@use 'sass:math';

.masonry {
    margin-bottom: 60px;
}

.masonry-callout {
    border-top: 1px solid $c-dark;
    border-bottom: 1px solid $c-dark;
    padding: 30px 0;
    margin: 30px 0 !important;

    .band--callout & {
        border-top: none;
        border-bottom: none;
        padding: 0;
        margin: 0 !important;
    }
}

/*
* margin-bottom: 10px is applied to masonry products in
* .grid--nested-masonry (_grids.scss)
*/
.masonry-product {
    @include aspect(math.div(5, 3));

    display: block;
    position: relative;
    z-index: 2;
    flex-grow: 0;

    svg {
        max-width: 200px;
        max-height: 200px;
        margin: 0 auto;
    }
}

// Where 2 products are in the same group, use margin bottom for vertical gutter
.masonry-product--nested {
    margin-bottom: 30px;
}

.masonry-product--full {
    height: 100%;
}

.masonry-product--left {

    .masonry-product__title,
    .masonry-product__body,
    .masonry-product__button {
        text-align: left;

        @include breakpoint(tablet) {
            text-align: center;
        }
    }
}

.masonry-product__content {
    margin: 25px 0 10px 0;
    text-align: center;
    flex-grow: 99;
    @include flush-last-child;

    &:empty {
        display: none;
    }

    .masonry-product--center &:first-child {
        margin-top: 0
    }
}

.masonry-product__callout {
    padding: 0 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include breakpoint(tablet) {
        position: static;
        top: 0;
        transform: none;
    }

    .masonry-product--wide & {
        padding: 0 0 0 20px;

        @include breakpoint(tablet) {
            padding: 0;
        }

        .grid--masonry-switch & {
            padding: 0;
        }
    }
}

.masonry-product__media {
    margin-bottom: 15px;

    svg {
        max-height: 50px;
    }
}

// Style the overlaying title
.masonry-product__title {
    @include small-title;

    margin-bottom: 15px;
    border-bottom: 1px solid currentColor;
    transition: color 150ms ease-in;
    display: inline-block;

    a:hover & {
        color: var(--site-colour-primary);
    }
}

.masonry-product__price {
    @include rem(15px);
}

.masonry--threesmall,
.masonry--foursmall,
.masonry--sixsmall,
.masonry--slider {
    .masonry-product__title {
        @include rem(17px);
    }
}

// Style the overlaying title
.masonry-product__body {
    @include intro();
    margin: 0;
}

.masonry-product ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;

    li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        padding-left: 25px;

        &::before {
            content: "";
            background: 0 50% url("/images/symbols/tick.svg") no-repeat;
            width: 25px;
            height: 25px;
        }
    }

    @include breakpoint(mobile) {
        display: inline-block;
        margin-left: 0;

        li {
            justify-content: flex-start;
            width: 100%;
            padding-left: 0;
        }
    }
}

.masonry-product.masonry-product--wide ul {
    display: block;
    margin-left: 0;

    li {
        justify-content: flex-start;
        width: 100%;
        padding-left: 0;
    }
}

.masonry-product__button,
.masonry-product__cta {
    text-align: center;
    flex-grow: 0;

    .masonry-callout & {
        margin-top: 15px;
    }

    .masonry-product--extra-space & {
        margin-top: 30px;

        @include breakpoint(tablet) {
            margin-top: 20px;
        }
    }
}

.masonry-product__cta {
    text-decoration: underline;
    cursor: pointer;
    transition: color .3s ease-out;

    a:hover & {
        color: $c-light-grey;
    }
}

/*
* 1. Stretch image to cover the entire element
* 2. Use object-fit to scale the image proportionately (like background-size: cover)
* 3. Transition in/out images when hovering .masonry-product (.no-touch only)
* ------ Fallback ------
* 4. Instead of stretching the image, center it
* 5. Reset image width
* ------ Compatibility ------
* 6. MS Edge fix for hover image opacity, limited selector to MS specific to avoid hover jank in sane browsers
*/
.masonry-product__image {
    @include image-slideshow(masonry-product); /* 3 */

    img {
        @include absolute-cover; /* 1 */
        object-fit: cover; /* 2 */
        .no-object-fit & {
            @include absolute-center;
            width: 100%; /* 5 */
            height: auto;
        }
    }
    _:-ms-lang(x), img {
        opacity: inherit; /* 6 */
    }
}

.masonry-product--zoom {
}

// Targets the smallest blocks on 'Large'
// Bit weird, but works
.grid--nested-masonry {
    .l-one-half,
    .l-one-quarter {
        .l-square {
            .masonry-product__title {
                @include rem(17px);
            }
        }
    }

    .l-one-sixth {
        .l-square {
            .masonry-product__title {
                @include rem(17px);
            }
        }
    }
}

// Style the icon
.masonry-product__icon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
}

// Heading styling
.masonry-heading {
    padding: 30px 0;
    @include flush-last-child;
}

.masonry-heading__title {
    @include section-title();
    margin-bottom: 15px;
}

.masonry-heading__longcopy {
    // @include wrap(750px);
    @include intro();
    @include flush-last-child;
    text-align: center;

    a:not(.button) {
        @include link;
    }
}

// Content styling
.masonry-content {
    padding: 30px 0 0 0;
    margin-bottom: 45px;
    @include flush-last-child;

    .masonry--content & {
        padding: 0;
        margin-bottom: 0;
    }
}

.masonry-content--title-above {
    padding-top: 0;
}

.masonry-content__wrap {
    max-width: 790px;
    margin-right: auto;
    margin-left: auto;
}

.masonry-content__media {
    margin-bottom: 10px;

    svg {
        max-height: 50px;
        margin: 0 auto;
    }
}

.masonry-content__media--natural {
    svg {
        max-height: none;
        max-width: 100%;
    }
}

.masonry-content__title {
    @include section-title();
    margin-bottom: 15px;

    .masonry-content--large & {
        margin-bottom: 32px;
    }
}

.masonry-content__longcopy {
    // @include wrap(750px);
    @include intro();
    @include flush-last-child;
    text-align: center;
    margin-bottom: 15px;

    a:not(.button) {
        @include link;
    }
}

.masonry-content__swatches {
    max-width: 790px;
    margin: 0 auto 35px;
}

.masonry-content__button {
    text-align: center;
}

.masonry-content__label {
    svg {
        width: auto;
        display: inline-block;
        height: 1.3rem;
    }
    svg.badges-sale {
        width: 100%;
    }
}

.masonry-content__label--gift {
    display: block;

    svg {
        height: 38px;
    }
}

.scrummy {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
}

.scrummy--buttons {
    .grid--callout & {
        padding-bottom: 0;
    }
}

.scrummy--normal {
}
.scrummy--dark {
}
.scrummy--promo {
    .masonry-heading__title {
        color: $c-promo;
    }
}

.scrummy__placeholder {
    background: #e5e5e5;
    @include absolute-cover; /* 1 */
    object-fit: cover; /* 2 */
}

.masonry-wrap {
    margin-top: 70px;

    @include breakpoint(tablet) {
        margin-top: 54px;
    }

    .masonry-callout {
        margin: 0 !important;
        padding: 0;
        border: none;
    }

    > * {
        margin: 0 0 70px;
        padding: 0 0 70px;
        border-bottom: 1px solid rgba($c-note, 0.4);

        @include breakpoint(tablet) {
            margin-bottom: 54px;
            padding-bottom: 54px;
        }

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
    }
}

.masonry-wrap--border {

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        margin-bottom: 70px;
        background: rgba($c-note, 0.4);
    }

    @include breakpoint(tablet) {
        margin-bottom: 54px;
    }
}
