.block--products {
    .block__header {
        position: relative;
        margin-bottom: 15px;

        @include breakpoint(tablet) {
            margin-bottom: 10px;
        }
    }

    .block__nav-wrap {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        margin-right: -10px;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    .block__cta {
        margin-top: 30px;

        @include breakpoint(tablet) {
            margin-top: 20px;
        }
    }
}
