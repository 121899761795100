// Modal
.modal-nav {
	border-width: 1px 0;
	border-style: solid;
	border-color: $c-border;
	@include flush-last-child;
}

.modal-nav__link {
	@include title();
	position: relative;
	padding: 20px 15px;
	text-align: center;
}

.modal-nav__icon {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}
