.new-fabric-groups {
    .swatch-search & {
        display: none;
    }

    .swatch-filter & {
        .gallery__count {
            display: none;
        }
    }
}
