.block--carousel {
    .block__header {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

        .block__title {
            margin-bottom: 5px;
            text-align: center;
        }

        .block__text {
            margin-bottom: 5px;
            text-align: center;
        }

        .block__nav-wrap {
            display: flex;
            justify-content: flex-end;
            margin-right: -10px;

            @include breakpoint(tablet) {
                display: none;
            }
        }

    .block__swiper {
        @include breakpoint(tablet) {
            margin-right: calc($site-mobile-gutter * -1);
            padding-bottom: 50px;
        }

        align-items: stretch;
    }   

        .block__swiper-wrapper--no-swiper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 23px;
            
            & > * {
                flex-basis: calc(33.33% - 16px);
    
                @include breakpoint(tablet) {
                    flex-basis: 100%;
                }
            }
        }
}
