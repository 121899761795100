@use 'sass:math';

.showroom-location {
    display: grid;
    grid-template-columns: 3fr 7fr;
    gap: 85px;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        gap: 30px;
    }
}

    .showroom-location__content {

    }

        .showroom-location__title {
            @include large-subtitle;
            
            margin-bottom: 10px;
        }

        .showroom-location__override {
            margin-bottom: 10px;
        }

        .showroom-location__times {
            display: grid;
            grid-template-columns: auto 1fr;
            margin-bottom: 15px;

            dd {
                text-align: right;
                padding-right: 20px;
            }
        }

    .showroom-location__map {
        @include aspect(math.div(7, 4));

        @include breakpoint(tablet) {
            order: -1;
        }
    }
