.header-search {
    display: none;
    overflow: auto;
    color: $c-black;
}

.header-search--active {
    display: grid;
    // grid-template-columns: 1fr 5fr;
    grid-template-columns: 1fr;
    gap: 23px;
    position: fixed;
    top: calc($header-height + $notice-height);
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 12;
    background-color: $c-white;

    @include breakpoint(nav) {
        top: calc($header-height-mobile + $notice-height-mobile);
    }

    .body--admin & {
        top: calc($header-height + $notice-height + 44px);
    }

    .body--debug & {
        top: calc($header-height + $notice-height + 20px);
    }

    .body--admin.body--debug & {
        top: calc($header-height + $notice-height + 64px);
    }
}

// .header-search__suggestions {
//     grid-column: 1;
// }

.header-search__stats {
    // grid-column: 2;
    margin-bottom: 23px;
}

.header-search__results {
    // grid-column: 2;
    padding-bottom: 200px;
}

    // .header-search__list {
    //     display: flex;
    //     flex-direction: column;
    //     gap: 10px;
    // }

    // .header-search__list--inactive {
    //     display: none;
    // }

    // .header-search__container {
    //     overflow: scroll;
    //     padding-bottom: 200px;
    // }

    //     .header-search__grid {
    //         display: grid;
    //         grid-template-columns: repeat(3, minmax(0, 1fr));
    //         gap: 23px;
    //         padding: 20px 0;
    //     }
