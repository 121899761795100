.modal-slide {
    max-width: 770px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    .gallery-wrap {
        max-width: 100%;
        margin: 0 auto;
    }

    @media screen and (max-width: 750px) {
        display: block;
    }
}

.modal-slide__media {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 450px;
    max-width: 100%;

    .modal-slide--portrait & {
        width: 337px;
    }

    img {
        display: inline-block;
    }
}

.modal-slide__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    width: 315px;
    padding: 0 30px;

    #simplemodal-container & {
        width: 300px;
        padding-right: 0;
    }
}

.modal-slide__tooltip {
    position: absolute;
    margin-left: -8px;
    margin-top: -8px;
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 100%;
    border: 1px solid #fff;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}

.modal-slide__content {
    margin: 20px 0;
    border-top: 1px solid $c-border;
    padding-top: 20px;

    @include flush-last-child;

    p {
        font-size: 15px !important;
        line-height: 1.2;
    }
}

.modal-slide__product {
    @include title();
    @include rem(17px);
    margin-bottom: 0;
}

.modal-slide__colour {
    margin-bottom: 0;
}

.modal-slide__social {
    display: block;
    margin-bottom: 0;
    text-align: center;
}

.modal-slide__nav {
    display: flex;
    justify-content: space-between;
    margin: auto 0 3px 0;
    line-height: 1.2;
}
