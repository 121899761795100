$numerals: (
     1 : 'one',
     2 : 'two',
     3 : 'three',
     4 : 'four',
     5 : 'five',
     6 : 'six',
     7 : 'seven',
     8 : 'eight',
     9 : 'nine',
    10 : 'ten',
    11 : 'eleven',
    12 : 'twelve',
    13 : 'thirteen',
    14 : 'fourteen',
    15 : 'fifthteen',
    16 : 'sixteen',
    17 : 'seventeen',
    18 : 'eighteen',
    19 : 'nineteen'
);

$fractions: (
     2 : 'half',
     3 : 'third',
     4 : 'quarter',
     5 : 'fifth',
     6 : 'sixth',
     7 : 'seventh',
     8 : 'eighth',
     9 : 'ninth',
    10 : 'tenth',
    11 : 'eleventh',
    12 : 'twelfth',
    20 : 'twentieth'
);

$use: (
     2 : true,
     3 : true,
     4 : true,
     5 : true,
     6 : true,
     7 : true,
     8 : true,
     9 : true,
    10 : true,
    11 : true,
    12 : true,
    20 : false
);

$percentages: (
  10, 20, 30, 40, 50, 60, 70, 80, 90
);

@each $b, $breakpoint in $sizes {
    @include breakpoint($breakpoint) {
        @each $f, $fraction in $fractions {
            @if map-get($use, $f) == true {
                @each $n, $numeral in $numerals {
                    @if $n < $f {
                        @if $n < 2 {
                            .#{$b}-#{$numeral}-#{$fraction} {
                                width: percentage($n / $f);
                            }
                        } @else {
                            .#{$b}-#{$numeral}-#{$fraction}s {
                                width: percentage($n / $f);
                            }
                        }
                    }
                }
            }
        }

        @each $percent in $percentages {
            .#{$b}-#{$percent}-percent {
                width: percentage($percent / 100);
            }
        }

        .#{$b}-full {
            width: 100%;
        }

        .#{$b}-false {
            display: none !important;
        }

        .#{$b}-true {
            display: inline-block !important;
        }

        .#{$b}-block {
            display: block !important;
        }

        .#{$b}-flex {
            display: flex !important;
        }

        .#{$b}-field {
            width: calc(100% - 200px);
        }

        .#{$b}-button {
            width: 200px;
        }

        .#{$b}-left {
            text-align: left;
        }

        .#{$b}-center {
            text-align: center;
        }

        .#{$b}-right {
            text-align: right;
        }
    }
}


.fauxdal,
#simplemodal-container {
    @each $f, $fraction in $fractions {
        @if map-get($use, $f) == true {
            @each $n, $numeral in $numerals {
                @if $n < $f {
                    @if $n < 2 {
                        .modal-#{$numeral}-#{$fraction} {
                            width: percentage($n / $f);
                        }
                    } @else {
                        .modal-#{$numeral}-#{$fraction}s {
                            width: percentage($n / $f);
                        }
                    }
                }
            }
        }
    }

    .modal-full {
        width: 100%;
    }

    .modal-false {
        display: none;
    }

    .modal-true {
        display: inline-block;
    }

    .modal-block {
        display: block;
    }
}
