.express-swatches {
    .richtext {
        ul {
            margin-left: 0;

            li {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 0;

                &::before {
                    content: "";
                    background: 0 50% url("/images/symbols/tick.svg") no-repeat;
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}

.express-swatches__cta {
}

.express-swatches__swatches {
    margin-top: 40px;
    margin-bottom: 15px;
    padding-top: 34px;
    border-top: 1px solid $c-border;

    @include breakpoint(tablet) {
        margin-top: 30px;
        padding-top: 23px;
    }
}
