.social-post {
    display: block;
}

    .social-post__image {
        @include aspect(1);

        position: relative;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s ease-in-out;

            .social-post:hover & {
                transform: scale(1.05);
            }
        }

        svg {
            position: absolute;
            right: 15px;
            bottom: 15px;
            width: 20px;
            height: 20px;
            fill: $c-white;
        }
    }
