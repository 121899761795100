$accordionGutter: 11px;
$accordionGutterWider: 20px;

.accordion {
    background: $c-grey;
    margin-bottom: 15px;
}

.accordion--separator {
    height: 1px;
}

.accordion__title {
    @include mediumText;
    @include font(15px, 25px);
    cursor: pointer;
    display: block;
    position: relative;
    padding: 13px $accordionGutter;
    letter-spacing: 1px;

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 13px;
        right: $accordionGutter;
        background: 0 50% url("/images/symbols/plus.svg") no-repeat;
        width: 11px;
        height: 25px;
    }

    .accordion--active &::after {
        background-image: url("/images/symbols/minus.svg");
    }

    span {
        @include regularText;
    }

    .accordion--faux &::after {
        display: none;
    }

    .accordion--disabled & {
        pointer-events: none;
        opacity: 0.2;
    }
}

.accordion__content-area {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 250ms ease-in-out, opacity 250ms ease-in-out;

    .accordion--active &,
    .accordion--faux & {
        max-height: none;
        opacity: 1;
        overflow: visible;
    }
}

.accordion__content {
    border-top: 1px solid $c-border;
    margin: 0 $accordionGutter;
    padding: 13px 0;
    @include flush-last-child;

    .accordion--faux & {
        border-top: none;
        margin-top: 0;
    }

    .accordion--white & {
        border-top: none;
        margin: 0;
        padding: 13px $accordionGutter;
        background: #fff;
        border: 1px solid $c-border;
    }

    .item-qty {
        justify-content: flex-end;
    }

    .item-qty__down {
        padding-left: 0;
    }

    .item-qty__up {
        padding-right: 0;
    }

    .checkbox-replace label {
        @include font(15px, 21px);
        letter-spacing: 1px;
        line-height: 1.7;
    }
}

.accordion__divider {
    border-top: 1px solid $c-border;
    margin: 0 -#{$accordionGutter} 12px -#{$accordionGutter};
}

.accordion__wrapper {
    @include flush-last-child;

    margin-bottom: 30px;
}

.accordion__wrapper--wider {
    .accordion__title {
        padding-left: $accordionGutterWider;
        padding-right: $accordionGutterWider;

        &::after {
            right: $accordionGutterWider;
        }
    }

    .accordion__content {
        margin-left: $accordionGutterWider;
        margin-right: $accordionGutterWider;
    }

    .accordion--white {
        padding-left: $accordionGutterWider;
        padding-right: $accordionGutterWider;
    }
}
