.block--cross-sells {
    @include breakpoint(tablet) {
        margin-right: calc($site-mobile-gutter * -1);
    }

    .block__header {
        position: relative;
        margin-bottom: 15px;
    }

    .block__nav-wrap {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        margin-right: -10px;

        @include breakpoint(tablet) {
            display: none;
        }
    }
}
