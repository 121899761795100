.order-complete-donation {
    border: 1px solid $c-border;
    padding: 60px;
    display: flex;
    flex-direction: column;
    background: $c-white;
    align-items: center;

    @include breakpoint(tablet) {
        padding: 10px;
    }
}

.order-complete-donation--hidden {
    display: none;
}

.order-complete-donation__banner {
    padding: 20px;

    @include breakpoint(tablet) {
        padding-bottom: 5px;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.order-complete-donation__text {
    @include font(28px, 36px);
    @include mediumText;

    text-align: center;
    
    @include breakpoint(tablet) {
        @include font(16px, 24px);
    }
}

.order-complete-donation__icon {
    @include font(44px, 44px);
    @include boldText;

    height: 100px;
    width: 100px;
    background-color: $c-grey;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    @include breakpoint(tablet) {
        width: 80px;
        height: 80px;
    }

    svg {
        width: 40px;
        height: auto;

        @include breakpoint(tablet) {
            width: 30px;
        }
    }
}

.order-complete-donation__button {
    margin-top: 20px;
}
