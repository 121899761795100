.swatch-basket-trigger {
    display: none;

    @include breakpoint(tablet) {
        position: fixed;        
        left: $site-mobile-gutter;
        bottom: $site-mobile-gutter;
        padding: 15px;
        background-color: var(--site-colour-primary);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        z-index: 1;
    
        svg {
            fill: var(--site-text-colour-primary);
        }  
    }
}

    .swatch-basket-trigger__text {
        @include mediumText;

        color: var(--site-text-colour-primary);
    }
