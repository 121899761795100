.section-intro {
    margin-bottom: 15px;
    text-align: center;

    svg {
        display: inline-block;
    }
}

.section-intro__band.band {
    @include breakpoint(phablet) {
        padding-top: 40px;
    }
}

.section-intro--left {
    text-align: left;

    & .section-intro__longcopy {
        text-align: left;
    }
}

.section-intro--flush-bottom {
    margin-bottom: 0;

    .section-intro__longcopy {
        margin-bottom: 0;
    }
}

.section-intro--has-small-space {
    margin-bottom: 20px;
}

.section-intro--has-space {
    margin-bottom: 40px;

    @include breakpoint(tablet) {
        margin-bottom: 30px;
    }
}

.section-intro--has-extra-space {
    margin-bottom: 70px;

    @include breakpoint(tablet) {
        margin-bottom: 40px;
    }
}

.section-intro--pull-to-hero {

    .hero + & {

        @include breakpoint(tablet) {
            margin-top: -45px;
        }
    }
}

.section-intro__lead-image {
    max-width: 160px;
    margin-left: auto;
    margin-right: auto;

    svg {
        width: 100%;
        height: auto;
    }
}
.section-intro__title {
    @include section-title;
    @include margin(bottom, 10px);

    text-align: inherit;
}

.section-intro__details {
    @include font(15px, 24px);
    @include mediumText;

    color: $c-border;

    &:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        margin: 0 11px 4px 8px;
        background: currentColor;
        border-radius: 100%;
    }
}

.section-intro__title--smaller {
    @include rem(17px);
    @include margin(bottom, 5px);
}

.section-intro__longcopy {
    text-align: center;

    a:not(.button) {
        @include link;
    }

    @include breakpoint(mobile) {
        min-width: auto;
    }

    strong {
        color: $c-error;
        font-weight: normal;

        a:not(.button) {
            color: inherit;
        }
    }

    .button {
        margin-top: 20px;
    }
}

.section-intro__longcopy--left {
    text-align: left;
}

.section-intro__longcopy--warning {
    color: $c-error;
    margin-bottom: 0.75em;

    p {
        margin-bottom: 0.75em;
    }

    a:not(.button) {
        color: inherit;
    }
}

.section-intro__media {
    text-align: center;
    margin-bottom: 10px;

    svg {
        max-height: 50px;
        margin: 0 auto;
    }
}

.section-intro__media--wide {
    margin-bottom: 30px;

    img {
        width: 100%;
        height: auto;
    }

    @include breakpoint(phablet) {
        @include aspect(3 / 2);
    }
}

.section-intro__button {
    text-align: center;
}
