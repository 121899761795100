@mixin bullet-list() {
    display: block;
    position: relative;
    padding-left: 1.25em;
    margin-bottom: 0.75em;

    &::before {
        position: absolute;
        content: "";
        top: 0.45em;
        left: 0;
        width: 0.45em;
        height: 0.45em;
        background: $c-dark;
        border-radius: 1em;
    }
}

@mixin check-list() {
    display: block;
    position: relative;
    padding-left: 1.25em;
    margin-bottom: 0.75em;

    &::before {
        position: absolute;
        content: "✓";
        top: 0.25em;
        left: 0;
        width: 17px;
        height: 14px;
        color: $c-body;
    }
}

@mixin benefits-list() {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0;

    &::before {
        content: "";
        background: 0 50% url("/images/symbols/tick.svg") no-repeat;
        width: 25px;
        height: 25px;
        flex-shrink: 0;
    }
}
