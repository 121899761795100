
/*
* Section 1. Grid and Grid__item/column mixins
* Section 2. Grid and Grid__item/column setup
* Section 3. Grid alignment modifiers
* Section 4. Generic grid gutter modifications
* Section 5. Specific grid gutter modifications
*/

/* ---------------------------- Section 1 ---------------------------- */

@mixin grid() {
    @include flex(row);
    flex-wrap: wrap;
    @include grid-gutter();
    text-align: left;
    // Fallback
    .no-flexbox & {
        display: block;
        @include remove-white-space;
    }
}

@mixin grid__item() {
    @include flex(column);
    text-align: left;
    @include flush-last-child;
    // Fallback
    .no-flexbox & {
        display: inline-block;
        vertical-align: top;
    }
}

// WARNING
// If you use these mixins on anything other than .grid/grid__item (as below) - currently the grid-gutter mixin
// will not work properly (not really ready for the wild yet)
// also margins may be removed via flush-last-child mixin

/* ---------------------------- Section 2 ---------------------------- */

// Flexbox grid
.grid {
    @include grid;
    @include breakpoint(phablet) {
        @include grid-gutter(20px, 20px);
    }
}

// Grid items (formerly .col)
.grid__item {
    @include grid__item;
}

// Negative margins applied to .grid via 'grid-gutter' mixin
// Overflow hidden required alongside this to make it work
// .grid--flush-bottom {
//     overflow: hidden;
// }

@include breakpoint(mobile) {
    .double-column > .grid--product-listing > .grid__item {
        width: 50%;
    }
}

/* ---------------------------- Section 3 ---------------------------- */

// Reverse order
.grid--reverse {
    flex-direction: row-reverse;
    .no-flexbox & {
        direction: rtl;
        & > .grid__item {
            direction: ltr;
        }
    }
}

// Cross axis - Left
.grid--left {
    justify-content: flex-start;
    .no-flexbox & {
        text-align: left;
    }
}

// Cross axis - Center
.grid--center {
    justify-content: center;
    .no-flexbox & {
        text-align: center;
    }
}

// Cross axis - Right
.grid--right {
    justify-content: flex-end;
    .no-flexbox & {
        text-align: right;
    }
}

// Verical axis - Top
// (Grid items (in flexbox) will nolonger match heights with any vertical axis modifier)
.grid--v-top {
    align-items: flex-start;
    .no-flexbox & > .grid__item {
        vertical-align: top;
    }
}

// Verical axis - Middle
.grid--v-middle {
    align-items: center;
    .no-flexbox & > .grid__item {
        vertical-align: middle;
    }
}

// Verical axis - Bottom
.grid--v-bottom {
    align-items: flex-end;
    .no-flexbox & > .grid__item {
        vertical-align: bottom;
    }
}

.grid__item--center {
    text-align: center;
}

.grid__item--center-block {
    margin-left: auto;
    margin-right: auto;
}

.grid__item--right {
    text-align: right;
}

.grid__item--spread {
    justify-content: space-between;
}

.grid__item--break {
    width: 100%;
    margin: 0 !important;
}

.grid__item--scrollable {
    max-height: 100%;
    overflow: auto;
    position: relative;
}

.grid__item--flush-bottom {
    margin-bottom: 0 !important;
}

/* ---------------------------- Section 4 ---------------------------- */

// No gutters
.grid--flush {
    @include grid-gutter(0, 0);
}

// Used for general page layout/structure
.grid--layout {
    @include grid-gutter(50px, 50px);
    @include breakpoint(tablet) {
        @include grid-gutter(40px, 40px);
    }
    @include breakpoint(phablet) {
        @include grid-gutter(30px, 30px);
    }
}
.grid--layout-switch {
    @include grid-gutter(50px, 50px, true);
    @include breakpoint(tablet) {
        @include grid-gutter(40px, 40px, true);
    }
    @include breakpoint(phablet) {
        @include grid-gutter(30px, 30px, true);
    }
}

.grid--form {
    @include grid-gutter(23px, 23px);

    @include breakpoint(tablet) {
        @include grid-gutter(10px, 10px);
    }
}

.grid--loose {
    @include grid-gutter(50px, 50px);

    @include breakpoint(phablet) {
        @include grid-gutter(20px, 20px);
    }
}

.grid--tight {
    @include grid-gutter(20px, 20px);
}

.grid--borders {
    border-top: 1px solid $c-border;
    border-left: 1px solid $c-border;

     .grid__item {
        padding: 20px;
        border-bottom: 1px solid $c-border;
        border-right: 1px solid $c-border;
        margin-bottom: 0;
    }
}

.grid--border-top {
    padding: 20px;
    border-top: 1px solid $c-border;
    @include grid-gutter(80px, 20px);
}

.grid--border-top-gap {
    padding: 50px 20px 20px 20px;
    border-top: 1px solid $c-border;
    @include grid-gutter(80px, 20px);
}

.grid--justify {
    justify-content: space-between;
}

.grid--whats-that {
    position: relative;;
    padding-right: 30px;

    .grid__item--whats-that {
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        padding-bottom: 10px;
    }
}

/* ---------------------------- Section 5 ---------------------------- */

.grid--fabric-tiles {
    @include grid-gutter(10px, 10px);
}

.grid--fabric-tiles-request {
    @include grid-gutter(10px, 10px);
}

.grid--fabric-tiles-tight {
    @include grid-gutter(7px, 7px);

    @include breakpoint(tablet) {
        @include grid-gutter(10px, 10px);
    }
}

// Used for the 'chooser' modal for each 'choice'
.grid--choices {
    @include breakpoint(tablet) {
        @include grid-gutter(20px, 20px);
    }
}

.grid--gallery {
    @include grid-gutter(10px, 10px);
}

.grid--product-config {
    @include grid-gutter(0, 0);
    @include breakpoint(tablet) {
         @include grid-gutter(30px, 30px);
    }
    @include breakpoint(phablet) {
        @include grid-gutter(0, 0);
    }
}

.grid--product-listing {
    margin-bottom: 60px;
}

.grid--upsell-added {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.grid--mattress-upsell {
    @include grid-gutter(20px, 20px);
}

.grid--linen {
    @include grid-gutter(5px, 15px);
    margin-bottom: 0 !important;
}

.grid--bedding-listing {
    @include grid-gutter(50px, 50px);
    @include breakpoint(tablet) {
        @include grid-gutter(20px, 20px);
    }

    padding: 10px 0 10px 0;
}

.grid--dimensions {
    @include grid-gutter(25px, 0);
    @include breakpoint(tablet) {
        @include grid-gutter(15px, 0);
    }
}

.grid--swatch {
    @include breakpoint(tablet) {
        @include grid-gutter(10px, 10px);
    }
}

// Masonry, formerly known as scrummy (listing pages)
// Using a variable here as other elements/components rely on the same value
.grid--masonry,
.grid--masonry-switch {

    .masonry-wrap &.grid--flush-bottom {

        @include breakpoint(tablet) {
            margin-bottom: -45px;
        }
    }

    .grid__item {
        overflow: hidden;

        @include breakpoint(tablet) {
            margin-bottom: 45px;

            &:last-of-type {
                margin-bottom: 0;

                .masonry-callout & {
                    margin-bottom: 45px;
                }
            }
        }
    }

    .grid__item--m-full {

        .wrap--full & {

            @include breakpoint(tablet) {
                width: calc(100% + 20px);
                margin-right: -10px;
                margin-left: -10px;
            }
        }
    }
}

.grid--masonry {
    @include grid-gutter(23px, 23px);
}

.grid--masonry-switch {
    @include grid-gutter(30px, 10px, true);

    @include grid-gutter(50px, 50px, true);
    @include breakpoint(tablet) {
        @include grid-gutter(40px, 40px, true);
    }
    @include breakpoint(phablet) {
        @include grid-gutter(30px, 30px, true);
    }
}

// Slightly different attributes for nested grids to make the gutters/aspects behave
.grid--nested-masonry {
    @include grid-gutter(30px, 0);
    margin-bottom: -10px;
    overflow: hidden;
    .masonry-product {
        // margin-bottom: ;
    }
}
.grid--range,
.grid--gallery,
.grid-gallery-cta {
    @include grid-gutter(30px, 30px);
}

.grid--moodboard {
    @include grid-gutter(30px, 30px);

    @include breakpoint(tablet) {
        @include grid-gutter(10px, 10px);
    }
}

.grid--feature {
    @include grid-gutter(30px, 30px);

    @include breakpoint(tablet) {
        @include grid-gutter(45px, 45px);
    }
}

.grid--filters {
    @include grid-gutter(15px, 15px);
}

.grid--loading {
    pointer-events: none;
}

.grid--equal-cols {

    & > * {
        flex: 1;

        @include breakpoint(phablet) {
            flex-basis: 100%;
        }
    }
}

.grid__block--gallery-cta {

    @include breakpoint(tablet) {
        @include grid-gutter(30px, 10px, true);
    }
}
