.listing-item-wrap {
    display: flex;
}

.listing-item {
    border: 1px solid $c-grey-border;
    padding-bottom: 12px;
    width: 100%;
    transition: opacity 0.1s ease-out;
}

.listing-item--loading {
    opacity: 0.5;
}

.listing-item__form {
    padding-left: $site-gutter;
    padding-right: $site-gutter;
}

    .listing-item__form-content {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;

        .button {
            margin-left: auto;
            max-width: 126px;
        }

        span {
            @include mediumText;

            display: flex;
            align-items: center;
        }
    }
