.block--intro {

    .block__content {
   
    }

    .block__content--intro-outline {
        padding: 50px 0 30px;
        border: 1px solid $c-border;
    }

    .block__image {
        @include aspect(3 / 1);

        @include breakpoint(tablet) {
            @include aspect(3 / 2);
        }
    }
}
