@use 'sass:math';

.product-xmas-checker {
    display: flex;
    flex-direction: column;
    list-style: none;

}
    .product-xmas-checker__button {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 15px 0;
        border-bottom: 1px solid $c-grey-border;
        width: 100%;
        cursor: pointer;
        transition: border-color 150ms ease-in;

        svg {
            fill: currentColor;
            pointer-events: none;
        }

        svg.global-icon-arrow-right {
            margin-left: auto;
            margin-right: 10px;
        }

        &:hover {
            border-color: $c-dark;
        }
    }

    .product-xmas-checker__location-form {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 25px;
        margin-bottom: 15px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 25px;

        @include breakpoint(phablet) {
            flex-direction: column;
        }

        .checkbox-replace {
            flex-basis: 0;
            flex-grow: 1;
        }
    }

        .product-xmas-checker__search-location {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            width: 100%;

            @include breakpoint(phablet) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .product-xmas-checker__error {
            @include mediumText;

            color: $c-error;
            flex-basis: 100%;
        }

        .product-xmas-checker__search {
            position: relative;
            display: inline-block;

            input {
                @include form-input;

                width: 320px;
                height: 50px;
                border: 1px solid $c-grey-border;
                outline: 1px solid rgba(0,0,0,0);
                border-radius: 25px;
                background-color: $c-white;
                padding-right: 35px;
                padding-left: 25px;
                transition: outline-color 250ms ease;

                @include breakpoint(phablet) {
                    width: 100%;
                }

                &::placeholder {
                    color: $c-body-light;
                }

                &:focus-visible {
                    outline: 1px solid $c-dark;

                    .product-xmas-checker__location-form--error & {
                        outline: 1px solid $c-error;
                    }
                }
            }

            button {
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                cursor: pointer;
                padding: 10px;

                svg {
                    fill: $c-dark;
                }
            }

            p {
                position: absolute;
                bottom: -10px;
            }
        }

    .product-xmas-checker__location {
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
            fill: $c-dark;
        }

        .button[disabled]{
            background-color: $c-white;
            color: #eceae6;
            transition: all 1s;
        }

        .button[disabled]:hover{
            background-color: $c-white;
        }
    }

    .product-xmas-checker__title {
        @include small-title;

        padding-bottom: 24px;
        margin-bottom: 25px;
        border-bottom: 1px solid $c-grey-border;

        @include breakpoint(phablet) {
            padding-right: 40px;
            margin-bottom: 20px;
        }
    }

    .product-xmas-checker__subtitle {
        @include large-subtitle;

        margin-bottom: 10px;
    }

    .product-xmas-checker__steps {
        @include mediumText;
        @include font(18px, 20px);

        list-style: decimal;
        list-style-position: inside;
        margin-top: 25px;
    }

        .product-xmas-checker__step {
            margin-bottom: 25px;
        }

            .product-xmas-checker__step-title {
                margin-bottom: 15px;
                display: inline-block;
            }

            .product-xmas-checker__step-copy {
                @include body;
                @include regularText;
            }

    .product-xmas-checker__showrooms {
        transition: opacity 1s;
        margin: 25px 0;

        @include breakpoint(tablet) {
            margin: 10px 0;
        }
    }

    .product-xmas-checker__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .product-xmas-checker__showrooms--loading {
        transition: opacity 1s;
        opacity: 0.4;
        pointer-events: none;
    }

    .product-xmas-checker__showrooms--spinner::after {
        content: " ";
        display: block;
        width: 21px;
        height: 21px;
        margin: 8px;
        border-radius: 50%;
        border: 3px solid #eceae6;
        border-top-color: rgb(236, 234, 230);
        border-right-color: rgb(236, 234, 230);
        border-bottom-color: rgb(236, 234, 230);
        border-left-color: rgb(236, 234, 230);
        border-color: #eceae6 transparent #eceae6 transparent;
        -webkit-animation: lds-dual-ring 2s linear infinite;
        animation: lds-dual-ring 2s linear infinite;
    }

    .product-xmas-checker__showrooms--spinner {
        opacity: 0;
        transition: opacity 1s;
    }

    .product-xmas-checker__showrooms--spinnershow {
        opacity: 1;
    }
    
    .product-xmas-checker__results{
        .product-xmas-checker__results--title-wrap{
            margin-bottom:  10px;
            display: flex;
            align-items: center;

            .product-xmas-checker__results--title{
                @include large-subtitle;

                margin-left: 10px;
            }
        }
   
        .product-xmas-checker__results--body{
            @include body;
            @include regularText;
            margin-bottom: 25px;
        }

        max-height: 0px;
        opacity: 0;
    }

    .product-xmas-checker__results--show{
        opacity: 1;
        max-height: 1000px;
        margin-bottom: 25px;
        transition: all .500s ease-in-out;
    }

    .product-xmas-checker__image{
        height: 0px;
        opacity: 0;
        display: flex;
        align-items: flex-start;
        transition: opacity 1s linear;

        img {
            width: 100%;
            height: 0;
        }
    }

    .product-xmas-checker__preBody{
        @include body;
        @include regularText;
        margin-bottom: 25px;
    }

    .product-xmas-checker_location--error{
        @include smallprint;

        padding-left: 25px;
    }

    .product-xmas-checker__lifestyle-image{

        &.product-xmas-checker__lifestyle-image--show{
            opacity: 1;
            height: fit-content;
            padding-top: 25px;
            border-top: 1px solid $c-grey-border;

            img{
                height: auto;
            }
        }
    }

    .product-xmas-checker__xmas-image{

        &.product-xmas-checker__xmas-image--show{
            opacity: 1;
            height: fit-content;
            padding-top: 25px;
            border-top: 1px solid $c-grey-border;

                img{
                height: auto;
            }
        }
    }
