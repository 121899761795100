.price {
    display: flex;
    align-items: baseline;
    justify-content: end;
}

.price__value {}

.price__value--was {
    text-decoration: line-through;
    margin-right: 4px;
}

.price__value--now {
    color: $c-promo;
}
