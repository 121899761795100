.calendar {
    width: 100%;
}

.calendar__month {
    @include intro;

    text-align: center;
    padding: 20px 0 2px 0;
    text-transform: uppercase;
}

.calendar__day {
    @include smallprint;

    text-align: center;
    padding: 2px 10px;
}

.calendar__date {
    border: 1px solid $c-border;
}

.calendar__date--empty,
.calendar__date:has(.calendar__link) {
    @include breakpoint(tablet) {
        background-color: $c-light-grey;
    }
}

.calendar__link {
    @include intro;

    display: block;
    padding: 10px;
    text-align: center;

    &:hover {
        color: $c-white;
        background-color: $c-dark;
    }

    &[readonly] {
        pointer-events: none;
        opacity: 0.5;
    }

    &[data-date] {
        background-color: $c-white;

        &:hover {
            background-color: $c-dark;
        }
    }
}

.calendar__link--selected {
    &[data-date] {
        background-color: $c-dark;
        color: $c-white;
    }
}

.calendar__field {
    color: $c-body;
    text-transform: uppercase;
}
