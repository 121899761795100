.swatch-colour {

}

.swatch-colour--swatches {
    @include breakpoint(tablet) {
        margin-bottom: 10px;
    }
}

.swatch-colour--overlay,
.swatch-colour--listing {
    @include breakpoint(tablet) {
        margin-bottom: 15px;
    }
}

    .swatch-colour__title {
        margin-bottom: 12px;
    }

    .swatch-colour__list {
        display: flex;
        gap: 1px;

        @include breakpoint(tablet) {
            flex-wrap: wrap;
            gap: 10px;
        }
    }

    .swatch-colour__fabric {
        @include swatch-hover;

        cursor: pointer;
        width: 48px;
        height: 48px;

        img {
            width: 100%;
            height: auto;
        }

        &:not(:last-child) {
            margin-right: 1px;
        }

        &:has(input[type="radio"]:checked) {
            @include swatch-active;
        }
    }
