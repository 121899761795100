@mixin large-title {
    @include boldText;
    @include font(40px, 50px);

    letter-spacing: 0.5px;

    @include breakpoint(tablet) {
        @include font(28px, 32px);
    }
}

@mixin medium-title {
    @include mediumText;
    @include font(32px, 40px);

    @include breakpoint(tablet) {
        @include font(26px, 30px);
    }
}

@mixin small-title {
    @include mediumText;
    @include font(28px, 40px);

    @include breakpoint(tablet) {
        @include font(24px, 34px);
    }
}

@mixin subtitle {
    @include mediumText;
    @include font(16px, 20px);
}

@mixin heading {
    @include mediumText;
    @include font(16px, 20px);
}

@mixin large-subtitle {
    @include mediumText;
    @include font(20px, 24px);

    @include breakpoint(tablet) {
        @include font(18px, 24px);
    }
}

@mixin body {
    @include font(14px, 24px);
}

@mixin intro {
    @include font(16px, 26px);
}

@mixin button-copy {
    @include mediumText;
    @include font(14px, 17px);
}

@mixin form-label {
    @include font(12px, 12px);

    color: $c-label;
}

@mixin large-label {
    @include font(14px, 18px);

    color: $c-body;
}

@mixin form-input {
    @include font(14px, 14px);

    @include breakpoint(tablet) {
        @include font(16px, 16px);
    }
}

@mixin form-placeholder {
    @include font(14px, 17px);

    color: $c-disabled;
}

@mixin title {
    display: block;
    color: $c-body;
    line-height: 1.2;
}

@mixin smallprint {
    @include font(12px, 12px);

    color: $c-body;
}

@mixin section-title {
    @include medium-title;
    @include boldText;

    text-align: center;
    text-transform: uppercase;
}

@mixin link {
    @include body;
    @include mediumText;

    padding-bottom: 3px;
    color: $c-dark;
    border-bottom: 1px solid currentColor;
    transition: color 150ms ease-in;

   &:hover {
        color: var(--site-colour-primary);
    }
}
