.upsell {
    display: block;
    // No style
}

// Media
.upsell__media {
    @include image-wrap(fill);
    width: 100%;
    padding: 0 30px;
    margin: 0 auto 30px auto;
    @include breakpoint(tablet) {
        max-width: 450px;
    }
    @include breakpoint(phablet) {
        width: 100%;
        padding-bottom: 10px;
    }
}

.upsell__media--center {
    text-align: center;
}

.upsell__lifestyle {
    @include image-wrap(fill);
    width: 100%;
    margin-bottom: 30px;
}

// generic 'grouping' class
.upsell__item {
    margin-bottom: 20px;
}

// Content

.upsell__content {
    max-width: 550px;
    padding: 0 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    @include breakpoint(tablet) {
        padding: 30px;
    }
    @include breakpoint(phablet) {
        padding: 0;
    }
}

.callout {
    .upsell__content {
        @include breakpoint(phablet) {
            padding: 20px;
        }
    }
}

.upsell__content--center {
    text-align: center;
    img {
        margin: 0 auto;
    }
}

.upsell__title {
    @include title();
    @include rem(30px);
    margin-bottom: 20px;
}

.upsell__body {
    @include rem(17px);
    line-height: 1.6;
    @include flush-last-child;
    margin-bottom: 20px;
}

.upsell__action {
    text-align: center;
}

.upsell__secondary-action {
    margin-top: 10px;
    text-align: inherit;

    a.link {
        text-decoration: underline;
    }
}

.upsell__bullet-list {
    @include flush-last-child;
    li {
        @include bullet-list;
        a {
            text-decoration: underline;
        }
    }
}

.upsell-range {
    margin: 30px auto 0 auto;
    text-align: center;
}

.upsell-range--selected {
    display: block;
}

.upsell-range__title {
    @include title();
    @include rem(17px);
    margin: 10px 0;
    text-align: center;
}

.upsell-range__body {
    @include rem(17px);
    line-height: 1.4;
    text-align: center;
    margin-bottom: 10px;
}

.upsell__basket {
    z-index: z-index(mini-basket);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 10px 0;
    background: $c-white;
    border-top: 1px solid $c-light-grey;

    .cookie-pad {
        display: block;
    }
}

.upsell-range {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
}

.upsell-range__title {
    @include title();
    @include rem(21px);
    @include mediumText;
    margin: 10px 0;
    text-align: center;
}

.upsell-range__body {
    @include rem(17px);
    line-height: 1.4;
    text-align: center;
    margin-bottom: 10px;
}

.upsell-range__media {
    margin: 0 auto;
}

.upsell-range__items {
    margin: 30px;

    button {
        width: 200px;
        margin-bottom: 10px;
    }
}

.upsell-protector {
    text-align: center;
    margin-bottom: 50px;

    img {
        display: inline-block;
    }
}

.upsell__selected {
    margin-bottom: 30px;
}

.upsell__form > * {
    width: auto;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
}

.upsell__selected {
    margin-bottom: 30px;
}

/*** CUSHION UPSELL ***/

.upsell-cushions {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
}

.upsell-cushions__action {
    margin: 50px 0;
    text-align: center;
}

.upsell-cushions__sizes {
    max-width: 250px;
    margin: 0 auto 20px auto;
}

.upsell-cushion {
    text-align: center;
    overflow: visible !important;
}

.upsell-cushion__title {
    margin-bottom: 10px;
}

.upsell-cushion__price {
    @include rem(22px);
    margin-bottom: 10px;
}

.upsell-cushion__form > * {
    width: auto;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
}

.upsell-cushions__selected {
    margin-bottom: 30px;
}
