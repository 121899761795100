.listing2 {
    [hidden] {
        display: none !important;
    }
}

.filters {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $c-grey-border;

    @include breakpoint(tablet) {
        display: none;
        position: fixed;
        align-content: flex-start;
        z-index: 30;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 15px $site-mobile-gutter 100px;
        overflow: auto;
        background-color: $c-white;
    }

    &[data-modal-open] {
        @include breakpoint(tablet) {
            display: flex;
        }
    }
}

    .filters__mobile-title {
        display: none;

        @include breakpoint(tablet) {
            @include mediumText;
            @include font(20px, 20px);

            display: flex;
            gap: 15px;
            margin-bottom: 15px;
        }
    }

    .filters__close-modal {
        display: none;

        @include breakpoint(tablet) {
            display: block;
            position: absolute;
            padding: 10px;
            right: 2px;
            top: 5px;
            z-index: 1;
        }
    }

    .filters__button-wrap {
        display: flex;
        position: relative;

        @include breakpoint(tablet) {
            flex-direction: column;
            border: none;
            width: 100%;
        }
    }

    .filters__button-wrap--no-accordion {
        padding-top: 10px;
        margin: 0;

        @include breakpoint(tablet) {
            padding-top: 65px;
        }
    }

    .filters__lower {
        display: flex;
        padding: 20px 0;

        @include breakpoint(tablet) {
            display: none;

            [data-filtered="true"] & {
                display: flex;
                justify-content: center;
                padding-bottom: 0;
            }
        }
    }

        .filters__lower-count,
        .filters__clear {
            @include smallprint;

            display: flex;
            align-items: center;
        }

        .filters__lower-count {
            margin-right: auto;

            @include breakpoint(tablet) {
                display: none;
            }
        }

        .filters__clear {
            @include mediumText;

            position: relative;
            text-decoration: underline;
            margin-right: 40px;
            transition: color 150ms ease-in-out;

            @include breakpoint(tablet) {
                margin: 0;
            }

            [data-loading="true"] & {
                opacity: .3;
                pointer-events: none;
            }

            &:hover {
                color: var(--site-colour-primary);
            }

            &::after {
                content: '';
                position: absolute;
                right: -20px;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 20px;
                background: $c-border;

                @include breakpoint(tablet) {
                    display: none;
                }
            }
        }

    .filters__options {
        position: relative;
        flex-basis: 100%;
        display: flex;
        min-width: 0; //stops the carousel overflowing
    }

    .filters__mobile-actions {
        display: none;

        @include breakpoint(tablet) {
            display: flex;
            gap: 10px;
            margin-top: 10px;

            & > * {
                flex: 1;
            }
        }
    }

        .filters__button {
            display: flex;
            align-items: center;
            border-bottom: 1px solid transparent;
            padding: 20px 0;
            margin-bottom: -1px;
            z-index: 1;
            cursor: pointer;

            @include breakpoint(tablet) {
                padding: 15px 0 15px;
                margin-bottom: 0;
                border-bottom: 1px solid $c-border;
            }

            svg {
                margin-left: 15px;
                transition: transform .1s ease;
                fill: $c-dark;

                @include breakpoint(tablet) {
                    transform: rotate(270deg);
                }
            }

            &:first-child {
                @include breakpoint(tablet) {
                    border-top: 1px solid $c-border;
                }
            }

            &:not(:first-child) {
                margin-left: 40px;

                @include breakpoint(tablet) {
                    margin-left: 0;
                }
            }

            &[aria-expanded="true"] {
                border-bottom: 1px solid $c-dark;

                @include breakpoint(tablet) {
                    border-bottom: none;
                }

                svg {
                    transform: rotate(180deg);

                    @include breakpoint(tablet) {
                        transform: rotate(270deg);
                    }
                }
            }
        }

        .filters__button--sort {
            display: none;

            @include breakpoint(tablet) {
                display: flex;
                order: 2; // sort should be last on mobile
            }
        }

        // Fabric filter should be the last
        .filters__button--fabric {
            order: 1;
        }

            .filters__button-content {
                @include breakpoint(tablet) {
                    display: flex;
                    align-items: baseline;
                    width: 100%;
                }
            }

            .filters__button-label {
                @include font(12px, 13px);

                margin-bottom: 10px;
                text-align: left;

                @include breakpoint(tablet) {
                    @include font(14px, 16px);
                    @include mediumText;

                    margin: 0;
                    white-space: nowrap;
                }
            }

            .filters__button-label--dynamic {
                @include mediumText;
                @include font(14px, 16px);

                margin-bottom: 0;

                @include breakpoint(tablet) {
                    @include regularText;

                    margin-left: 5px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
            
                .filters__button-label-title {
                    transition: color 150ms ease-in-out;

                    .filters__button:hover & {
                        color: var(--site-colour-primary);
                    }

                    @include breakpoint(tablet) {
                        position: relative;

                        &::before {
                            content: '(';
                        }

                        &::after {
                            content: ')';
                        }
                    }
                }

                .filters__button-label-title--Colour {
                    text-transform: capitalize;
                }

        .filters__summary {
           display: none;

            @include breakpoint(tablet) {
                @include font(12px, 15px);

                display: block;
                margin: 20px auto;
                text-align: center;
            }
        }

        .filters__form {
            display: none;
            position: absolute;
            bottom: -1px; /* allow for the border */
            transform: translateY(100%);
            background: $c-white;
            z-index: 1;
            flex-wrap: wrap;
            text-align: left;
            width: 100%;
            padding: 20px 0;
            border-bottom: 1px solid $c-border;

            .filters--open & {
                display: flex;
            }

            @include breakpoint(tablet) {
                all: unset;
                text-align: left;
            }
        }

        .filters__secondary-filters {
            display: flex;

            @include breakpoint(tablet) {
                width: 100%;
                padding: 20px 0;
            }

            .listing__filters--no-filters & {
                padding-bottom: 8px;
            }
        }

            .filters__checkbox-wrap {
                margin-bottom: 4px;

                @include breakpoint(tablet) {
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }

            .filters__checkbox-wrap--no-accordion {
                margin: 10px 0;
            }

            .filters__checkbox {
                display: inline-flex;
                align-items: center;

                &:not(:last-of-type) {
                    margin-right: 30px;

                    @include breakpoint(tablet) {
                        margin-right: 0;
                    }
                }

                label {
                    @include form-label;

                    color: $c-body;
                    padding-left: 35px;
                    padding-top: 0;
                }

                .filters__checkbox-wrap--no-accordion & {
                    margin: 0;
                }
            }

                .filters__tooltip {
                    display: flex;
                    align-items: center;
                    color: $c-light-grey;

                    svg {
                        fill: currentColor;
                        transition: fill .2s ease;
                    }

                    &:hover {
                        color: $c-dark;
                    }
                }

            .filters__sort-wrapper {
                display: flex;
                align-items: center;

                @include breakpoint(tablet) {
                    display: none;
                }
            }

                .filters__sort {
                    @include breakpoint(tablet) {
                        @include font(13px, 13px);

                        display: flex;
                        position: relative;
                        align-items: center;
                        padding-left: 15px;
                        padding-right: 15px;

                        span {
                            z-index: 1;
                        }
                    }

                    &.filters__button {
                        @include breakpoint(tablet) {
                            border: none;
                        }
                    }
                }

    .filters__fixed-cta {
        display: none;

        @include breakpoint(tablet) {
            display: block;
            position: fixed;
            padding: 12px $site-mobile-gutter;
            background-color: $c-white;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
            border-top: 1px solid $c-grey;
        }
    }

        .filters__group-img {

            @include breakpoint(tablet) {
                width: 20px;
                height: 20px;
                flex-shrink: 0;
                margin-left: 8px;
            }
        }

        .filters__group-img.filters__group-img {
            @include breakpoint(tablet) {
                display:none;
            }
        }

        .filters__group-label {

            @include breakpoint(tablet) {
                @include font(13px, 16px);

                margin: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
