.swatch-brochure {
    background-color: $c-light-grey;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    margin-bottom: 25px;

    svg {
        fill: $c-dark;
        margin-right: 10px;
    }

    .button--link {
        margin-left: auto;
    }

    .added {
        display: none;
    }

    &:has(input[type="checkbox"]:checked) {
        .not-added {
            display: none;
        }

        .added {
            display: block;
        }
    }
}

    .swatch-brochure__title {
        @include subtitle;

        @include breakpoint(tablet) {
            @include font(14px, 20px);
        }
    }

    .swatch-brochure__text {
        flex-basis: 100%;

        @include breakpoint(tablet) {
            @include font(12px, 24px);
        }
    }
