.listing-callout {
    background-color: var(--site-colour-secondary);
    color: var(--site-text-colour-secondary);

    .site-colour-secondary--white & {
        border: 1px solid $c-grey-border;
    }
}

.listing-callout--single {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 230px;

    &:hover {
        .listing-callout__action {
            .button {
                transition: opacity 100ms ease-in;
                color: inherit;
                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }

    .listing-callout__content {
        display: flex;
        justify-content: center;
        padding-left: 12.5%;
        padding-right: 12.5%;
        align-items: center;
        text-align: center;
        flex-direction: column;
        width: 100%;

        .listing-callout__action {
            @include font(14px, 24px);
        }

        @include breakpoint(phablet) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    &.listing-callout--image {
        .listing-callout__media {
            @include breakpoint(phablet) {
                img {
                    position: relative;
                }
            }
        }
    }

    &.listing-callout--top .listing-callout__content {
        top: 25px;

        @include breakpoint(phablet) {
            top: 0;
        }
    }

    &.listing-callout--middle .listing-callout__content {
        top: 50%;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
    }

    &.listing-callout--bottom .listing-callout__content {
        bottom: 25px;

        @include breakpoint(phablet) {
            bottom: 0;
        }
    }

    .listing-callout__title {
        @include font(28px, 36px);
        font-weight: 500;

        @include breakpoint(tablet) {
            @include font(24px, 32px);
        }
    }

    .listing-callout__body,
    .listing-callout__action {
        @include font(14px, 24px);
    }
}

.listing-callout--double {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: span 2;

    @include breakpoint(phablet) {
        grid-column: span 1;
        grid-template-columns: 1fr;
        grid-template-rows: 1.5fr 1fr;
    }

    .listing-callout__media {
        @include breakpoint(phablet) {
            order: 1;
        }
    }

    .listing-callout__content {
        display: flex;
        justify-content: center;
        padding-left: 12.5%;
        padding-right: 12.5%;
        align-items: center;
        text-align: center;
        flex-direction: column;

        @include breakpoint(phablet) {
            padding-top: 40px;
            padding-bottom: 40px;
            order: 2;
        }
    }

        .listing-callout__title {
            @include font(28px, 36px);
            font-weight: 500;

            @include breakpoint(tablet) {
                @include font(24px, 32px);
            }
        }

        .listing-callout__body,
        .listing-callout__action {
            @include font(14px, 24px);
        }
}

.listing-callout--image {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.7;
        height: 50%;
        background: linear-gradient(180deg, rgba($c-black, 0) 0%, $c-black 100%);
        pointer-events: none;
    }

    .listing-callout__media {
        display: block;
        position: relative;
        height: 100%;
        width: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .listing-callout__content {
        position: absolute;
        text-align: center;
        z-index: 1;
        color: $c-white;
    }

        .listing-callout__title {
            padding-left: $site-mobile-gutter;
            padding-right: $site-mobile-gutter;
            @include font(20px, 24px);
            font-weight: 500;
        }

        .listing-callout__body,
        .listing-callout__action {
            @include font(14px, 24px);
        }
}

    .listing-callout__media {
        overflow: hidden;

        img {
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 300ms ease-in-out;

            .listing-callout:hover & {
                transform: scale(1.025);
            }
        }
    }

        .listing-callout__action {

            a.button {
                transition: opacity 100ms ease-in;

                &:hover {
                    opacity: 0.75;
                    color: inherit;
                }
            }
        }

        .listing-callout__title,
        .listing-callout__body {
            margin-bottom: 10px;
        }
