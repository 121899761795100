@use 'sass:math';

.chooser-overlay {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    column-gap: 23px;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
}

.chooser-overlay-loading {
}

    .chooser-overlay__title {
        @include small-title;

        text-align: center;
        grid-column: 1 / 3;
        padding-bottom: 20px;
        margin-bottom: 40px;
        border-bottom: 1px solid $c-grey-border;

        @include breakpoint(tablet) {
            grid-column: auto;
            grid-row: auto;
            text-align: left;
            margin-bottom: 23px;
            padding-right: 40px;
        }
    }

    .chooser-overlay__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 23px;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr 1fr;
            gap: 10px;

            [data-phase="option-viewing"] & {
                display: none;
            }
        }
    }

    .chooser-overlay__options {
        @include breakpoint(tablet) {
            [data-phase="option-viewing"] & {
                display: none;
            }
        }
    }

    .chooser-overlay__filters {
        grid-column: 1 / 2;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 20px;
        padding-bottom: 40px;
        margin-bottom: 35px;
        border-bottom: 1px solid $c-grey-border;

        @include breakpoint(tablet) {
            gap: 10px;
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 10px;

            [data-phase="option-viewing"] & {
                display: none;
            }
        }
    }

    .chooser-overlay__panel {
        padding:  0 0 0 23px;
        grid-column: 2 / 3;
        grid-row: 2 / 5;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: sticky;
        top: -10px;
        align-self: start;

        @include breakpoint(tablet) {
            display: none;
            position: relative;
            top: auto;
            align-self: auto;
            padding: 0;
            grid-column: auto;
            grid-row: auto;

            [data-phase="option-viewing"] & {
                display: flex;
            }
        }
    }

    .chooser-overlay__footer {
        padding: 30px 0 10px;
        border-top: 1px solid $c-grey-border;
        width: 100%;
        margin-top: auto;

        @include breakpoint(tablet) {
            padding-top: 20px;
            margin-top: 40px;

            [data-phase="option-viewing"] & {
                margin-bottom: 60px;
            }
        }
    }

    .chooser-overlay__fabric-footer {
        @include breakpoint(tablet) {
            [data-phase="option-viewing"] & {
                margin: 20px 0 80px;
            }
        }
    }
