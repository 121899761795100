.showrooms-popup {
    display: flex;
    align-items: center;
    gap: 23px;

    @include breakpoint(phablet) {
        flex-direction: column;
        align-items: center;
    }
}

    .showrooms-popup__subtitle {
        @include large-subtitle;

        display: flex;
        align-items: baseline;
        gap: 6px;
    }
