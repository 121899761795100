.mattresses-bed-seeks-mattress {
  width: 670px;
  height: 548px;
}

.mattresses-quote {
  width: 1290px;
  height: 138px;
}

.mattresses-trial {
  width: 946px;
  height: 413px;
}
