@mixin swatch-inactive {
    position: relative;
    outline: 1px solid $c-grey;
    outline-offset: -1px;

    &::after {
        content: '';
        position: absolute;
        left: 1px;
        right: 1px;
        top: 1px;
        bottom: 1px;
        border: 1px solid $c-white;
        pointer-events: none;
    }
}