.upsell-overlay {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    column-gap: 23px;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        gap: 0;
    }
}

.upsell-overlay__content {
    @include breakpoint(tablet) {
        display: block;

        [data-phase="option-viewing"] & {
            display: none;
        }
    }
}

.upsell-overlay--block {
    display: block;
}

    .upsell-overlay__title {
        @include small-title;

        text-align: center;
        grid-column: 1 / 3;
        padding-bottom: 20px;
        margin-bottom: 40px;
        border-bottom: 1px solid $c-grey-border;

        @include breakpoint(tablet) {
            grid-column: 1 / 2;
            text-align: left;
            margin-bottom: 23px;
            padding-right: 40px;
        }
    }

    .upsell-overlay__subtitle {
        @include large-subtitle;

        margin-bottom: 15px;
    }

    .upsell-overlay__description {
        max-width: 75%;
        margin-bottom: 15px;

        @include breakpoint(tablet) {
            max-width: 100%;
        }
    }

    .upsell-overlay__confirm {
        @include breakpoint(tablet) {
            display: none;
            gap: 23px;
            align-items: center;
            justify-content: space-between;
            padding: $site-mobile-gutter;
            position: sticky;
            background-color: $c-white;
            left: 0;
            right: 0;
            bottom: -25px;

            [data-phase="option-viewing"] & {
                display: flex;
            }

        }

        .button--link {
            display: none;

            @include breakpoint(tablet) {
                display: inline-flex;
            }
        }
    }

    .upsell-overlay__confirm--center {

        justify-content: center;
        @include breakpoint(tablet) {
            display: flex;
            [data-phase="option-viewing"] & {
                display: none;
            }
        }
    }

    .upsell-overlay__grid {
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(3,minmax(0,1fr));
        gap: 23px;
        margin-bottom: 100px;

        @include breakpoint(tablet) {
            grid-template-columns: repeat(2,minmax(0,1fr));
            gap: 10px;
        }

        @include breakpoint(mobile) {
            grid-template-columns: repeat(1,minmax(0,1fr));
        }
    }

    .upsell-overlay__features {
        grid-column: 1 / 3;

        @include breakpoint(tablet) {
            grid-column: 1 / 2;
        }
    }

    .upsell-overlay__panel {
        @include breakpoint(tablet) {
            display: none;

            [data-phase="option-viewing"] & {
                display: block;
            }
        }
    }

    .upsell-overlay__footer{
        position: fixed;
        bottom: 0;
        left: 30px;
        right: 30px;
        text-align: center;
        grid-column: 1 / 3;
        padding: 30px;
        border-top: 1px solid $c-grey-border;
        background-color: white;

        @include breakpoint(tablet) {
            grid-column: 1 / 2;
            text-align: left;
            left: 12px;
            right: 12px;
        }

        .button {
            margin: auto;
            display: block;
        }
    }
