.showroom-detail {
    text-align: center;
}

    .showroom-detail__title {
        @include medium-title;
        @include boldText;

        text-transform: uppercase;
        margin-bottom: 25px;
        letter-spacing: 0.5px;
    }

    .showroom-detail__small-title {
        @include font(28px, 40px);
        @include mediumText;

        margin-bottom: 40px;
    }

    .showroom-detail__contact {
        @include large-subtitle;

        margin-bottom: 13px;
    }
