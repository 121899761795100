.gr1d {
    display: grid;
    align-items: start;
}

// Column count and span count
@for $i from 1 through 12 {
    .cols-#{$i} {
        grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
    }
    .col-span-#{$i} {
        grid-column: span #{$i};
    }
}

@each $key, $value in $sizes {
    @if $value != false {
        $breakpoint: map-get($breakpoint-config, $value);
        $type: map-get($breakpoint, 'type');
        $size: map-get($breakpoint, 'size');
    
        @for $i from 1 through 12 {
            @media (#{$type}: #{$size}px) {
                .cols-#{$key}-#{$i} {
                    grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
                }
                .col-span-#{$key}-#{$i} {
                    grid-column: span #{$i};
                }
            }
        }
    }
}

// Gaps
@for $i from 1 through 40 {
    // For row and column gap
    .gap-#{$i}px {
        gap: #{$i}px;
    }

    // For column gap
    .gap-x-#{$i}px {
        column-gap: #{$i}px;
    }
  
    // For row gap
    .gap-y-#{$i}px {
        row-gap: #{$i}px;
    }
}

@each $key, $value in $sizes {
    @if $value != false {
        $breakpoint: map-get($breakpoint-config, $value);
        $type: map-get($breakpoint, 'type');
        $size: map-get($breakpoint, 'size');

        @for $i from 1 through 40 {
            @media (#{$type}: #{$size}px) {
                .gap-#{$key}-#{$i}px {
                    gap: #{$i}px;
                }
                .gap-x-#{$key}-#{$i}px {
                    column-gap: #{$i}px;
                }
                .gap-y-#{$key}-#{$i}px {
                    row-gap: #{$i}px;
                }
            }
        }
    }
}

// Alignment
.align-center {
    align-items: center;
}

.align-stretch {
    align-items: stretch;
}

// Decorative
.border-top {
    padding-top: 50px;
    border-top: 1px solid $c-grey-border;
}
