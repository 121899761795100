dl.detail-list {
    display: grid;
    margin-top: 35px;
    grid-template-columns: 3fr 7fr;
    row-gap: 25px;
    column-gap: 23px;
    color: var(--site-text-colour-secondary);

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        gap: 0;
        margin-top: 15px;
    }

    dt {
        @include mediumText;
        @include font(18px, 24px);

        @include breakpoint(tablet) {
            @include font(16px, 24px);
        }
    }

    dd {
        @include breakpoint(tablet) {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    p {
        margin: 0;
    }

    a {
       @include link;

       color: var(--site-text-colour-secondary);

       &:hover {
            color: var(--site-text-colour-secondary);
            opacity: 0.9;
        }
    }
}
