.willitfit__size {
    margin: 0;

    input {
        display: none;
    }

    label {
        padding: 23px;
        border: 1px solid #ccc;
    }

    label:has(input:checked) {
        border: 1px solid black;
    }
}

.willitfit__space {
    border: 1px solid #ccc;
    padding: 23px;
    margin-bottom: 20px;

    h3 {
        @include small-title;
        margin-bottom: 23px;
    }
}

.willitfit__measurements {
    margin-bottom: 20px;
}

.willitfit__measurement-notice {
    display: none;
    p {
        color: #d43354;
        padding-bottom: 20px;
    }

    a {
        font-weight: 500;
    }
}
