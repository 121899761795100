$bottombar: 78px;

html.fauxdal-mode {
    overflow: hidden;
    overscroll-behavior: none;

    body {
        overflow: hidden;
    }
}

.fauxdal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available;
    z-index: z-index(fauxdal);
    background: rgba(0, 0, 0, 0.5);
    overflow-y: visible;
    overscroll-behavior: none;

    @include breakpoint(mobile) {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    #outer-wrapper & {
        display: none;
        position: static;
    }

    .non-fauxdal & {
        display: block !important;
    }

    .fauxdal & {
        display: none;
    }

    .wrap--full {
        padding-left: 0;
        padding-right: 0;
    }
}
.fauxdal--fixed {
    position: fixed;
    display: flex;
    flex-direction: column;
}

.fauxdal[data-closeable="false"]:not(.fauxdal--mobile) {
    .js-close {
        display: none;
    }
}

.fauxdal--alt {
    display: flex;
    flex-direction: column;
}

.fauxdal--white {
    position: fixed;
    display: flex;
    flex-direction: column;
    background: $c-white;
}

.fauxdal--scrollbar {
    overflow-y: scroll;
}

.fauxdal__container {
    .fauxdal--alt &,
    .fauxdal--white & {
        margin: auto;

        @include breakpoint(tablet) {
            margin: 0;
        }
    }
}

// this mobile class is horribly fragile
// avoid rearranging anything due to specificity concerns

.fauxdal--mobile {
    overflow: visible;
    height: auto;
    display: block;
    min-height: auto;
    max-height: auto;

    #outer-wrapper & {
        display: block;
        position: static;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    @include breakpoint(tablet) {
        display: flex;
        height: 100%;
        overflow: scroll;
    }

    &.fauxdal--hide {
        display: none;

        @include breakpoint(tablet) {
            display: block;

            // We need this because of nested Fauxdals

            .fauxdal--mobile & {
                display: none;
            }
        }

        #outer-wrapper & {
            display: none;
        }
    }
}

.fauxdal__content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    max-width: 800px;
    overflow-x: hidden;
    overflow-y: overlay;
    max-height: 100vh;
    background: $c-white;
    border: 1px solid $c-dark;
    box-shadow: 0px 0px 25px 0px rgba($c-black,0.025);
    text-align: center;
    @include flush-last-child;
    margin: 0 auto;
    padding: 40px 20px;
    transition: max-height 250ms ease-in-out, max-width 250ms ease-in-out;

    .non-fauxdal & {
        top: 0;
        transform: none;
    }

    &::-webkit-scrollbar {
        width: 15px;
        height: 15px;
    }

    .fauxdal--alt & {
        top: 0;
        max-width: 995px;
        margin-top: auto;
        margin-bottom: auto;
        padding: 70px;
        transform: none;
        border: none;

        @include breakpoint(tablet) {
            max-width: none;
            min-height: 100%;
            margin: 0;
            padding: 20px 20px 30px;
        }
    }

    .fauxdal--full & {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto;
        max-width: none;
        padding-top: 40px;
        border: none;
        transform: none;

        @include breakpoint(tablet) {
            padding: 12px 10px;
        }
    }

    .fauxdal--full-mobile & {

        @include breakpoint(tablet) {
            padding: 14px 10px;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: auto;
            max-width: none;
            padding-top: 40px;
            border: none;
            transform: none;
        }
    }

    .fauxdal--white & {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto;
        max-width: none;
        margin-top: auto;
        margin-bottom: auto;
        padding: 40px 40px 0;
        transform: none;
        border: none;

        @include breakpoint(tablet) {
            max-width: none;
            margin: 0;
            padding: 70px 20px 0;
        }
    }

    .fauxdal--mobile & {
        padding: 0;
        min-height: auto;
        text-align: left;
        overflow: visible;
        box-shadow: none;

        @include breakpoint(tablet) {
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 80px;
            overflow: scroll;
        }
    }

    &.fabric-chooser {
        @media screen and (max-height:700px) {
            padding-top: 5px;
        }
    }
}

#outer-wrapper {
    .fauxdal--white,
    .fauxdal--full {
        .fauxdal__content {
            position: relative;
        }
    }
}

.fauxdal__content.fauxdal__content--full-mobile {
    @include breakpoint(mobile) {
        top: 0;
        min-height: 100vh;
        max-width: 100vw;
        padding-top: 40px;
        border: none;
        transform: none;
        overflow-y: scroll;
        padding: 14px 10px;
    }
}

.fauxdal__content--wider {
    max-width: 1000px;
}

.fauxdal__content--wide {
    max-width: none;
    width: 100vw;

    @include breakpoint(large) {
        margin: 30px auto;
        max-width: 1200px;
    }
}

.fauxdal__content--high {
    top: 0;
    transform: translateY(0);
}

.fauxdal__content--left {
    text-align: left;
}

.fauxdal__content.fauxdal__content--bottom-bar {
    bottom: $bottombar;
}

// Close/back button (container)
.fauxdal__close {
    display: block;
    position: absolute;
    z-index: 2;
    padding: 15px;
    top: 5px;
    right: 5px;

    .fauxdal--white & {
        @include breakpoint(tablet) {
            top: 5px;
            right: 5px;
        }
    }

    .fabric-chooser-mobile-content & {
        right: -10px;
    }

    .fauxdal--full & {
        top: 5px;
        right: 5px;
    }

    .fauxdal--mobile & {
        display: none;

        @include breakpoint(tablet) {
            display: block;
            top: 4px;
            right: 5px;
        }
    }

    @include breakpoint(phablet) {
        top: 0;
        right: 0;
    }

    svg {
        .fauxdal--white & {
            @include breakpoint(tablet) {
                width: 15px;
                height: 15px;
            }
        }

        .fauxdal--full & {
            width: 17px;
            height: 17px;
        }
    }
}

.fauxdal__top-bar {
    position: relative;
    margin: 0 -20px 15px -20px;
    padding: 0 20px;

    @include breakpoint(tablet) {
        height: 50px;
        border-bottom: 1px solid $c-border;
        margin-bottom: 15px;
    }
}

// Cancel button
.fauxdal__cancel {
    display: block;
    position: absolute;
    z-index: 2;
    padding: 15px;
    top: 0;
    right: 20px;
}

.fauxdal__back {
    @include mediumText;
    @include font(13px, 25px);

    position: absolute;
    top: 18px;
    left: 0;
    display: flex;
    align-items: center;
    letter-spacing: 0.65px;
    z-index: 2;
    transform: translateY(-50%);
    text-transform: uppercase;
    text-decoration: underline;

    .fauxdal__top-bar & {
        top: 7px;
        left: 20px;
    }

    .fauxdal__bottom-bar & {
        position: static;
        transform: none;
    }

    @include breakpoint(tablet) {
        top: 14px;
        left: 10px;
        transform: none;
    }

    svg[role="img"] {
        width: 6px;
        height: 10px;
        margin-right: 10px;
        display: inline-block;
    }
}

.fauxdal__title {
    @include heading();
    padding-bottom: 10px;
    text-align: center;

    .fauxdal--white & {
        @include font(30px, 37px);

        margin-bottom: 37px;
        padding: 0 100px;

        @include breakpoint(tablet) {
            @include font(26px, 32px);

            margin-bottom: 18px;
            padding: 0;
        }
    }

    .fauxdal__success & {
        margin-bottom: 26px;
        padding-bottom: 0;
    }
}

.fauxdal__title--chooser {
    @include font(30px, 37px);

    border-bottom: 1px solid $c-grey;
    margin-bottom: 30px;
}

.fauxdal__title--chooser-left {
    @include font(30px, 37px);

    padding-bottom: 0;
    margin-bottom: 30px;
    text-align: left;
    min-height: 50px;

    @include breakpoint(tablet) {
        @include font(26px, 32px);

        margin-right: 40px;
        margin-bottom: 10px;
        letter-spacing: 1.3px;
    }

    .fauxdal__content--top-bar & {
        margin-right: 0;
    }
}

.fauxdal__title--flush-bottom {
    margin-bottom: 0;
}

.fauxdal__title--sooner {
    @include font(30px, 37px);

    margin-bottom: 0;
    padding-bottom: 0;
    text-align: left;

    @include breakpoint(tablet) {
        @include font(26px, 32px);

        margin-right: 54px;
        margin-bottom: 10px;
        letter-spacing: 1.3px;
    }
}

.fauxdal__title--express-swatch {
    @include font(24px, 29px);

    margin-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 0;
    text-align: left;
    letter-spacing: 1px;

    @include breakpoint(tablet) {
        @include font(20px, 24px);

        margin-bottom: 15px;
        letter-spacing: 0.5px;
    }
}

.fauxdal__legend {
    @include font(13px, 16px);
    letter-spacing: 0.65px;
    margin: 26px 0 23px 0;
}

.fauxdal__heading {
    @include font(20px, 24px);
    @include mediumText;
    letter-spacing: 0.5px;
}

.fauxdal__subtitle {}

.fauxdal__subtitle--express-swatch {
    @include font(24px, 29px);

    margin-bottom: 10px;
    letter-spacing: 0.5px;

    @include breakpoint(tablet) {
        @include font(20px, 24px);

        letter-spacing: 0.5px;
    }
}

.fauxdal__longcopy {
    @include rem(15px);
    margin-top: 10px;
    letter-spacing: 0.65px;

    a:not(.button) {
        @include link;
    }
}

.fauxdal__success {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $c-grey;
}

    .fauxdal__success-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
        margin: 0 auto;
        background: $c-grey;
        border-radius: 100%;

        svg {
            display: block;
            width: 26px;
            height: 20px;
        }
    }

.fauxdal__footer {
    border-top: 1px solid $c-grey;
    padding: 25px 20px 0 20px;
    margin: 0 20px;
    text-align: center;
    @include rem(15px);
    letter-spacing: 1.5px;

    .fauxdal--white & {
        margin: 60px -40px 0;

        @include breakpoint(tablet) {
            margin-top: 30px;
        }
    }

    a {
        text-decoration: underline;
    }
}

.fauxdal__bottom-bar {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px 16px;
    height: $bottombar;
    background: $c-white;
    box-shadow: 0 -10px 15px $c-border;
    z-index: 2;
    overflow: hidden;
    text-align: center;
}

.fauxdal__content.fauxdal__content--quickbuy {
    max-width: 1000px;

    .fauxdal__bottom-bar {
        position: static;
        box-shadow: none;
        height: auto;
    }

    @include breakpoint(tablet) {
        max-width: 100%;
    }

    @include breakpoint(mobile) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: auto;
        transform: none;
        padding-bottom: $bottombar;

        .fauxdal__bottom-bar {
            position: fixed;
            box-shadow: 0 -10px 15px $c-border;
            height: $bottombar;
        }
    }
}
