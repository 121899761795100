.fitwiz-door {
  width: 250px;
  height: 250px;
}

.fitwiz-hallway {
  width: 250px;
  height: 250px;
}

.fitwiz-landing {
  width: 250px;
  height: 250px;
}
