.finance-message {
    line-height: 1.7;
}

.basket-finance {
    margin: 10px auto 20px auto;
    padding: 10px;
    text-align: center;
    @include smallprint;
    @include flush-last-child;
    border: 1px solid $c-border;

    small {
        display: block;
    }

    a {
        white-space: nowrap;
    }
}

.finance-calculator {
}

.finance-calculator__shipping {
    display: none;

    .finance-calculator--shipping & {
        display: inline-block;
    }

    label {
        @include rem(11px);
    }

    span {
        display: inline !important;
    }
}

.klarna-osm {
    width: 350px;
    margin: 30px auto;
    transition: opacity 2s;

    iframe {
        height: 120px !important;
    }
}

.klarna-osm--hide {
    opacity: 0;
    transition: opacity 0s;
    pointer-events: none;
}

table.financial {
    max-width: 100%;
    border-collapse: collapse;
    margin: 0 auto 30px auto;

    th {
        background: $c-grey;
        color: #000;
    }

    td,
    th {
        padding: 5px 10px;
        border: 1px solid $c-border;
        text-align: left;
    }

    td:not(:first-child),
    th:not(:first-child) {
        text-align:right;
    }

    thead td:first-child {
        border: none;
    }
}
