.listing {
    display: block;
}

.listing__media {
    margin-bottom: 15px;
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
        transition: transform 300ms ease-in;
        
        .listing:hover & {
            transform: scale(1.075)
        }
    }
}

.listing__title {
    @include font(16px, 17px);
    @include mediumText;

    padding-bottom: 3px;
    display: inline-block;
    border-bottom: 1px solid currentColor;
    transition: color 150ms ease-in;

    .listing:hover & {
        color: var(--site-colour-primary);
    }
}

.listing__date {
    display: block;
    margin-top: 10px;
}
