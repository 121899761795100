@use 'sass:math';

.listing-card {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $c-grey-border;

    .listing-item & {
        border: none;
    }

    input {
        display: none;
    }

    &:has(input:checked) {
        border: 1px solid $c-dark;

        .listing-card__more-details {
            display: block;
        }
    }
}

.listing-card__more-details {
    display: none;
}

.listing-card--size-chooser {
    cursor: pointer;

    .price {
        @include mediumText;

        @include breakpoint(tablet) {
            @include smallprint;
        }
    }
}

    .listing-card__media {
        position: relative;
    }

        @keyframes bounceScroll {
            0% { margin-left: 0; }
            40% { margin-left: -40px; }
            50% { margin-left: -36px; }
            70% { margin-left: -38px; }
            100% { margin-left: 0; }
        }

        .listing-card__image {
            @include aspect(math.div(4, 3));

            position: relative;
            -ms-overflow-style: none; /* hide scrollbar for Internet Explorer, Edge */
            scrollbar-width: none; /* hide scollbar for Firefox */

            &::-webkit-scrollbar {
                display: none; /* hide scrollbar for Chrome, Safari, and Opera */
            }

            @include breakpoint(phablet) {
                display: flex;
                scroll-behavior: smooth;
                scroll-snap-type: x mandatory;
                overflow-x: auto;
                width: 100%;
            }

            picture {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                @include breakpoint(phablet) {
                    all: unset;
                    position: relative;
                    width: 100%;
                    scroll-snap-align: center;
                    flex-shrink: 0;
                }

                &.animate-bounce {
                    animation: bounceScroll 1000ms cubic-bezier(.56,1.26,1,1.1);
                    animation-fill-mode: forwards;
                }
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transition: opacity 150ms ease-in-out;
            }

            img.listing-card__lifestyle {
                @media (hover: hover) and (pointer: fine) {
                    opacity: 0;
                }
            }

            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    img.listing-card__lifestyle {
                        opacity: 1;
                    }

                    img.listing-card__cutout {
                        opacity: 0;
                    }
                }
            }

            .listing__items---lifestyle-trial & {

                img.listing-card__lifestyle {
                    @media (hover: hover) and (pointer: fine) {
                        opacity: 1;
                    }
                }

                picture:first-of-type {
                    order: 2;
                }

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        img.listing-card__lifestyle {
                            opacity: 0;
                        }

                        img.listing-card__cutout {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .listing-card__image.listing-card__image--swapimages {
            img.listing-card__lifestyle {
                opacity: 0;
            }

            img.listing-card__cutout {
                opacity: 1;
            }
        }

        .listing-card__pagination {
            display: none;
        }

        .listing:not(.swiper) .listing-card__pagination {
            display: none;

            @include breakpoint(phablet) {
                display: flex;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 7px;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 25px;
                    height: 25px;
                }

                div[role="button"] {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border: 1px solid $c-dark;
                        border-radius: 50%;
                        height: 14px;
                        width: 14px;
                        background-color: $c-white;
                    }
                }

                div[role="button"].active::after {
                    box-shadow: inset 0 0 0 2px $c-white;
                    background-color: $c-dark;
                }
            }

            .listing__items---lifestyle-trial & {
                li:first-of-type {
                    order: 2;
                }
            }
        }

    .listing-card__content {
        padding: 16px 24px 0px;
        border-top: 1px solid $c-grey-border;
        position: relative;

        .listing-card--tight & {
            padding: 14px 20px;
        }

        .listing-card--size-chooser & {
            border-top: none;

            @include breakpoint(phablet) {
                padding-right: 12px;
                padding-left: 12px;
            }
        }

        @include breakpoint(phablet) {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

        .listing-card__title {
            @include subtitle;

            margin-bottom: 5px;

            .listing-card--tight & {
                @include font(14px, 22px);
            }

            .listing-card--size-chooser & {
                @include subtitle;

                @include breakpoint(tablet) {
                    @include font(14px, 22px);
                }
            }
        }

        .listing-card__text {
            @include font(12px, 18px);
        }

        .listing-card__prices {
            display: flex;
            align-items: baseline;
            gap: 4px;
            margin-bottom: 6px;

            .price {
                gap: 5px;
            }

            .price__value {
                font-weight: 500;
            }

            .price__value--was {
                font-size: 13px;
            }
        }

        .listing-card__dimensions {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: calc(10px * -1);
            margin-right: calc(10px * -1);
            margin-bottom: 10px;

            @include breakpoint(tablet) {
                margin-left: 0;
                margin-right: 0;
            }

            svg {
                fill: $c-grey-border;
            }

            span {
                @include font(12px, 20px);

                background-color: $c-white;
                padding-left: 6px;
                padding-right: 6px;
                z-index: 1;

                @include breakpoint(tablet) {
                    line-height: 1;
                }
            }

            &::after {
                content: '';
                position: absolute;
                left: 1px;
                right: 1px;
                height: 1px;
                background-color: $c-grey-border;
                top: 50%;
                transform: translateY(-50%);
            }
        }

    .listing-card__swatches {
        display: inline-flex;
        align-items: center;
        gap: 5px;
        padding: 0 24px;
        width: 100%;
    }

        .listing-card__swatch {
            display: flex;
        }

            .listing-card__swatch-button {
                @include swatch-inactive;
                @include swatch-hover;

                position: relative;
                height: 20px;
                width: 20px;
                cursor: pointer;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: -1;
                }

                input {
                    display: none;
                }
            }

            .listing-card__swatch-button--active {
                @include swatch-active;
            }

        .listing-card__swatch-link {
            margin-top: 1px;
            margin-left: 5px;

            &:hover {
                text-decoration: underline;
            }

            @include breakpoint(phablet) {
                span {
                    display: none;
                }
            }
        }

        .listing-card__quick-buy {
            position: absolute;
            right: 0;
            top: -50px;
            width: 50px;
            height: 50px;
            display: flex;
            border: 1px solid $c-grey-border;;
            border-bottom: none;
            border-right: none;
            background-color: $c-white;

            svg {
                justify-content: center;
                align-items: center;
                margin: auto;
                height: 20px;
                width: 20px;
            }

            &:hover {
                background-color: var(--site-colour-primary);
                color: var(--site-text-colour-primary);
                fill:  var(--site-text-colour-primary);
                transition: 250ms all ease;
             }
        }
