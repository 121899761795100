$grid-gutter-h: 30px;
$grid-gutter-v: 30px;

// Grid gutters
@mixin grid-gutter($gutter-x:$grid-gutter-h, $gutter-y:$grid-gutter-v, $switchMargin:false) {
    @if $switchMargin {
        margin-right: 0;
        margin-left: -($gutter-x);
    } @else {
        margin-right: -($gutter-x);
    }

    // Use the correct negative margin for flush bottom
    &.grid--flush-bottom {
        margin-bottom: -($gutter-y);
    }

    // Using child selector otherwise nested grids break (inherit the wrong gutter)
    & > .grid__item {
        margin-bottom: $gutter-y;

        @if $switchMargin {
            padding-right: 0;
            padding-left: $gutter-x;
        } @else {
            padding-right: $gutter-x;
        }
    }
}
