$button-color: #E9CB78;
$text-color: #154651;

.private-sale {
    position: fixed;
    bottom: 30px;
    left: 0;
    z-index: 11;
}

.private-sale__link {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    background: $button-color;
    border-radius: 0 100px 100px 0;
    box-shadow: 0 0 15px 0 rgb(52 52 52 / 20%);
    overflow: hidden;
    padding: 8px 10px;

    @include font(12px, 15px);
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    color: $text-color;

    .private-sale__link-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        background-color: $text-color;
        svg {
            width: 100%;
            height: 100%;
            fill: $button-color;
            transform: scale(0.45);
        }
    }
}
