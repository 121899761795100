.job {}

.job__title {
    @include heading();
    margin-bottom: 10px;
    border-top: 1px solid $c-light-grey;
    padding-top: 30px;
    margin-top: 30px;
}

.job__desc {
    @include intro();
    margin-bottom: 10px;
}

.job-summary__field {
    display: inline;
    color: #000;
    font-size: 17px;
    margin-bottom: 10px;
}

.job-summary__value {
    display: inline;
    font-size: 17px;
    margin-bottom: 10px;

    &:not(:last-of-type) {
        margin-right: 0.5em;
    }
}

/*** job showroom ***/

.job-showroom {
    margin-bottom: 50px;
    position: sticky;
    top: $header-height + $notice-height;

    @include breakpoint(tablet) {
        position: relative;
        top: unset;
    }
}

.job-showroom__media {
    margin-bottom: 20px;
    display: block;

    img {
        width: 100%;
        height: auto;
    }
}

.job-showroom__title {
    @include small-title;
}

.job-showroom__address {
    @include intro;
}

.showrooms-list__actions {
}

/*** job details ***/

.job-details {
    background-color: $c-light-grey;
    position: sticky;
    top: calc($header-height + $notice-height + 40px);
    padding: 20px;

    @include breakpoint(tablet) {
        position: relative;
        top: unset;
    }
}

.job-details__list {
    display: grid;
    grid-template-columns: min-content 1fr;
    column-gap: 5px;
}

.job-details__field {
    @include body;
    @include mediumText;

    margin-bottom: 10px;
    display: inline-block;
    white-space: nowrap;
}

.job-details__value {
    @include body;

    margin-bottom: 10px;
    display: inline-block;

    a {
        text-decoration: underline;
    }
}

.job-details__actions {
    display: flex;
    gap: 20px;
}

.job-form {
    @include secondary-panel;
    text-align: center;
    position: relative;
    @include flush-last-child;
}
