.notice-bar {
    @include font(12px, 17px);

    position: sticky;
    top: 0;
    z-index: 20;
    padding: 13px $site-gutter 11px;
    background-color: var(--site-colour-primary);
    display: flex;
    justify-content: space-between;
    color: var(--site-text-colour-primary);

    &:has([data-private-sale="true"]) {
        background-color: #154651;
    }

    @include breakpoint(nav) {
        padding-left: $site-mobile-gutter;
        padding-right: $site-mobile-gutter;
        justify-content: center;
        height: $notice-height-mobile;
    }

    @include breakpoint(tablet) {
        padding-top: 10px;
        padding-bottom: 9px;
    }
}

    .notice-bar__link-wrap {
        display: inline-flex;
        align-items: center;

        a {
            @include mediumText;

            color: inherit;
            border-bottom: 1px solid transparent;
            transition: border-color 150ms ease-in-out;

            &:hover {
                border-color: var(--site-text-colour-primary);
            }
        }
    }

    .notice-bar__phone {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }
    }

    .notice-bar__link--hide-nav,
    .notice-bar__phone {
        @include breakpoint(nav) {
            display: none;
        }
    }

    .notice-bar__icon {
        display: flex;
        margin-right: 5px;
        width: 21px;
        height: 21px;

        svg {
            width: 100%;
            height: auto;
            fill: currentColor;

            &.global-icon-location {
                stroke: currentColor;
            }
        }
    }

    .notice-bar__link--bordered {
        border-bottom: 1px solid var(--site-text-colour-primary);
    }

    .notice-bar__swiper {
        flex: 1;
        // CSS Grid/Flexbox bug size workaround
        // @see https://github.com/nolimits4web/swiper/issues/3599
        min-height: 0;
        min-width: 0;
        opacity: 0;
        transition: opacity 100ms ease-in-out;
    }

        // override swiper specificity
        .notice-bar__notice.notice-bar__notice {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            p {
                text-align: center;
            }

            a {
                @include mediumText;

                color: inherit;
                display: inline-block;
                border-bottom: 1px solid transparent;
                transition: border-color 150ms ease-in-out;

                &:hover {
                    border-color: var(--site-text-colour-primary);
                }
            }
        }
