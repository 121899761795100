.product-badges {
    display: flex;
}

.product-badges--card {
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    gap: 12px;
    z-index: 1;
    pointer-events: none;
    justify-content: end;
    color: $c-dark;
}

.product-badges--pdp {
    gap: 10px;
}

    .product-badges__badge {
        @include font(12px, 17px);
        @include boldText;

        padding: 10px 15px 8px;
        text-transform: uppercase;
        letter-spacing: 0.25px;
        background-color: $c-grey;
        border-radius: 20px;

        @include breakpoint(tablet) {
            @include font(10px, 14px);

            padding-top: 6px;
            padding-bottom: 5px;
        }

        .product-badges--pdp & {
            @include font(10px, 14px);

            padding-top: 8px;
            padding-bottom: 5px;
        }
    }

    .product-badges__badge--small {
        @include font(10px, 14px);

        padding: 6px 10px 5px;
        border-radius: 15px;
    }