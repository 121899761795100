.filterable {}

    .filterable__back {
        display: none;

        @include breakpoint(tablet) {
            @include mediumText;
            @include font(16px, 17px);

            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 15px;
            padding: 20px 0 16px;
            margin-bottom: 20px;
            width: 100%;
            text-align: left;
            border-bottom: 1px solid $c-grey-border;
        }
    }

    .filterable__dimension {
        background: $c-white;
        position: relative;
        width: 100%;
    }

    .filterable__dimension--activating.filterable__dimension--activating {
        display: block;
        opacity: 0;
        transition: opacity .15s ease;

        &.filterable__dimension--dimensions {
            display: flex;
        }
    }

    .filterable__dimension--active.filterable__dimension--active {
        opacity: 1;
    }

    .filterable__dimension--mobile-takeover {
        @include breakpoint(tablet) {
            box-sizing: border-box;
            position: fixed;
            top: 0;
            left: 0;
            padding: 0 10px 50px;
            width: 100%;
            height: 100%;
            z-index: 9999;
            border-bottom: 1px solid $c-border;
            overflow: auto;
        }
    }

    svg[class^="global-icon-filter"] {
        margin-top: 10px;
        height: 48px;
        width: 100%;

        @include breakpoint(tablet) {
            height: 80px;
            padding: 0 10px;
        }
    }

    .filterable__dimension--checkbox {
        width: auto;
        border: none;
        cursor: pointer;

        label {
            cursor: pointer;
        }

        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    // TODO fix this - it will be broken by global filter management.
    .filterable__dimension--colour_style,
    .filterable__dimension--dimensions {
        display: flex;
    }

    .filterable__dimension--dimensions {
        @include breakpoint(tablet) {
            flex-direction: column;
        }
    }

    .filterable__group-wrap {
        display: flex;
        column-gap: 10px;
        align-items: flex-end;

        @include breakpoint(tablet) {
            flex-direction: column;
            align-items: stretch;
            row-gap: 10px;
            padding-bottom: 20px;
        }
    }

    .filterable__inner-wrap {
        display: flex;
        flex-direction: column;

        @include breakpoint(tablet) {
            width: 100%;
        }
    }

    .filterable__colour-wrap {
        display: flex;

        @include breakpoint(tablet) {
            flex-wrap: wrap;
            gap: 5px;
            margin-bottom: 15px;
        }

        @include breakpoint(mobile) {
            display: grid;
            grid-template-columns: repeat(6, minmax(auto, 1fr));
        }

        .filterable__dimension--colour_style & {

            @include breakpoint(tablet) {
                display: grid;
                gap: 18px;
                grid-template-columns: repeat(3, 1fr);
                margin: 0;
            }

            @include breakpoint(phablet) {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    .filterable__option {}

    .filterable__option--sort {
        display: flex;
        align-items: center;

        label {
            @include smallprint;

            flex-shrink: 0;
            margin: 0;

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

        .filterable__header {
            display: none;

            @include breakpoint(tablet) {
                @include font(24px, 32px);
                @include mediumText;

                display: block;
                letter-spacing: 1px;
                margin-bottom: 10px;
            }
        }

    .filterable__dimension-description {
        @include font(13px, 18px);

        grid-column: span 3; // something hopefully safe

        a {
            display: inline-block;
        }
    }
