.swatch-select {
    min-width: 320px;

    @include breakpoint(tablet) {
        flex-grow: 1;
    }

    select {
        height: 48px;
        padding: calc(0.75em - 1px) 1em calc(0.75em - 4px) 1em;
    }
}

    .swatch-select__title {
        margin-bottom: 12px;
        display: block;
    }
