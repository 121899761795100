.tabs {
    @include flex(row);
    justify-content: center;
    padding: 0 15px;
    border-bottom: 1px solid $c-light-grey;
    .no-flexbox & {
        @include remove-white-space;
        text-align: center;
    }
}

.tabs--large {
	@include rem(17px);
}

// Make tabs grow (equally) and fill available space (flexbox only)
.tabs--fill {
    .tabs__tab {
        flex: 1;
    }
}

.tabs--chooser {
    margin-bottom: 20px;
    padding: 0;
    border-bottom: none;
}

.tabs__tab {
    display: block;
    position: relative;
    text-align: center;
    padding: 12px 30px;
    border-radius: 3px 3px 0 0;
    border-width: 1px 1px 0 0;
    border-style: solid;
    border-color: $c-light-grey;
    background: $c-grey;
    margin-bottom: 0;
    white-space: nowrap;
    text-transform: uppercase;
    @include rem(13px);
    @include mediumText;
    line-height: 16px;
    letter-spacing: 1.5px;

    &:first-of-type {
        border-left-width: 1px;
    }

    .tabs--chooser & {
        @include font(12px, 21px);

        flex: 1 1 50%;
        padding: 11px 5px;
        color: rgba($c-dark, 0.35);
        background: transparent;
        border: 1px solid transparent;
        border-bottom-color: $c-dark;
        border-radius: 0;
        letter-spacing: 1.25px;
    }
}

// Active tab styling
.tabs__tab--active {
    background: #fff;
    pointer-events: none;
    @include hover {
        background: #fff;
    }
    &::after {
        pointer-events: none;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -(1px);
        height: 1px;
        background: #fff;

        .tabs--chooser & {
            display: none;
        }
    }

    .tabs--chooser & {
        color: $c-dark;
        border: 1px solid $c-dark;
        border-bottom-color: transparent;
    }
}

.tabs__tab--changed {
    border-top: 2px solid $c-dark;
    margin-top: -1px;
}

.tabs__tab--asterisk {
    &::before {
        position: absolute;
        content: "*";
        top: 0.2em;
        right: 0.2em;
        width: 17px;
        height: 14px;
    }
}

.tabs__tab--disabled {
    display: none !important;
}

.tabs__slide {
    display: none;
}

.tabs__slide--active {
    display: block;
}

.tabs__slide--border {
    border-bottom: 1px solid $c-light-grey;
    padding: 15px 0;
    margin-bottom: 60px;
}

.tabs__slide--gap {
    margin-bottom: 30px;
}

.tabs__slide--disabled {
    opacity: 0.3;
    cursor: default;
    pointer-events: none;
}

.tabs--sized {
    max-width: 100%;
    font-size: 14px;

    .tabs__tab {
        padding: 10px 20px;

        @include breakpoint(tablet) {
            font-size: 10px;
            padding: 10px;
        }
    }

    @include breakpoint(mobile) {
        display: none;
    }
}

.tabs--content {
    margin-bottom: 30px;

    @include breakpoint(tablet) {
        .tabs__tab {
            flex: 1;
            padding: 10px;
            font-size: 14px;
        }
    }
}

.tabs__heading {
    display: none;
}

@include breakpoint(phablet) {
    .tabs--content {
        display: block;
        list-style-type: disc;
        margin: 0 0 30px 0;
        padding: 0;
        border: none;

        .tabs__tab {
            display: list-item;
            border: none;
            margin: 0 0 0 30px;
            background: none;
            text-align: left;
            padding: 0;
            font-size: 17px;
            text-transform: none;
            line-height: 1.4;
            letter-spacing: normal;

            a {
                text-decoration: underline;
            }
        }

        &::before {
            font-size: 17px;
            display: block;
            content: "Jump to:";
            margin: 0 0 10px 0;
        }

        & ~ .tabs__slide {
            display: block;
            margin-bottom: 30px;
        }
    }

    .tabs__heading {
        display: block;
    }
}

.filter-footer {
    @include breakpoint(mobile) {
        display: none;
    }
}

.filter-toggle {
    display: none;
    margin: 10px 0 20px 0;
    padding: 0 20px;
    text-align: center;

    @include breakpoint(mobile) {
        display: block;
    }
}

.filter-sort {
    @include breakpoint(mobile) {
        display: none;
    }
}

.filter-overlay {
    display: none;
    position: fixed;
    z-index: z-index(modal-overlay) !important;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
}

.filter-menu {
    display: none;
    position: fixed;
    z-index: z-index(modal);
    top: 0;
    left: 30px;
    right: 0;
    background: #fff;
}

.filter-menu__back {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;

    svg {
        padding-top: 4px;
        display: inline-block;
    }
}

.filter-menu__reset {
    position: absolute;
    top: 10px;
    left: 10px;
}

.filter-menu__close {
    text-align: right;
    margin: 10px;
    display: none;
}

.filter-menu__menu {
    border-top: 1px solid #000;
}

.filter-menu__filter {
    font-size: 16px;
    position: relative;
    display: block;
    text-transform: uppercase;
    color: #000;
    line-height: 1.2;
    letter-spacing: .05em;
    padding: 13px;
    margin-bottom: 0;
    text-align: left;
    border-top: 1px solid $c-border;
    border-left: 4px solid transparent;

    &:first-of-type {
        border-top: none;
    }

    &:after {
        content: "";
        width: 10px;
        height: 15px;
        background: url("../../images/latest/icon-arrow-right.svg");
        background-position: right top;
        background-repeat: no-repeat;
        display: block;
        position: absolute;
        right: 10px;
        top: 16px;
    }
}

.filter-menu__filter--changed {
    border-left-color: $c-brand--alt;
}

@include breakpoint(mobile) {
    .filter-filters {
        display: none;
        position: fixed;
        z-index: z-index(modal);
        top: 63px;
        left: 30px;
        right: 0;
        padding: 10px;
        background: #fff;
        border-top: 1px solid #000;
    }
}

.filter-show-menu {
    .filter-overlay,
    .filter-menu {
        display: block;
    }

    .filter-menu__close {
        display: block;
    }
}

.filter-show-filter {
    .filter-menu__back {
        display: block;
    }

    .filter-menu__close {
        display: block;
    }

    .filter-menu__menu {
        display: none;
    }

    .filter-menu__reset {
        display: none;
    }

    .filter-filters {
        display: block;
    }

    .tabs__slide--active {
        padding-top: 0;
    }
}
