.block--instagram {
    margin-left: calc(((100vw - $site-max-width) / -2) - $site-gutter);

    @include breakpoint(large) {
        margin-left: calc($site-gutter * -1);
    }

    @include breakpoint(tablet) {
        margin-right: calc($site-mobile-gutter * -1);
        margin-left: calc($site-mobile-gutter * -1);
    }

    .block__grid {
        position: relative;
        display: grid;
        grid-template-columns: repeat(24, 1fr);

        @include breakpoint(tablet) {
            display: block;
        }
    }

        .block__content {
            grid-column: 1 / span 9;
            grid-row: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: var(--site-colour-primary);
            color: var(--site-text-colour-primary);
            padding-right: 180px;
            padding-left: $site-gutter;

            @include breakpoint(tablet) {
                padding: 75px 55px 175px 55px;
            }
        }

            .block__title {
                margin-bottom: 10px;
            }

            // We need to override the richtext styles
            .block__text.block__text {
                color: inherit;
                max-width: 260px;
                margin: 0 auto 15px;

                a {
                    color: var(--site-text-colour-primary);
                    padding-bottom: 0;
                    border: none;
                }
            }

        .block__swiper {
            grid-column: 7 / 25;
            grid-row: 1;
            margin: 0;
            padding: 60px 0;

            @include breakpoint(tablet) {
                padding: 0;
                margin-top: -120px;
                padding-left: $site-mobile-gutter;
            }
        }

            .block__swiper-wrapper {
                width: 100%;
                max-width: 100%;
                max-height: 100vh;
                // CSS Grid/Flexbox bug size workaround
                // @see https://github.com/nolimits4web/swiper/issues/3599
                min-height: 0;
                min-width: 0;
            }

        .block__nav-wrap {
            display: flex;
            margin-right: -10px;
            position: absolute;
            top: 20px;
            right: 0;

            @include breakpoint(tablet) {
                display: none;
            }
        }

            .block__nav {
                position: relative;
                z-index: 10;
            }
}
