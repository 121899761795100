// Better box sizing for everyone!
html {
	box-sizing: border-box;
}

* {
	&,
	&::before,
	&::after {
		box-sizing: inherit;
	}
}