.block--video,
.block--video.block[class*='site-colour'] {
    .block__video {
        @include aspect(16 / 9);

        position: relative;
        max-width: $max-content-width;
        margin-left: auto;
        margin-right: auto;

        iframe {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        img {
            width: 100%;
            height: auto;
        }

        &[data-playing] {
            .block__play-icon {
                display: none;
            }

            .block__pause-icon {
                display: block;
            }
        }

        &[data-hover] {
            .block__pause-icon {
                opacity: 1;
            }
        }
    }

    .block__play-icon,
    .block__pause-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;

        svg {
            fill: $c-white;
        }

        &:focus-visible {
            outline: 2px dotted $c-white;
            outline-offset: 10px;
        }
    }

    .block__pause-icon {
        display: none;
        opacity: 0;
        transition: opacity 200ms ease-in-out;
    }
}
