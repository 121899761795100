/* ------------------------------------ Radio replace ------------------------------------ */

.radio-replace.radio-replace {
    text-align: inherit;
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 5px;
    min-height: 25px;

    input[type="radio"] {
        display: none;

        &:checked + label {
            &::before {
                background-color: $c-dark;
                padding: 0;
                border-color: $c-dark;
                outline: 5px solid $c-white;
                outline-offset: -6px;
            }
        }
    }

    label {
        @include flush-last-child;
        @include large-label;

        position: relative;
        left: unset;
        top: unset;
        padding-left: 35px;
        margin-bottom: 0;
        letter-spacing: 0;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 1px solid $c-border;
            background-color: $c-white;
            background-clip: content-box;
        }
    }
}

.radio-replace--top {
    label {
        &::before,
        &::after {
            top: 0;
            transform: none;
        }
    }
}

.radios {
    @include flex(row);
    flex-wrap: wrap;
    align-items: center;
    .no-flexbox & {
        display: inline-block;
        vertical-align: middle;
        @include breakpoint(phablet) {
            display: block;
        }
    }

    .radio-replace,
    .checkbox-replace {
        margin: 0 25px 15px 0;
    }
}

.radios__input {
    margin-right: 25px;
    @include breakpoint(phablet) {
        &:last-of-type {
            margin-right: 0;
        }
    }
}

/* ------------------------------------ Checkbox replace ------------------------------------ */

.checkbox-replace {
    position: relative;
    text-align: inherit;
    display: inline-block;
    margin-right: 25px;
    padding: 7px 0;

    &:last-of-type {
        margin-right: 0;
    }

    input[type="checkbox"] {
        display: none;
    }

    label {
        @include font(14px, 20px);
        @include flush-last-child;

        display: inline-block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 0;
        letter-spacing: 0;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 25px;
            height: 25px;
            border: 1px solid $c-border;
            background-color: $c-white;
            background-clip: content-box;
            background-image: none;
            background-repeat: no-repeat;
            background-position: center center;

            .contact-us__optins &,
            .checkout-shacks__content & {
                border-color: $c-checkout-border;
            }
        }
    }

    .contact-us__optins & {
        padding: 0;
    }

    a {
        @include link;
    }
}

.checkbox-warning {
    display: none;
    color: $c-error;
}

.checkbox-replace.input--warning {
    .checkbox-warning {
        display: block;
    }

}

.checkbox-replace + .whats-that {
    margin-left: 10px;
    vertical-align: middle;
    margin-top: 8px;
}

.checkboxes {
    .checkbox-replace {
        margin-bottom: 10px;
        margin-right: 0;
    }
}

.checkboxes--vertical {
    .checkbox-replace {
        display: block;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.checkbox-replace--flush-right {
    margin-right: 0;
}

.checkbox-replace--top {
    label {
        &::after {
            top: 5px;
            transform: none;
        }
    }
}

.checkbox-replace--center {
    text-align: center;
    margin-right: 5px;

    label {
        display: inline-block;
    }
}

.checkboxes {
    .checkbox-replace {
        margin-bottom: 10px;
        margin-right: 0;
    }
}

.checkboxes--vertical {
    .checkbox-replace {
        display: block;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

// Checked checkbox
.checkbox-replace {
    input[type="checkbox"]:not(.disabled):checked + label {
        &::after {
            background-image: url("../../images/latest/checkbox-check-icon.svg");
            border-color: $c-dark;
        }
    }
}

.checkbox-replace {
    input.disabled,
    input:disabled {
       & + label {
            color: #dddddd;
            cursor: not-allowed;
            span {
                color: inherit;
            }
            &::after {
                filter: grayscale(100%);
                background-color: $c-grey;
            }
        }
    }
}

.checkbox-replace__bold {
    @include mediumText;
}
