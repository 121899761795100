@use 'sass:math';

.product-gallery {
    display: grid;

    @include breakpoint(phablet) {
        margin-left: calc($site-mobile-gutter * -1);
        margin-right: calc($site-mobile-gutter * -1);
    }

    cylindo-viewer {
        display: block;
        aspect-ratio: 7 / 5;
        --legacy-browser-aspect-ratio: 7 / 5;

        :host {
            aspect-ratio: 7 / 5;
        }

        img[slot="placeholder"] {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    .swiper-slide-active {
        z-index: 10;
    }
}

    .product-gallery__swiper {
        width: 100%;
        max-width: 100%;
        // CSS Grid/Flexbox bug size workaround
        // @see https://github.com/nolimits4web/swiper/issues/3599
        min-height: 0;
        min-width: 0;
        margin-bottom: 15px;
    }

        .product-gallery__swiper-container {}

            .product-gallery__item {
                @include aspect(math.div(7, 5));

                position: relative;
                overflow: hidden;
                cursor: pointer;

                @include breakpoint(tablet) {
                    overflow: visible;
                }

                & > img {
                    height: auto;
                    width: 100%;
                }

                & > video {
                    width: 100%;
                    height: auto;
                    margin: 0;
                    display: block;
                }

                &:has(video) > .button.button--has-swatch-rounded {
                    bottom: 80px;
                }

                .button.button--has-swatch-rounded {
                    position: absolute;
                    bottom: 52px;
                    left: 23px;
                }

                svg.global-icon-360 {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 40px;

                    @include breakpoint(tablet) {
                        bottom: 20px;
                    }

                    @include breakpoint(phablet) {
                        bottom: 60px;
                        right: $site-mobile-gutter;
                        left: initial;
                        transform: none;
                    }
                }

                svg.global-icon-zoom-in {
                    position: absolute;
                    top: 23px;
                    right: 23px;
                    fill: $c-dark;
                }
            }

            .product-gallery__image--cylindo {
                position: relative;

                svg.global-icon-360 {
                    bottom: 10px;
                }
            }

                .product-gallery__caption {
                    @include smallprint;

                    margin-top: 17px;

                    @include breakpoint(phablet) {
                        padding: 0 $site-mobile-gutter;
                    }

                    span {
                        @include mediumText;
                    }
                }

        .product-gallery__nav {
            position: absolute;
            top: calc(50% - 15px); // allow for figcap
            transform: translateY(-50%);
            z-index: 1;
            width: 38px;
            height: 38px;
            background-color: $c-grey;
            opacity: 0.8;
            cursor: pointer;
            display: grid;
            place-items: center;
            border-radius: 50%;
            transition: opacity 150ms ease-in;

            @media (hover: hover) {
                &:hover {
                    opacity: 1;
                }
            }

            &:disabled {
                display: none;
            }
        }

        .product-gallery__nav--prev {
            left: 14px;

            @include breakpoint(tablet) {
                left: $site-mobile-gutter;
            }
        }

        .product-gallery__nav--next {
            right: 14px;

            @include breakpoint(tablet) {
                right: $site-mobile-gutter;
            }
        }

    .product-gallery__thumb-swiper:not(.product-gallery__thumb-swiper.swiper-initialized) {
        .product-gallery__thumb.swiper-slide {
            @include aspect(math.div(7, 5));
            width: calc((100% + 23px) / 4 - 23px);
            margin-right: 23px;

            img {
                width: 100%;
            }

            @include breakpoint(phablet) {
                @include aspect(math.div(7, 5));
                width: calc((100% + 12px) / 3.5 - 12px);
                margin-right: 12px;
            }
        }
    }

    .product-gallery__thumb-swiper {
        overflow: hidden;
    }

    .product-gallery__thumb-swiper.swiper {
        width: 100%;
        max-width: 100%;
        max-height: 100vh;
        // CSS Grid/Flexbox bug size workaround
        // @see https://github.com/nolimits4web/swiper/issues/3599
        min-height: 0;
        min-width: 0;

        @include breakpoint(phablet) {
            padding: 0 $site-mobile-gutter;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

        .product-gallery__thumb-container.swiper-wrapper {
            padding: 1px 0;
            box-sizing: border-box;
        }

            .product-gallery__thumb.product-gallery__thumb {
                @include aspect(math.div(7, 5));

                cursor: pointer;
                border: 1px solid $c-grey-border;
                transition: border-color 150ms ease-in-out, outline 150ms ease-in-out;
                transition-property: transform, border-color, outline; //override swiper
                box-sizing: border-box;
                position: relative;

                img {
                    max-width: 100%;
                    height: auto;
                }

                &.swiper-slide-thumb-active,
                &:hover {
                    border-color: $c-dark;
                    outline: 1px solid $c-white;
                    outline-offset: -2px;
                }

                .global-icon-360 {
                    height: 30px;
                    width: 30px;
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    margin: auto;
                }

                .product-gallery__play-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    padding: 25px;
                    background-color: #1c1c1c70;
                    border-radius: 100%;
                    width: 40px;
                    height: 40px;

                    svg {
                        height: 25px;
                        width: 25px;
                        fill: #ffffffb8;
                        transform: translate(-36%, -50%);
                    }
                }
            }
