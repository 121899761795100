.windows-angled-width-1 {
  width: 320px;
  height: 320px;
}

.windows-angled-width-2 {
  width: 320px;
  height: 320px;
}

.windows-angled {
  width: 180px;
  height: 150px;
}

.windows-bay-height {
  width: 320px;
  height: 320px;
}

.windows-boxed-width {
  width: 320px;
  height: 320px;
}

.windows-boxed {
  width: 180px;
  height: 150px;
}

.windows-double {
  width: 156.2px;
  height: 180px;
}

.windows-height {
  width: 320px;
  height: 320px;
}

.windows-inside-fitting {
  width: 320px;
  height: 320px;
}

.windows-inside-height {
  width: 320px;
  height: 320px;
}

.windows-inside-width {
  width: 320px;
  height: 320px;
}

.windows-install {
  width: 221px;
  height: 121px;
}

.windows-left {
  width: 127px;
  height: 195px;
}

.windows-measure {
  width: 171px;
  height: 174px;
}

.windows-outside-fitting {
  width: 320px;
  height: 320px;
}

.windows-outside-height {
  width: 320px;
  height: 320px;
}

.windows-outside-width {
  width: 320px;
  height: 320px;
}

.windows-pole {
  width: 320px;
  height: 360px;
}

.windows-returns {
  width: 171px;
  height: 166px;
}

.windows-right {
  width: 127px;
  height: 195px;
}

.windows-single {
  width: 140px;
  height: 180px;
}

.windows-standard-inside {
  width: 180px;
  height: 150px;
}

.windows-standard-outside {
  width: 180px;
  height: 150px;
}

.windows-standard {
  width: 320px;
  height: 320px;
}

.windows-strings {
  width: 140px;
  height: 159px;
}

.windows-swatches {
  width: 359px;
  height: 113px;
}

.windows-track {
  width: 320px;
  height: 360px;
}
