.dixa {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #b05033;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 30px;
    bottom: 30px;
    cursor: pointer;
    display: none;
    z-index: 9999;

}

.dixa--active {
    display: flex;
}

.dixa--quick-add {
    transition: bottom 0.5s linear;
    z-index: 999;
}

.dixa-messenge-wrapper--quick-add {
    transition: bottom 0.5s linear !important;
    z-index: 999 !important
}