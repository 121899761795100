.block--action,
.block--action[class*="block-background"] {
    padding-inline: 10px;

    &.block[class*='site-colour'] {
        max-width: none;

        & > * {
            max-width: $max-content-width;
            margin-inline: auto;
       }
    }
}

.block--action {
    & .richtext,
    & .richtext a:not(.button),
    & .richtext--inherit {
        color: unset;
    }

    & .inline-cta__content {
        padding-inline: 5%;
        margin: auto;
        
        @include breakpoint(tablet) {
            padding-inline: 0;
        }
    }
}
