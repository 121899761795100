.vh,
.visually-hidden {
    @include visually-hidden;
}

.d3r-hidden {
    display: none;
}

.d3r-shown {
    display: block;
}

.touchevents .click-only {
    display: none;
}

.no-touchevents .touch-only {
    display: none;
}
