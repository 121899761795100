@use 'sass:math';

.block--features, .block--features-type {
    margin-top: 100px;
    margin-bottom: 100px;

    @include breakpoint(tablet) {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .block__content {
        max-width: 1200px;
        margin: 0 auto;
    }

        .block__title {
            text-align: center;
        }

    .block__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 24px;
        row-gap: 40px;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            max-width: 350px;
            justify-items: center;
            margin-left: auto;
            margin-right: auto;
            row-gap: 0;
        }

        @include breakpoint(mobile) {
            max-width: 300px;
        }
    }

    .block__grid--flex {
        display: flex;
        justify-content: center;

        @include breakpoint(tablet) {
            flex-direction: column;
        }

        // & > {
        //     flex-basis: calc((1 / 3) * 100%);
        //     flex-grow: 0;
        // }
    }

    .block__grid--single {
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: 84px;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            gap: 23px;
            max-width: $tablet-max-width;
            margin-left: auto;
            margin-right: auto;
        }

        .block__content {
            padding-right: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;


            .block__title {
                text-align: left;
                margin-bottom: 10px;
            }

            @include breakpoint(tablet) {
                padding: 0;
            }
        }

        .block__image {
            @include aspect(math.div(9, 5));

            position: relative;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
