@mixin swatch-hover {
    position: relative;

    &:hover {
        outline: 1px solid $c-dark;
        outline-offset: -1px;
    }

    &:hover::after {
        content: '';
        position: absolute;
        left: 1px;
        right: 1px;
        top: 1px;
        bottom: 1px;
        border: 1px solid $c-white;
        pointer-events: none;
    }
}
