$quantity-control-width: 60px;
$quantity-control-height: 40px;

.quantity-control {
    position: relative;
    display: flex;
    justify-content: flex-start;

    label {
        display: none;
    }

    input {
        @include rem(15px);

        flex: 0 0 $quantity-control-width;
        min-width: 0;
        height: $quantity-control-height;
        padding: 0;
        text-align: center;
        line-height: (19 / 15);
        border: 1px solid $c-dark;
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &:focus {
            outline: none;
        }
    }
}

.quantity-control--flex {

    input {
        flex: 1 1 auto;
    }
}

.quantity-control--shrink {

    input {
        flex-shrink: 1;
    }

    .quantity-control__button {
        flex-shrink: 0;
    }
}

.quantity-control--grow {

    input {
        flex-grow: 1;
    }
}

.quantity-control--fixed {

    input {
        max-width: $quantity-control-width;
    }
}

.quantity-control--center {
    justify-content: center;
}

    .quantity-control__button {
        flex: 0 0 auto;
        position: relative;
        width: 30px;
        height: $quantity-control-height;
        background: transparent;
        border: none;
        cursor: pointer;
        user-select: none;
        transition: color .3s;

        &:focus {
            outline: none;
        }

        &[disabled] {

            svg {
                opacity: 0.15;
            }
        }

        svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .quantity-control__button--up {

        svg {
            right: 0;
        }
    }

    .quantity-control__button--down {

        svg {
            left: 0;
        }
    }
