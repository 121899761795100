.footer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 65px $site-gutter 55px;
    border-bottom: 1px solid $c-grey;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        padding: 40px $site-mobile-gutter 0;
        border: none;
        max-width: 700px;
        margin: 0 auto;
    }
}

    .footer__signup {
        grid-column: span 3;
        max-width: 60%;

        @include breakpoint(tablet) {
            max-width: none;
            grid-column: span 1;
            margin-bottom: 10px;
        }
    }

        .footer__signup-title {
            @include mediumText;
            @include font(24px, 40px);

            margin-bottom: 8px;
        }

        .footer__signup-copy {
            @include body;

            margin-bottom: 20px;
            max-width: 90%;

            @include breakpoint(tablet) {
                @include font(12px, 22px);

                max-width: none;
                margin-bottom: 15px;
            }

            &--success {
                color: var(--site-colour-primary);
            }

            &--error {
                color: $c-error;
            }
        }

        .footer__signup-label {
            @include visually-hidden;
        }

        .footer__signup-field {
            position: relative;
            display: inline-block;
            width: 100%;

            input {
                width: calc(100% - 10px);
                height: 45px;
                border: 1px solid $c-border;
                border-radius: 23px;
                background-color: $c-white;
                padding-right: 35px;
                padding-left: 20px;
                padding-top: 3px;

                &:focus-visible {
                    outline: 1px solid $c-dark;
                }
            }

            &--hidden {
                display: none;
            }
        }

        .footer__signup-submit {
            position: absolute;
            height: 45px;
            width: 45px;
            border-radius: 50%;
            display: grid;
            place-items: center;
            background-color: var(--site-colour-primary);
            transition: background-color 150ms ease-in-out;
            top: 50%;
            transform: translateY(-50%);
            right: 0;

            &:hover {
                background-color: $c-dark;
            }

            svg {
                transition: fill 150ms ease-in-out;
                fill: $c-white;
            }
        }

    .footer__links {
        grid-column: span 1;
    }

        .footer__links-title {
            @include mediumText;
            @include font(18px, 20px);

            margin-bottom: 12px;

            @include breakpoint(tablet) {
                @include font(16px, 20px);

                margin: 0;
                border-bottom: 1px solid $c-grey;
            }
        }

            .footer__links-button {
                svg {
                    display: none;
                }

                @include breakpoint(tablet) {
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 13px 0px;
                    width: 100%;

                    svg.global-icon-plus {
                        display: block;
                    }

                    &[aria-expanded='true'] {
                        svg.global-icon-plus {
                            display: none;
                        }

                        svg.global-icon-minus {
                            display: block;
                        }
                    }
                }
            }

        .footer__links-list {
            @include breakpoint(tablet) {
                margin: 15px 0;
            }
        }

            .footer__links-item {
                padding: 2px 0;

                a, p {
                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
