.chosen-fabric-filters {
    @include margin(bottom, 35px);

    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
    border-bottom: 1px solid $c-grey;
    justify-content: space-between;
    align-items: flex-start;
}

.chosen-fabric-filters--swatch-modern {
    @include breakpoint(tablet) {
        padding-top: 0;
        padding-bottom: 40px;
        margin: 0;
        border-bottom: none;
    }
}

.chosen-fabric-filters--clear-top {
    border-top: none;
    padding-top: 0;
}

.chosen-fabric-filters__group {}

.chosen-fabric-filters__group--colour {
    width: calc(100% - 300px);
    margin-bottom: 23px;
    padding-right: 20px;

    @include breakpoint(tablet) {
        width: auto;
        margin-bottom: 15px;
        padding-right: 0;
    }
}

.chosen-fabric-filters__group--fabric {
    width: 300px;
    margin-bottom: 23px;

    .filterable__dimension & {
        margin: 0;
    }

    @include breakpoint(tablet) {
        width: 100%;
        margin-bottom: 15px;
    }
}

.chosen-fabric-filters__group--actions {
    margin-left: 20px;
    align-self: center;
}

.chosen-fabric-filters__reset {
    @include font(12px, 18px);

    text-decoration: underline;
    letter-spacing: 0.65px;
}

.chosen-fabric-filters__group--options {
    width: 300px;
    display: flex;
    align-items: center;

    @include breakpoint(tablet) {
        margin-left: 0;
    }
}
