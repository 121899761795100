.block--quote {
    .block__quote {
        @include mediumText;
        @include font(24px, 32px);

        max-width: $narrow-max-width;
        margin: 0 auto;
        text-align: center;

        @include breakpoint(tablet) {
            @include font(20px, 30px);

            max-width: $tablet-max-width;
        }
    }
}
