$default-callout-bg: gradient;

@mixin wavy-panel($callout-bg: $default-callout-bg) {
    position: relative;
    overflow: hidden;
    &::before {
        transition: transform 200ms ease-in-out;
        content: "";
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        @if $callout-bg == "gradient" {
            background:
            url("../../images/latest/callout-wave-top-left.svg") top -1px left -1px no-repeat,
            url("../../images/latest/callout-wave-top-right.svg") top -1px right -1px no-repeat,
            url("../../images/latest/callout-wave-bottom-right.svg") bottom -1px right -1px no-repeat,
            url("../../images/latest/callout-wave-bottom-left.svg") bottom -1px left -1px no-repeat,
            linear-gradient(to bottom, rgba(168,216,228,1) 0%,rgba(173,218,230,1) 10%,rgba(255,255,255,1) 100%);
        }
        @else if $callout-bg == "transparent" {
            background:
            url("../../images/latest/callout-wave-top-left.svg") top -1px left -1px no-repeat,
            url("../../images/latest/callout-wave-top-right.svg") top -1px right -1px no-repeat,
            url("../../images/latest/callout-wave-bottom-right.svg") bottom -1px right -1px no-repeat,
            url("../../images/latest/callout-wave-bottom-left.svg") bottom -1px left -1px no-repeat
        }
        @else if type-of($callout-bg) == color {
            background:
            url("../../images/latest/callout-wave-top-left.svg") top -1px left -1px no-repeat,
            url("../../images/latest/callout-wave-top-right.svg") top -1px right -1px no-repeat,
            url("../../images/latest/callout-wave-bottom-right.svg") bottom -1px right -1px no-repeat,
            url("../../images/latest/callout-wave-bottom-left.svg") bottom -1px left -1px no-repeat,
            $callout-bg;
        }
        @else {
            background: $c-error;
            @warn "Expected keyword gradient or color (hex) in callout-bg function";
        }
        transform: translateY(0);
    }
    & > * {
        position: relative;
        z-index: 2;
    }
}

@mixin secondary-panel {
    padding: 20px;
    border: 1px solid $c-dark;
}
