.chooser-panel {}

    .chooser-panel__header {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid $c-light-grey;

        a.link {
            cursor: pointer;
        }
    }

    .chooser-panel__header--no-border {
        padding-bottom: 0;
        border: none;
        margin-bottom: 0;
    }

    .chooser-panel__header--grid {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 10px;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;

            .chooser-panel__price {
                margin-right: unset;
            }

                .price {
                    justify-content: start;
                }
        }
    }

    .chooser-panel__image {
        height: auto;
        width: 100%;
        margin-bottom: -10px;

        @include breakpoint(tablet) {
            margin-bottom: 0;
        }
    }

    .chooser-panel__price-wrap {
        display: flex;
        align-items: center;
        margin-top: 10px;
        gap: 15px;
    }

        .chooser-panel__title {
            @include large-subtitle;

            display: flex;
            gap: 10px;

            @include breakpoint(tablet) {
                align-items: center;
                margin-bottom: 10px;
            }
        }

        .chooser-panel__price {
            @include mediumText;
            @include font(22px, 30px);

            letter-spacing: 1px;

            margin-right: 50px;

            @include breakpoint(tablet) {
                @include font(20px, 24px);
            }
        }

        .chooser-panel__meta {
            @include smallprint;

            flex-basis: 100%;
        }

        .chooser-panel__meta--red {
            color: $c-error;
        }

    .chooser-panel__confirm {
        @include breakpoint(tablet) {
            display: flex;
            gap: 23px;
            align-items: center;
            justify-content: space-between;
            padding: 12px 0;
            position: fixed;
            bottom: 0;
            left: $site-mobile-gutter;
            right: $site-mobile-gutter;
            background-color: $c-white;
            z-index: 1;
        }

        .button--link {
            display: none;

            @include breakpoint(tablet) {
                display: inline-flex;
            }
        }
    }

    .chooser-panel__swatch-button-add {
        .global-icon-minus {
            display: none;
        }

        .swatch--selected {
            .global-icon-minus {
                display: inline-block;
            }

            .global-icon-plus {
                display: none;
            }
        }
    }

    .chooser-panel__swatches {
        padding: 12px 20px 20px;
        border: 1px solid $c-light-grey;

        @include breakpoint(tablet) {
            margin-bottom: 10px;
        }
    }

        .chooser-panel__swatch-title {
            @include subtitle;

            margin-bottom: 10px;
        }

        .chooser-panel__swatch-button {
            display: flex;
            justify-content: flex-end;

            button:disabled {
                opacity: 0.3;
                background-color: unset;

                &:hover {
                    background-color: unset;
                }
            }
        }

    .chooser-panel__button {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 15px 0;
        border-bottom: 1px solid $c-grey-border;
        transition: border-color 150ms ease-in-out;
        width: 100%;
        cursor: pointer;

        &:hover {
            border-color: $c-dark;
        }

        svg {
            fill: $c-dark;
        }

        svg.global-icon-arrow-right {
            margin-left: auto;
            margin-right: 10px;
        }
    }
