/* ======================= */
/* = Richtext stylesheet = */
/* ======================= */

/* This should only apply to the wysiwyg */
body.richtext {
    font-size: 100%;
    background: $c-white;
}

.richtext {
    color: $c-body;

    h1 {
        @include large-title;
    }

    h2 {
        @include medium-title;
    }

    h3 {
        @include small-title;
    }

    h4 {
        @include large-subtitle;
    }

    h5 {
        @include subtitle;
    }

    h1, h2, h3, h4,  h5, h6 {
        &:not(:first-child) {
            margin-top: 1.5em;
        }

        margin-bottom: 0.5em;
    }

    p {
        @include font(14px, 24px);

        margin-bottom: 1em;
    }

    p, h1, h2, h3, h4, h5, h6, p, span {
        scroll-margin-top: $header-height;

        @include breakpoint(nav) {
            scroll-margin-top: $header-height-mobile;
        }
    }

    strong {
        @include mediumText;
    }

    ul,
    ol {
        padding: 0;
        margin: 0;
        margin-bottom: 1em;
    }

    ul ul,
    ol ol {
        margin-top: .5em;
    }

    ul {
        list-style: none;
        padding-left: 15px;
        position: relative;

        li:before {
            content: "";
            position: absolute;
            left: -15px;
            top: 11px;
            transform: translateY(-50%);
            width: 5px;
            height: 5px;
            background-color: $c-dark;
            border-radius: 50%;
        }
    }

    ol {
        list-style: decimal outside;
        padding-left: 20px;
    }

    ol ol {
        list-style-type: lower-alpha;
    }

    ol ol ol {
        list-style-type: lower-roman;
    }

    li {
        margin: 0 0 0 10px;
        position: relative;

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }

    ul li p {
        margin: 0;
    }

    ul li ,
    ol li {
        padding-left: 3px;
    }

    dl {
        margin: 0 2em 1em 2.5em;
    }

    dt {
        font-weight: bold;
        margin: 1em 0 0.4em 0;

    }

    dd {
        line-height: 1.3em;
        margin-bottom: 0.5em;
    }

    a:not(.button) {
        @include link;
        @include mediumText;
    }

    img {
        margin-bottom: 20px;
    }

    hr {
        border: 0;
        height: 1px;
        background-color: $c-border;
        margin: 1.875em 0;
    }

    blockquote {
        margin: 0 0 1.875em;

        p:last-child {
            margin-bottom: 0;
        }
    }
}

.richtext--flush {
    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.richtext--large {
    p {
        @include font(16px, 26px);
    }
}

.richtext--small {
    p {
        @include font(12px, 22px);
    }
}

.richtext--center {
    text-align: center;
}

.richtext table {
    border: 1px solid $c-border;
    margin: 30px auto;

    td, th {
        border: 1px solid $c-border;
        padding: 10px 20px;

        @include breakpoint(mobile) {
            padding: 7px 10px;
        }
    }

    th {
        background: $c-grey;
        font-weight: bold;
    }

    &.guide {
        td:nth-child(n+2) {
            text-align: right;
        }
    }
}

.richtext--inherit {
    color: inherit;
}
