.trade-review {
    margin: 0 auto;
    padding: 50px 115px;
    border: 2px solid #f5f4f2;
    display: flex;
    justify-content: center;

    .trade-review__content {
        max-width: 500px;
    }

    .trade-review__section {
        border-bottom: 1px solid rgb(236, 234, 230);
        padding: 25px 0;
    }

    .trade-review__section:last-child {
        border-bottom: unset;
        padding-bottom: unset;
    }

    .trade-review__order-details {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 23px;

        dd {
            @include boldText;
            text-align: right;
        }

        .trade-review__order-details--redText {
            color: $c-error;
        }

        .trade-review__order-details--tax-wrap {
            display: flex;
            justify-content: end;
            width: 100%;
            grid-column: 1 / -1;

            .trade-review__order-details--tax-label {
                @include form-label; 
                display: inline;
            }

            .trade-review__order-details--tax {
               @include form-label;
               display: inline;
            }
        }
    }

    .trade-review__form {
        @include form-label;
        @include boldText;
    }

    .buttons {
        text-align: center;
        padding-top: 25px;

        .button{
            min-width: 40%;
        }
    }

    .trade-review__order--thanks {
        @include subtitle;
        text-align: center;
        padding-top: 25px;
    }
}
    
