@use 'sass:math';

.block--focus-carousel {
    .block__content {
        text-align: center;
        padding-bottom: 20px;
        
        @include breakpoint(tablet) {
            padding: 0;
        }
    }

        .block__title {
            margin-bottom: 10px;
        }

        .block__text {
            margin-left: auto;
            margin-right: auto;
            max-width: calc((2 / 3) * 100%);

            @include breakpoint(tablet) {
                margin-bottom: 15px;   
            }
        }
}
