.carousel {
    position: relative;
}

    .carousel__nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }

    .carousel__nav--prev {
        left: 23px;
    }

    .carousel__nav--next {
        right: 23px;
    }

    .carousel__image {
        img {
            width: 100%;
            height: auto;
        }
    }
