.cards-amex {
  width: 47px;
  height: 30px;
}

.cards-apple {
  width: 47px;
  height: 30px;
}

.cards-blank {
  width: 38px;
  height: 24px;
}

.cards-google {
  width: 57px;
  height: 30px;
}

.cards-klarna {
  width: 45px;
  height: 25px;
}

.cards-maestro {
  width: 47px;
  height: 31px;
}

.cards-mastercard {
  width: 47px;
  height: 31px;
}

.cards-paypal {
  width: 20px;
  height: 23px;
}

.cards-visa {
  width: 47px;
  height: 31px;
}
