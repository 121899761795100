@mixin image-wrap($image-wrap-behaviour: contain) {

	// Addresses bug in ie11 where an image is a flex-item the height is incorrectly calculated
	// min-height of any value > 0 fixes this.
	min-height: 1px;

	// Don't fill the image but center it
	// default width is auto (max-width: 100%)
	@if $image-wrap-behaviour == contain {
		img,
		[role="img"] {
			margin-left: auto;
			margin-right: auto;
		}
	}

	// Fill that bad boy
	@else if $image-wrap-behaviour == fill {
		img,
		[role="img"] {
			width: 100%;
		}
	}

}