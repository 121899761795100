.enclosed-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px $site-gutter;

    @include breakpoint(tablet) {
        flex-direction: row-reverse;
        padding: 5px $site-mobile-gutter;
    }

    & > * {
        flex: 1;
    }

    &::after {
        content: '';
        position: absolute;
        left: $site-gutter;
        right: $site-gutter;
        bottom: 0;
        height: 1px;
        background-color: $c-light-grey;

        @include breakpoint(tablet) {
            left: $site-mobile-gutter;
            right: $site-mobile-gutter;
        }
    }
}

    .enclosed-header__secure p {
        @include subtitle;

        @include breakpoint(tablet) {
            text-align: right;
        }
    }

    .enclosed-header__logo {
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(tablet) {
            justify-content: flex-start;
            max-width: 55px;
        }
    }
