.wrap {
    margin: 0 auto;
    width: $site-width;
    max-width: $site-max-width;
    padding: 0 $site-gutter;

    @include breakpoint(tablet) {
        padding: 0 $site-mobile-gutter;
    }
}

.wrap--narrow {
    max-width: $max-content-width;
}

.wrap--v-narrow {
    max-width: calc(100% * (2 / 3));

    @include breakpoint(tablet) {
        max-width: 100%;
    }
}

.wrap--tab-width {
    @include breakpoint(tablet) {
        max-width: $tablet-max-width;
        padding: 0;
    }

    @include breakpoint(phablet) {
        padding: 0 $site-mobile-gutter;
    }
}

.wrap--flush-tablet {
    @include breakpoint(tablet) {
        padding: 0;
    }
}

.wrap--no-overflow {
    overflow: hidden;
}

.wrap--center {
    text-align: center;
}

.wrap--sticky {
    position: sticky;
    top: $header-height + $notice-height;
    z-index: 10;

    &.is-sticky {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: calc((100vw - $site-max-width) / -2);
            right: calc((100vw - $site-max-width) / -2);
            height: 1px;
            background-color: $c-grey-border;

            @include breakpoint(large) {
                left: 0;
                right: 0;
            }

            @include breakpoint(nav) {
                display: none;
            }
        }
    }

    @include breakpoint(nav) {
        border: none;
        position: relative;
        top: unset;
        z-index: unset;
    }
}
