.field {
    position: relative;
    margin-bottom: 20px;

    label {
        @include form-label;

        position: absolute;
        z-index: 1;
        top: 10px;
        left: 15px;
    }

    input,
    select,
    textarea {
        @include form-input;

        height: 55px;
        border-radius: 0;
        background-color: $c-white;
        padding: 20px 15px 0;
        border: 1px solid $c-grey-border;
        width: 100%;

        &:focus-visible,
        &:focus,
        &:active {
            outline: none;
            border: 1px solid $c-dark;
        }
    }

    textarea {
        height: 150px;
        max-width: 100%;
        padding-top: 30px;
    }

    &::placeholder {
        color: $c-body-light;
        opacity: 1;
    }
}

.field--has-icon {
    input {
        padding: calc(0.75em - 1px) 1em calc(0.75em - 4px) 1em;
    }

    svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        fill: $c-dark;
    }
}

.field--has-button {
    button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        display: flex;

        &[hidden] {
            display: none;
        }
    }

    svg {
        fill: $c-dark;
    }
}

.field--short {
    input,
    select {
        height: 48px;
    }
}

.field--flush-bottom {
    margin-bottom: 0;
}

.field--tight {
    margin-bottom: 10px;
}

.field--flush {
    margin: 0;
}

.field--checkbox,
.field.checkboxes,
.field--non-label,
.field--asset-file {
    label {
        position: relative;
        left: unset;
        top: unset;
    }
}

.field--checkbox,
.field.checkboxes {
    @include large-label;
}

.field--non-label,
.field--asset-file {
    label {
        @include font(14px, 20px);

        display: block;
        margin-bottom: 10px;
        color: $c-body;
    }

    input,
    textarea {
        padding-top: 15px
    }

    input {
        padding-bottom: 15px;
    }
}

.field-warning {
    p {
        margin-bottom: 10px;
    }
}

.field--small {
    label {
        @include form-label;

        color: $c-body;

        @include breakpoint(tablet) {
            @include body;
        }
    }
}

.field.checkboxes {
    label {
        display: block;
        margin-bottom: 15px;
    }
}

.field-inline {
    font-size: 0;
}

.field--asset-file {
    input {
        background-color: $c-white;
    }
}

.field.error,
.field-error,
.field--error,
.field-group--error {
    .input,
    .select-replace__value,
    select {
        border-color: $c-error;
    }
    div.error,
    p.error {
        color: $c-error;
        @include rem(14px);
    }
}

.field p {
    margin-top: 5px;
}

.js {
    .antispam-hide {
        display: none;
    }
    .field--antispam {
        display: none;
    }
}

select {
    @include font(14px, 14px);

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    position: relative;
    height: 55px;
    width: 100%;
    margin: 0 auto;
    color: $c-black;
    border: 1px solid $c-grey-border;
    background: $c-white;
    text-align: left;
    padding-left: 15px;
    padding-right: 35px;
    background-position: right 15px center;
    line-height: 16px;
    background-size: 14px 9px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='9' viewBox='0 0 14 9'%3E%3Cpath fill='%232e2d2c' d='M.117.46c.292-.215.924.052 1.598.677 1.207 1.124 1.031.822 2.183 2.012 1.778 1.832 1.533 1.9 3.281 3.759 1.369-1.652.873-1.53 2.384-3.119 1.665-1.751 1.967-1.772 3.595-3.5.146-.158.49-.289.64-.289.297 0 .228.324.04.713-.632 1.28-.242 1.159-1.367 2.383C10.28 5.47 10.1 5.86 7.81 8.155l-.01.008a.798.798 0 0 1-1.142-.008 85.804 85.804 0 0 1-1.61-1.706c-.361-.345-.722-.686-1.079-1.032-.9-.876-.915-.534-1.796-1.427-1.337-1.353-.147-.52-1.4-1.948C.177 1.364-.205.691.117.459Z'/%3E%3C/svg%3E");

    .filters__sort & {
        @include mediumText;
        @include smallprint;

        border: none;
        height: auto;
        padding: 0 15px 0 5px;
        background-position: right 0px center;
        overflow: hidden;
        text-overflow: ellipsis;
        appearance: none;
        max-width: 100px;

        @include breakpoint(tablet) {
            @include font(13px, 13px);

            background-color: $c-light-grey;
            top: 0;
            left: 0;
            bottom:0;
            right: 0;
            position: absolute;
            padding-left: 70px;
            padding-right: 35px;
            background-position: right 15px center;
            max-width: 100%;
        }
    }

    .filters__button--sort .filters__sort & {
        @include breakpoint(tablet) {
            @include font(16px, 18px);

            margin: 0;
            padding: 0;
            max-width: 100%;
            background-position: right 16px center;
            background-size: 14px 12px;
            letter-spacing: normal;
        }
    }
}

select.select--short {
    height: 40px;
}

.field-group {
    p.error {
        display: none;
    }
}

.field-group--error {
    p.error {
        display: block;
    }
}

.field.field--text-area {
    label {
        @include font(14px, 16px);
        color: $c-black;
        position: relative;
        top: 0;
        left: 0;
    }
}
