.intro {
    margin: 0 auto 12px;
    max-width: calc((5 / 6) * 100%);
    text-align: center;

    @include breakpoint(phablet) {
        max-width: none;
        padding: 0 20px;
    }

    .block--intro & {
        margin-bottom: 0;
    }
}

.intro--no-button {
    margin-bottom: 30px;
    
    .block--intro & {
        margin-bottom: 0;
    }
}

    .intro__title {
        @include font(30px, 40px);
        @include mediumText;
        @include margin(bottom, 15px);

        letter-spacing: 1px;

        @include breakpoint(tablet) {
            @include font(24px, 32px);
        }
    }

    .intro__text p {
        margin-bottom: 3px;
        letter-spacing: 1px;
    }
