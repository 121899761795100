@import 'lightgallery/css/lightgallery';
@import 'lightgallery/css/lg-zoom';

@font-face {
    font-family: 'lg';
    src: url("/fonts/LG/lg.eot?n1z373");
    src: url("/fonts/LG/lg.eot?#iefixn1z373") format("embedded-opentype"),
         url("/fonts/LG/lg.woff?n1z373") format("woff"),
         url("/fonts/LG/lg.ttf?n1z373") format("truetype"),
         url("/fonts/LG/lg.svg?n1z373#lg") format("svg");
    font-weight: normal;
    font-style: normal;
  }
