.quantity-stepper {
    display: flex;
    align-items: center;
    gap: 10px;
}

    .quantity-stepper__actions {
        display: flex;
        align-items: center;
        border: 1px solid $c-grey-border;
        max-width: 115px;
        flex-grow: 1;
        height: 50px;

        input {
            @include mediumText;

            text-align: center;
            height: 36px;
            width: 35px;
            -moz-appearance: textfield;
            padding: 0;
            border: none;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        button {
            cursor: pointer;
            flex-basis: 40px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            svg {
                fill: $c-dark;
                transition: fill 150ms ease-in;
                width: 14px;
            }

            &:hover {
                svg {
                    fill: var(--site-colour-primary);
                }
            }
        }
    }


.quantity-stepper--product-config {
    padding: 20px 0 20px;
    border-bottom: 1px solid $c-grey-border;
}
