.enclosed-footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 140px;
    padding: 25px 0;
    border-top: 1px solid $c-light-grey;

    @include breakpoint(tablet) {
        margin: 0;
        justify-content: center;
    }

    p {
        @include smallprint;
    }

    a {
        @include mediumText;
    }
}

    .enclosed-footer__payments {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 7px;

        @include breakpoint(tablet) {
            display: none;
        }

        li {
            display: flex;
            max-width: 38px;
        }

        svg {
            width: 100%;
        }
    }
