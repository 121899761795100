@use 'sass:math';

.focus-carousel {
    --swiper-image-ratio: 55%;
    --swiper-width: 33%;
    --swiper-spacing: 23px;
    --swiper-inactive-scale: 0.85;

    @media screen and (min-width: 2560px) {
        margin-left: calc(((2090px - $site-max-width) / -2) - $site-gutter);
        margin-right: calc(((2090px - $site-max-width) / -2) - $site-gutter);
    }

    height: calc(var(--swiper-width) * var(--swiper-image-ratio) / 100%);
    overflow: hidden;
    margin-left: calc(((100vw - $site-max-width) / -2) - $site-gutter);
    margin-right: calc(((100vw - $site-max-width) / -2) - $site-gutter);

    @include breakpoint(large) {
        margin-right: calc($site-gutter * -1);
        margin-left: calc($site-gutter * -1);
    }

    @include breakpoint(tablet) {
        margin-right: calc($site-mobile-gutter * -1);
        margin-left: calc($site-mobile-gutter * -1);
    }

    @media screen and (max-width: 2560px) {
        --swiper-width: 40%;
    }

    @media screen and (max-width: 1920px) {
        --swiper-width: 50%;
    }

    @media screen and (max-width: 999px) {
        --swiper-image-ratio: 100%;
        --swiper-width: 70%;
        --swiper-spacing: 12px;
    }

    .focus-carousel__swiper-wrapper {
        margin-block: 20px;

        @include breakpoint(phablet) {
            margin-block: 10px;
            padding-left: var(--site-gutter);
            padding-right: var(--site-gutter);
        }
    }

    .focus-carousel__nav-wrap {
        display: flex;
        justify-content: center;

        @include breakpoint(tablet) {
            display: none;
        }
    }

        .focus-carousel__nav {
            padding: 10px;
            display: grid;
            place-items: center;
            cursor: pointer;

            svg {
                fill: $c-dark;
                transition: fill 150ms ease-in-out;
            }

            &:disabled {
                opacity: 0.5;
                cursor: default;
            }

            &:hover:not(:disabled) {
                svg {
                    fill: var(--site-colour-primary);
                }
            }
        }

    .swiper-slide {
        // We need to control the swiper spacing here as the native carousel
        // spacing doesn't work well with scale
        margin: 0 calc(var(--swiper-spacing, 22px) / 2);
        width: var(--swiper-width, 50%);
        transform: scale(var(--swiper-inactive-scale));
        overflow: hidden;
        transition: all 250ms ease-in-out;

        @include breakpoint(phablet) {
            padding: 0;
        }
    }

    &.swiper-backface-hidden {
        .swiper-slide {
            transform: scale(var(--swiper-inactive-scale)) translateZ(0);
        }

        .swiper-slide.swiper-slide-active {
            transform: scale(1) translateZ(0);
        }
    }

    .swiper-slide.swiper-slide-active {
        transform: scale(1) translateZ(0);
    }

    .swiper-slide.swiper-slide-prev {
        transform-origin: right;
    }

    .swiper-slide.swiper-slide-next {
        transform-origin: left;
    }

    .focus-carousel__image,
    .focus-carousel__video {
        -webkit-backface-visibility: hidden;
        aspect-ratio: var(--swiper-image-aspect-ratio, 9 / 5);
        position: relative;
        width: 100%;
        transition: transform 0.3s ease-in-out;

        @include breakpoint(tablet) {
            aspect-ratio: 1;
        }

        &:hover {
            transform: scale(1.075);
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 200px;
            opacity: 0.5;
            background-image: linear-gradient(to top, $c-dark, rgba($c-dark, 0));
            transform: translate3d(0, 0, 0);

            @include breakpoint(tablet) {
                height: 150px;
            }
        }

        img,
        video {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
            margin: auto;
        }
    }

    .focus-carousel__action {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transform: translateZ(0);
        -webkit-transform: translate3d(-50%, 0, 0);
        bottom: 40px;
        z-index: 1;

        @include breakpoint(tablet) {
            bottom: 24px;
            left: $site-mobile-gutter;
            right: $site-mobile-gutter;
            transform: translateX(0);
            -webkit-transform: translate3d(0, 0, 0);
        }
    }

        .focus-carousel__item-title {
            @include small-title;

            text-align: center;
            color: $c-white;
            margin: 0;

            @include breakpoint(tablet) {
                width: 100%;
            }
        }
}