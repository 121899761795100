@use 'sass:math';

.block--showrooms {
    &:last-child {
        margin-bottom: calc(60px * -1);
    }

    .block__title {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

        .block__nav-wrap {
            display: flex;
            margin-right: -10px;

            @include breakpoint(tablet) {
                display: none;
            }
        }

    .block__swiper {
        @include breakpoint(tablet) {
            padding-bottom: 60px;
            margin-right: calc($site-mobile-gutter * -1);
        }
    }

        .block__card {
            &:hover {
                .block__image img,
                .block__video video {
                    transform: scale(1.05);
                }
            }
        }

            .block__image {
                @include aspect(math.div(8, 9));

                margin-bottom: 15px;
                position: relative;

                @include breakpoint(tablet) {
                    @include aspect(1);
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: transform 0.3s ease-in-out;
                }
            }

            .block__video {
                @include aspect(math.div(8, 9));

                margin-bottom: 15px;
                position: relative;

                @include breakpoint(tablet) {
                    @include aspect(1);
                }

                video {
                    pointer-events: none;
                    transition: transform 0.3s ease-in-out;
                }
            }
}
