.swatch-popup {
    margin-top: 60px;
    border-top: 1px solid $c-light-grey;

    @include breakpoint(tablet) {
        max-width: $tablet-max-width;
        margin-left: auto;
        margin-right: auto;
        border: none;
        margin-top: 0;
    }
}

    .swatch-popup__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 23px;
        row-gap: 50px;
        max-width: $max-content-width;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(tablet) {
            margin-top: 35px;
            grid-template-columns: 1fr;
            row-gap: 30px;
            max-width: none;
        }
    }

        .swatch-popup__left-column {
            padding-bottom: 40px;

            @include breakpoint(tablet) {
                padding-bottom: 0;
            }
        }

        .swatch-popup__right-column {
            @include breakpoint(tablet) {
                display: none;
            }
        }

        .swatch-popup__media {
            display: grid;
            align-items: center;
        }

            .swatch-popup__swiper,
            .swatch-popup__thumb-swiper {
                // CSS Grid/Flexbox bug size workaround
                // @see https://github.com/nolimits4web/swiper/issues/3599
                width: 100%;
                max-width: 100%;
                max-height: 100vh;
                min-height: 0;
                min-width: 0;
            }

                .swatch-popup__swiper {
                    margin-bottom: 23px;

                    @include breakpoint(phablet) {
                        margin: 0 calc($site-mobile-gutter * -1) 12px;
                        max-width: none;
                        width: calc(100% + ($site-mobile-gutter * 2));
                    }
                }

                .swatch-popup__thumb-swiper {
                    @include breakpoint(phablet) {
                        margin-right: calc($site-mobile-gutter * -1);
                        max-width: none;
                        width: calc(100% + $site-mobile-gutter);
                    }
                }

                .swatch-popup__image,
                .swatch-popup__thumb,
                .swatch-popup__column-image {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .swatch-popup__image {
                    @include aspect(5, 3);
                }

                .swatch-popup__caption {
                    @include smallprint;

                    margin-top: 17px;

                    @include breakpoint(phablet) {
                        padding: 0 $site-mobile-gutter;
                    }

                    span {
                        @include mediumText;
                    }
                }

                .swatch-popup__thumb {
                    border: 1px solid $c-grey-border;
                    box-sizing: border-box;
                    cursor: pointer;

                    &.swiper-slide-thumb-active,
                    &:hover {
                        border-color: $c-dark;
                    }
                }

                .swatch-popup__nav {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 1;
                    padding: 10px;
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                    background-color: $c-grey;
                    opacity: 0.8;
                    display: grid;
                    place-items: center;
                    border-radius: 50%;
                    transition: opacity 150ms ease-in;

                    &:disabled {
                        display: none;
                    }

                    @media (hover: hover) {
                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                .swatch-popup__nav--prev {
                    left: 14px;

                    @include breakpoint(tablet) {
                        left: $site-mobile-gutter;
                    }
                }

                .swatch-popup__nav--next {
                    right: 14px;

                    @include breakpoint(tablet) {
                        right: $site-mobile-gutter;
                    }
                }

        .swatch-popup__content {
            max-width: 80%;

            @include breakpoint(tablet) {
                max-width: 100%;
            }
        }

            .swatch-popup__title {
                @include small-title;

                padding-bottom: 15px;
            }

            .swatch-popup__title--m-show {
                display: none;

                @include breakpoint(tablet) {
                    display: block;
                    padding-bottom: 20px;
                    border-bottom: 1px solid $c-light-grey;
                }
            }

            .swatch-popup__title--m-hide {
                display: block;

                @include breakpoint(tablet) {
                    display: none;
                }
            }

            .swatch-popup__subtitle {
                @include large-subtitle;

                margin-bottom: 15px;
                padding-right: 10px;
                display: flex;
                align-items: center;
                gap: 25px;

                @include breakpoint(tablet) {
                    margin-bottom: 0;
                    padding-right: 40px;
                }
            }
            
            .swatch-popup__subtitle--m-flush {
                @include breakpoint(tablet) {
                    padding-right: 0;
                }
            }

            .swatch-popup__subtitle--m-hide {
                display: flex;

                @include breakpoint(tablet) {
                    display: none;
                }
            }

                .swatch-popup__tag {
                    @include body;
                    @include regularText;

                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: -13px;
                        top: 8px;
                        width: 4px;
                        height: 4px;
                        background-color: $c-dark;
                        border-radius: 50%;

                        @include breakpoint(tablet) {
                            top: 10px;
                        }
                    }
                }

            .swatch-popup__text {
                margin-bottom: 10px;
            }

            .swatch-popup__info {
                margin: 15px 0;

                dt {
                    @include mediumText;
                    @include font(16px, 24px);

                    margin-bottom: 5px;
                }

                dd {
                    margin-bottom: 15px;
                }
            }

            .swatch-popup__button {
                @include breakpoint(tablet) {
                    border-top: 1px solid $c-grey-border;
                    background-color: $c-white;
                    position: sticky;
                    bottom: calc(15px * -1);
                    margin-left: calc($site-mobile-gutter * -1);
                    margin-right: calc($site-mobile-gutter * -1);
                    padding: 12px;
                }
            }

            .swatch-popup__button--nab-a-swatch {
                display: none;

                @include breakpoint(tablet) {
                    margin-top: 20px;
                    display: block;
                }
            }

            .fabric-property {
                display: flex;
                align-items: center;
                gap: 10px;
            }


            .fabric-info__title {
                @include large-subtitle;

                margin-bottom: 15px;
                padding-right: 10px;
                display: flex;
                align-items: center;
                gap: 25px;
            }

            .fabric-info__desc {
                margin-bottom: 10px;
            }
