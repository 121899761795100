.band {
    margin: 60px 0;
}

.band--large {
    margin: 100px 0;

    @include breakpoint(tablet) {
        margin: 60px 0;
    }
}

.band--responsive {
    margin: 45px 0;

    @include breakpoint(tablet) {
        margin: 30px 0;
    }
}

.band--semi-skimmed {
    margin: 30px 0;
}

.band--skimmed {
    margin: 20px 0;
}

.band--m-skimmed {
    @include breakpoint(tablet) {
        margin: 30px 0;
    }
}

.band--with-divide {
    position: relative;
    padding-top: 60px;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: $site-gutter;
        right: $site-gutter;
        height: 1px;
        background-color: $c-grey-border;

        @include breakpoint(tablet) {
            left: $site-mobile-gutter;
            right: $site-mobile-gutter;
        }
    }
}

.band--skimmed-top {
    margin-top: 20px;
}

.band--skimmed-bottom {
    margin-bottom: 20px;
}

.band--flush {
    margin: 0;
}

.band--flush-top {
    margin-top: 0;
}

.band--m-flush-bottom {
    @include breakpoint(tablet) {
        margin-bottom: 0;
    }
}

.band--site-colour-secondary {
    background-color: var(--site-colour-secondary);
}

.band--padded {
    padding: 90px 0;

    @include breakpoint(tablet) {
        padding: 50px 0;
    }
}

.band--scroll-margin {
    scroll-margin-top: $header-height;

    @include breakpoint(nav) {
        scroll-margin-top: $header-height-mobile;
    }
}

.band--no-overflow {
    overflow: hidden;
}
