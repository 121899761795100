@mixin bedding-section() {
    text-align: center;

    svg {
        margin: 0 auto;
        max-width: 85%;
    }
}

.bedding-experts {
    @include bedding-section();
}

.bedding-experts__title {
    margin-bottom: 20px;
    @include rem(30px);
    @include breakpoint(tablet) {
        @include rem(26px);
    }
}
