.block--nudge {
    .block__content {
        padding: 30px 20px;
    }
    
    .block__nudge-intro {
        opacity: 1;
        transition: opacity 0.5s;

        &.block__nudge-intro--hidden {
            height: 0;
            opacity: 0;
        }
    }

    .block__nudge-thanks {
        opacity: 0;
        height: 0;
        transition: opacity 2s;

        &.block__nudge-thanks--show {
            opacity: 1;
            display: flex;
            justify-content: center;
            height: 100%;
            flex-direction: column;
        }
    }

    .block__nudge-button {
        height: 55px;
    }

    & .field input {
        color: $c-black;
    }
    
}

