@use 'sass:math';

// Note: equivilent styles for callout cards exist in
// resources/sass/components/global/_callout-card.scss
// We should consider consolidating these styles in the future.

.block--callouts {

    .block__title--site-colour-secondary-as-text {
        color: var(--site-colour-secondary);
    }

    .block__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 23px;
        row-gap: 40px;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            max-width: $tablet-max-width;
            margin-left: auto;
            margin-right: auto;
            row-gap: 35px;
        }
    }

    .block__grid--flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: $max-content-width;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(tablet) {
            max-width: $tablet-max-width;
        }

        & > * {
            flex-basis: calc(50% - 12px);

            @include breakpoint(tablet) {
                flex-basis: 100%;
            }
        }
    }

    .block__card {
        &:hover {
            .block__image img {
                transform: translateZ(0) scale(1.05);
            }
        }
    }

    .block__icon {
        margin-bottom: 10px;

        @include breakpoint(tablet) {
            margin-bottom: 5px;
        }
    }
        .block__video {
            @include aspect(1);
            overflow: hidden;

            margin-bottom: 11px;
        }

        .block__video.block__video--landscape {
            @include aspect(7 / 5);
            margin-bottom: 20px;
        }

        .block__card-image {
            position: relative;
        }

        .block__image {
            @include aspect(1);
            @supports (aspect-ratio: 1) {
                aspect-ratio: 1;
            }
            position: relative;
            overflow: hidden;

            margin-bottom: 20px;


            @include breakpoint(tablet) {
                @include aspect(math.div(5, 3));
                @supports (aspect-ratio: 5 / 3) {
                    aspect-ratio: 5 / 3;
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 300ms ease-in-out;
            }
        }

        .block__image-content {}

        .block__image-content .block__svg--desktop,
        .block__image-content .block__svg--mobile {
            & {
                position: absolute;
                z-index: 3;
            }
        }

        .block__image-content .block__svg--desktop {
            &--top-left {
                top: 20px;
                left: 20px;
            }

            &--top-right {
                top: 20px;
                right: 20px;
            }

            &--bottom-left {
                bottom: 10px;
                left: 20px;
            }

            &--bottom-right {
                bottom: 10px;
                right: 20px;
            }
        }

        .block__image-content .block__svg--mobile {
            &--top-left {
                top: 15px;
                left: 15px;
            }

            &--top-right {
                top: 15px;
                right: 15px;
            }

            &--bottom-left {
                bottom: 15px;
                left: 15px;
            }

            &--bottom-right {
                bottom: 15px;
                right: 15px;
            }
        }

        .block__image-content {
            .block__svg {

                &--desktop {
                    width: 25%;
                    @include breakpoint(tablet) {
                        width: 15%;
                    }
                }

                &--mobile {
                    width: 15%;
                    @include breakpoint(phablet) {
                        width: 20%;
                    }
                }

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .block__svg {
            &--mobile {
                display: none;
            }

            @include breakpoint(phablet) {
                &--desktop {
                    display: none;
                }

                &--mobile {
                    display: block;
                }
            }
        }

        .block__image--landscape {
            @include aspect(math.div(7, 5));
            @supports (aspect-ratio: 7 / 5) {
                aspect-ratio: 7 / 5;
            }
        }

        .block__title--large {
            margin-bottom: 30px;
            text-align: center;

            @include breakpoint(tablet) {
                margin-bottom: 20px;
            }
        }

        .block__title--small {
            margin-bottom: 10px;

            @include breakpoint(tablet) {
                margin-bottom: 5px;
            }
        }

        .block__text {
            margin-bottom: 10px;

            @include breakpoint(tablet) {
                margin-bottom: 5px;
            }
        }
}
