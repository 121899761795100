.fabric-filters {
    margin: 0;
}

.chosen-fabric-filters__group--actions {
    display: none;
}

.swatch-search {
    .chosen-fabric-filters__group--actions {
        display: block;
    }
}

.fabric-filters__title {
    @include font(13px, 25px);

    width: 100%;
    margin-bottom: 7px;
    letter-spacing: 0.65px;

    span {
        @include mediumText;

        &::after {
            content: "Show all";
            display: inline;
            text-decoration: underline;
            @include regularText;
            border-left: 1px solid $c-dark;
            margin-left: 1em;
            padding-left: 1em;
            line-height: 1;
            cursor: pointer;
        }
    }
}

.fabric-filters__content {
    display: flex;
    flex-wrap: wrap;

    .select-replace__value {
        @include font(13px, 22px);

        letter-spacing: 0.65px;
    }
}
