.slider__wrapper {
    padding: 12px;
}

.slider {
    height: 1px;
    background: $c-grey;
    position: relative;
}

.ui-widget-content {
    height: 1px;
    background: $c-dark;
}

.ui-slider-handle {
    position: absolute;
    z-index: 1;
    top: 50%;
    width: 25px;
    height: 25px;
    margin: -12px 0 0 -12px;
    outline: 0;
    padding: 0;
    border-radius: 100%;
    border: 1px solid $c-dark;
    background-color: $c-white;
    background-clip: content-box;
}

.slider__num {
    width: 55px !important;
    margin-top: 20px;
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

.slider__num--min {
    float: left;
    margin-left: -12px;
}

.slider__num--max {
    float: right;
    margin-right: -12px;
}
