.contact-frame {
    border: 1px solid $c-border;
    margin-bottom: 30px;
    box-shadow: 0px 0px 25px 0px rgba(black,0.025);
    overflow: hidden;

    @include breakpoint(mobile) {
        border: none;
        box-shadow: none;
    }
}

/* ------ Contact us form ------ */

.contact-form {
    margin: 0 auto;
    padding: 50px 115px;
    background-color: $c-light-grey;

    @include breakpoint(tablet) {
        max-width: 100%;
        padding: 0;
        background-color: $c-white;
    }
}

.contact-us__lookup-type {
    @include font(12px, 17px);
    @include mediumText;

    display: inline-block;
    text-decoration: underline;
    margin: 10px 0;
}

.contact-us__optins {
    @include font(12px, 22px);

    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 0;
    }

    label {
        @include font(12px, 22px);

        margin-bottom: 10px;
    }

    p {
        @include body;

        margin-bottom: 10px;
    }
}

.contact-form-group__actions {
    width: 100%;
    margin-top: 20px;

    @include breakpoint(phablet) {
        text-align: center;
    }
}

.contact-form__title {
    @include small-title;

    margin-bottom: 15px;
}

.contact-form__field-group {
    margin-bottom: 30px;
}

.contact-form__terms {
    @include rem(13px);
}

.contact-form__replace-item,
.contact-form__asset,
.contact-form__unwanted {
    display: none;
}


.contact-form__replace-radios {
    &:has(input#type_exchange:checked) {
        ~ .contact-form__replace-item {
            display: block;
        }
    }
}

.contact-form__return-reason {
    &:has(select > option[value="damaged"]:checked) {
        ~ .contact-form__asset {
            display: block;
        }
    }

    &:has(select > option[value="unwanted"]:checked) {
        ~ .contact-form__unwanted {
            display: block;
        }
    }
}

/* ------ Contact us details ------ */

.contact-details-wrap {
    padding: 40px 30px;

    @include breakpoint(tablet) {
        background: $c-white;
        border-top: 1px solid $c-border;
    }
}

.contact-details {
    background: $c-white;
    padding: 25px;
    border: 1px solid $c-border;
    overflow: hidden;
    margin-bottom: 30px;
    @include flush-last-child;
}

.contact-details__media {
    position: relative;
    margin-bottom: 30px;

    img {
        width: 100%;
        height: auto;
    }

    .caption__content {
        padding: 2px 20px;
    }

    .global-returns {
        max-height: 150px;
    }
}

.contact-details__title {
    @include large-subtitle;

    margin-bottom: 0;
}

.contact-details__subheading {
    @include subtitle;

    margin-bottom: 10px;
}

.contact-details__number {
    @include large-subtitle;

    margin-bottom: 10px;
}

.contact-details__international a {
    display: block;
}

.contact-details__holiday {
    @include title();
    @include rem(17px);
    margin-bottom: 10px;
}

.contact-details__opening {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 23px;
    margin-bottom: 20px;
}

.contact-details__days {
    color: $c-black;
}

.contact-details__times {
    margin-bottom: 10px;
}

.contact-details__buttons {
    display: flex;
    flex-direction: column;
    gap: 23px;
}

/* ------ Contact us callouts ------ */

.contact-callout-wrap {
    border-top: 1px solid $c-border;
    /** matching padding from grid--layout **/
    padding-top: 50px;
    @include breakpoint(tablet) {
        padding-top: 40px;
    }
    @include breakpoint(phablet) {
        padding-top: 30px;
    }
}

.contact-callout {
    text-align: left;
    @include flush-last-child;
    @include breakpoint(tablet) {
        @include flex(row);
        align-items: center;
        .no-flexbox & {
            @include remove-white-space;
        }
    }
    @include breakpoint(mobile) {
        display: block;
    }
}

.contact-callout--even {
    flex-direction: row-reverse;
    .no-flexbox & {
        direction: rtl;
    }
}

.contact-callout__media {
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    @include breakpoint(tablet) {
        @include image-wrap(fill);
        width: 40%;
        margin-bottom: 0;
    }
    @include breakpoint(mobile) {
        width: auto;
        margin-bottom: 20px;
    }
}

.contact-callout__content {
    @include flush-last-child;

    @include breakpoint(tablet) {
        text-align: left;
        flex: 1 1 0%;
        padding-left: 20px;
        @include flush-last-child;
    }
    @include breakpoint(mobile) {
        display: block;
        padding-left: 0;
        text-align: center;
    }
    a {
        @include link;
        @include mediumText;
    }
}

.contact-callout--even {
    .contact-callout__content {
        padding-left: 0;
        padding-right: 20px;
    }
}

.contact-callout__title {
    @include large-subtitle;

    margin-bottom: 10px;
}

svg.nudge {
    max-height: 200px;
}
