.block--categories {
    @include breakpoint(tablet) {
        max-width: $tablet-max-width;
        margin-left: auto;
        margin-right: auto;
    }

    .block__grid {
        display: flex;
        gap: 23px;

        @include breakpoint(tablet) {
            flex-wrap: wrap;
            justify-content: center;
        }

        & > * {
            flex: 1;

            @include breakpoint(tablet) {
                flex-basis: calc(50% - 30px);
            }
        }
    }

        .block__title {
            margin-bottom: 15px;
        }
}
