$cookie-fontcolor: $c-body;
// $cookie-altfontcolor: $c-secondary;
$cookie-fontsize: 14px;
$cookie-fontweight: normal;
$cookie-bg: #ECEAE6;
$cookie-radius: 0px;
$cookie-lineheight: 24px;
// $cookie-fg: $c-white;
// $cookie-secondary: $c-button;
// $cookie-footer-bg: $c-background;
// $cookie-padding: 10px;
// $cookie-overlay: rgba(0,0,0,0.4);
// $cookie-switch-height: 30px;
$cookie-switch-bg: #FFF;
$cookie-switch-fg: #222;
// $cookie-border: $c-border;
$cookie-btn-texttransform: uppercase;
$cookie-btn-letterspacing: 1.5px;
$cookie-btn-fontsize: .8125rem;
$cookie-btn: $c-white;
$cookie-btn-bg: $c-dark;
$cookie-btn-onhover: $c-dark;
$cookie-btn-onhover-bg: $c-dark;
$cookie-wrappadding: 27px 30px;
$cookie-wrappadding-mobile: 15px 18px;
$cookie-h3-fontsize: 32px;
$cookie-overlay-scroll: auto;

@import "cookie-centre/cookie-settings";
@import "cookie-centre/cookie-onetrust";

//buttons
#onetrust-accept-btn-handler,
#onetrust-reject-all-handler,
#onetrust-pc-btn-handler,
#accept-recommended-btn-handler,
.save-preference-btn-handler,
.ot-pc-refuse-all-handler,
#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn {
    font-family: 'Gotham Pro', Verdana, sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    font-size: 14px !important;
    letter-spacing: normal !important;
    line-height: 17px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
    height: 48px !important;
    font-weight: 500 !important;
    display: inline-block !important;
    transition: all 150ms ease-in-out !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    text-transform: lowercase !important;
    box-sizing: border-box !important;

    &::first-letter {
        text-transform: uppercase !important;
    }
}

#onetrust-accept-btn-handler,
#accept-recommended-btn-handler,
.save-preference-btn-handler,
#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn {
    background-color: $c-dark !important;

    &:hover {
        background-color: var(--site-colour-primary) !important;
        color: $c-white !important;
    }
}

#onetrust-pc-btn-handler {
    background-color: $c-grey !important;

    &:hover {
        background-color: $c-dark !important;
        color: $c-white !important;
        opacity: 1 !important;
    }
}

#ot-sdk-cookie-policy-v2 {
    width: 100% !important;
}

#ot-pc-content,
#ot-sdk-cookie-policy {
    h1, h2, h3, h4 {
        font-family: 'Gotham Pro', Verdana, sans-serif !important;
        font-weight: normal !important;
        font-style: normal !important;
        -webkit-font-smoothing: antialiased !important;
        color: $cookie-fontcolor !important;
        font-weight: 500 !important;
        text-transform: uppercase !important;
    }

    h3 {
        font-size: 32px !important;
        line-height: 40px !important;
        text-align: center !important;
        font-weight: 700 !important;


        @include breakpoint(tablet) {
            font-size: 26px !important;
            line-height: 30px !important;
        }
    }

    p {
        font-size: $cookie-fontsize !important;
        line-height: $cookie-lineheight !important;
    }
}

#cookie-policy-description {
    font-size: $cookie-fontsize !important;
}

#onetrust-consent-sdk #onetrust-pc-sdk.otPcCenter {
    .ot-always-active {
        font-weight: $cookie-fontweight !important;
    }

    #ot-pt-desc {
        color: $c-dark !important;
    }

    .ot-pc-logo {
        display: flex;
        justify-content: flex-start;
    }

    #ot-pc-title,
    #ot-category-title {
        font-family: 'Gotham Pro', Verdana, sans-serif !important;
        margin: 0 0 15px !important;
        padding: 0 !important;
        font-size: 20px !important;
        line-height: 24px !important;
        font-weight: 500 !important;
        color: $c-body !important;

        @include breakpoint(tablet) {
            font-size: 18px !important
        }
    }

    #ot-pc-title {
        margin-top: 20px !important;
    }

    .privacy-notice-link,
    .ot-link-btn {
        margin-left: 5px !important;
        font-weight: 500 !important;
        padding-bottom: 3px !important;
        color: $c-dark !important;
        border-bottom: 1px solid currentColor !important;
        transition: color 150ms ease-in !important;
        text-decoration: none !important;
        margin-right: 5px !important;
    
       &:hover {
            color: var(--site-colour-primary) !important;
        }
    }
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-txt {
    background: $c-grey !important;
}

#onetrust-banner-sdk.ot-wo-title {
    #onetrust-group-container {
        margin-top: 0 !important;
    }

    .banner-header {
        display: none !important;
    }
}

#onetrust-banner-sdk.otCenterRounded {
    border-radius: 0 !important;
    padding: 30px 40px !important;
    overflow: $cookie-overlay-scroll !important;
    @include breakpoint(tablet) {
        padding: 25px 30px !important;
    }
    #onetrust-policy {
        margin-top: 0 !important;
        line-height: 1.7 !important;
    }
    #onetrust-policy-title {
        font-family: 'Gotham Pro', Verdana, sans-serif !important;
        margin: 0 !important;
        margin-bottom: 10px !important;
        padding: 0 !important;
        font-size: 20px !important;
        line-height: 24px !important;
        font-weight: 500 !important;
        color: $c-body !important;

        @include breakpoint(tablet) {
            font-size: 18px !important
        }
    }

    #onetrust-policy-text {
        color: $c-dark !important;
        margin: 0 !important;
        padding: 0 !important;

        @include breakpoint(tablet) {
            width: 100%;
        }
    }

    #onetrust-policy-text a {
        margin-left: 5px !important;
        font-weight: 500 !important;
        padding-bottom: 3px !important;
        color: $c-dark !important;
        border-bottom: 1px solid currentColor !important;
        transition: color 150ms ease-in !important;
        text-decoration: none !important;
    
       &:hover {
            color: var(--site-colour-primary) !important;
        }
    }

    #onetrust-button-group-parent {
        margin: 20px 0 0 !important;
        padding: 0;

        .banner-actions-container {
            margin: 0 !important;
        }
    }

    #onetrust-button-group {
        @include breakpoint(tablet) {
            gap: 10px;
        }

        #onetrust-pc-btn-handler {
            @include breakpoint(tablet) {
                max-width: 100% !important;
            }
        }

        > * {
            margin: 0 10px !important;

            @include breakpoint(tablet) {
                margin: 0 !important;
                width: 100% !important;
            }

            #onetrust-accept-btn-handler,
            #onetrust-pc-btn-handler {
                @include breakpoint(tablet) {
                    width: 100% !important;
                    margin: 0 !important;
                }
            }
        }
    }

}
