.lower-footer {
    position: relative;
    grid-column: span 6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px $site-gutter 32px;

    @include breakpoint(tablet) {
        flex-direction: column;
        padding: 30px $site-mobile-gutter 25px;
        max-width: 700px;
        margin: 0 auto;
    }

    > * {
        flex: 1;
    }

    &::after {
        content: '';
        position: absolute;
        height: 8px;
        background-color: var(--site-colour-primary);
        bottom: 0;
        left: 0;
        right: 0;

        @include breakpoint(tablet) {
            left: 0;
            right: 0;
        }
    }
}

    .lower-footer__links {
        display: flex;
        gap: 25px;

        @include breakpoint(laptop) {
            gap: 15px;
        }

        @include breakpoint(tablet) {
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 5px;
            column-gap: 20px;
            padding: 25px 0 5px;
            max-width: 350px;
        }
    }

        .lower-footer__link {
            @include font(12px, 24px);

            &:hover {
                text-decoration: underline;
            }
        }

    .lower-footer__socials {
        display: flex;
        justify-content: center;
        gap: 30px;
        flex-grow: 0;

        @include breakpoint(laptop) {
            gap: 20px;
        }

        @include breakpoint(tablet) {
            gap: 30px;
            order: -1;
            border-bottom: 1px solid $c-grey;
            width: 100%;
            padding-bottom: 30px;
        }
    }

        .lower-footer__social-item {
            display: flex;
            align-items: center;

            a {
                display: flex;
            }

            svg {
                height: 20px;
                width: auto;
                transition: fill 150ms ease-in;

                &:hover {
                    fill: var(--site-colour-primary);
                }
            }
        }

    .lower-footer__trademark {
        @include font(12px, 24px);

        text-align: right;

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
