// Need a percentage width but with a max, makes sense to do it specifically (via class)
.radio-colours {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));

    .touchevents & {
        grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    }
}

.radio-colours--hidden {
    margin-top: 10px;
}

.radio-colours--fixed {
    grid-template-columns: repeat(auto-fit, 40px);

    .touchevents & {
        grid-template-columns: repeat(auto-fit, 50px);
    }
}

.radio-colours__item {
    @include aspect(1);

    font-size: 0;

    &:hover {
        img {
            border: 1px solid $c-disabled;
            outline: 3px solid $c-white;
            outline-offset: -1px;
        }
    }

    label {
        margin-bottom: 0;
    }

    input {
        display: none;

        &:checked + img {
            border: 1px solid $c-dark;
            outline: 3px solid $c-white;
            outline-offset: -4px;

            .scaled-down & {
                outline: none;
            }
        }
    }

    &.js-disabled {
        opacity: 0.3;
    }
}

.radio-colours__dropdown {
    display: grid;
    grid-template-columns: auto 120px;
    column-gap: 10px;
}

.radio-colours__text {
    @include rem(13px);
    letter-spacing: 0.65px;
    margin-bottom: 0;
    text-overflow: ellipsis;
}

.radio-colours__text--center {
    text-align: center;
}

.radio-colours__toggle {
    @include rem(12px);
    letter-spacing: 0.65px;
    line-height: 18px;
    position: absolute;
    top: 0;
    right: 1px;
    text-decoration: underline;
    margin-top: 0;
    padding-right: 20px;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transform-origin: 50% 20%;
        transition: transform 250ms ease-in-out;
        display: block;
        width: 15px;
        height: 10px;
        background: url("/images/latest/select-replace-icon.svg") center center no-repeat;
    }
}
.radio-colours__toggle--active {
    &::after {
        transform: rotate(180deg);
    }
}
