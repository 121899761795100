.pagination {
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    @include breakpoint(tablet) {
        gap: 7px;
    }
}

.pagination__count {
    margin-right: 10px;

    @include breakpoint(tablet) {
        display: none;
    }
}

.pagination__button {
    @include mediumText;

    width: 50px;
    height: 50px;
    display: grid;
    place-items: center;
    background-color: $c-light-grey;

    @include breakpoint(tablet) {
        width: 36px;
        height: 36px;
    }

    & > *,
    a {
        display: grid;
        place-items: center;
        width: 100%;
        height: 100%;
    }
}

.pagination__button--algolia {
    display: inline-flex;
    padding: 0;

    & > * {
        flex: 1 1 auto;
        padding: .75em 1em;
    }
}

.pagination__button--algolia + .pagination__button--algolia {
    margin-left: 4px;
}

.pagination__button--current {
    cursor: default;
    pointer-events: none;
    background-color: $c-dark;
    color: $c-white;
}

.pagination__button--disabled {
    opacity: 0.5;
}
