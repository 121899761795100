.upsell-chooser-button {
    display: flex;
    align-items: center;
    gap: 15px;
    border: 1px solid $c-grey-border;
    padding: 12px 15px 14px 20px;
    cursor: pointer;

    @include breakpoint(tablet) {
        padding: 10px;
    }
}

    .upsell-chooser-button__image {
        max-width: 40px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .upsell-chooser-button__content {
        margin-right: auto;
    }

        .upsell-chooser-button__subtitle {
            @include smallprint;
        }

        .upsell-chooser-button__text {
            display: flex;
        }

            .upsell-chooser-button__name {
                @include mediumText;

                margin-right: 5px;
            }
