.block--simple-cta {

    .block__cta {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 20px 10px;
        flex-wrap: wrap;
        flex-grow: 1;


        .button {
            max-width: 320px;
            margin: 0;

            @include breakpoint(tablet) {
                max-width: 350px;
                width: 100%;
            }

            @include breakpoint(mobile) {
                max-width: 80%;
            }
        }
    }
}
