.product-feature {
    width: 100%;
}

    .product-feature__media {
        width: 100%;
        margin: 25px 0 20px 0;

        img {
            width: 100%;
            height: auto;
        }

        svg {
            width: 100%;
            height: auto;
        }
    }

    .product-feature__media .cld-video-player {
        margin-bottom: 0px;
    }

    .product-feature__title {
        @include mediumText;
        @include font(18px, 24px);

        text-align: center;
        margin-bottom: 15px;

        @include breakpoint(tablet) {
            @include font(16px, 22px);

            margin-bottom: 5px;
        }
    }

    .product-feature__description {
        max-width: 80%;
        margin: 0 auto;
        text-align: center;

        @include breakpoint(tablet) {
            max-width: 100%;
        }
    }
