@import 'base/required';

@keyframes slide-in {
    0% {
        transform: translateY(120%);
        opacity: 0;
    }
}

.toast {
    display: block;
    position: fixed;
    bottom: 50px;
    right: 50px;
    background: $c-white;
    padding: 30px;
    z-index: z-index(toast);
    box-shadow: 5px 5px 5px 0px rgba($c-black, 0.10);
    transform: translateY(0%);
    animation: slide-in 0.5s;
    border: 1px solid $c-border;
    border-radius: 10px;
    box-sizing: border-box;
    max-width: 400px;
    max-height: 100vh;
    opacity: 1;

    @include breakpoint(phablet) {
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
    }
}

.toast__close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    background: url("/images/latest/modal-close.svg") center center no-repeat;
}

.toast__title {
    @include title;
    @include rem(21px);
    text-align: center;
    margin-bottom: 20px;
}

.toast__description {
    margin-bottom: 20px;
}

.toast__button {
    display: flex;
    justify-content: center;

    .button {
        margin-bottom: 0;
    }
}

.toast__image {
    margin: 20px 0 20px 0;

    svg {
        max-height: 100px;
        margin: 0 auto;
    }
}
