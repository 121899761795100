@mixin image-slideshow($parent-selector) {
    transition: opacity 500ms ease-in-out;
    will-change: opacity;
    opacity: 0;

    .no-animation & {
        transition: none;
    }

    &:first-of-type {
        opacity: 1;
    }

    .#{$parent-selector} &--current {
        opacity: 1;
        z-index: 1;
    }
}
