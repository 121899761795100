.pdp-bin {
  width: 286px;
  height: 220px;
}

.pdp-clock {
  width: 286px;
  height: 220px;
}

.pdp-delivery-large {
  width: 655px;
  height: 200px;
}

.pdp-delivery-medium {
  width: 655px;
  height: 200px;
}

.pdp-delivery-small {
  width: 655px;
  height: 200px;
}

.pdp-delivery {
  width: 277px;
  height: 160px;
}

.pdp-email {
  width: 286px;
  height: 220px;
}

.pdp-excitement {
  width: 286px;
  height: 220px;
}

.pdp-option-boa {
  width: 385px;
  height: 310.7px;
}

.pdp-option-tl {
  width: 385px;
  height: 160.4px;
}

.pdp-phone {
  width: 286px;
  height: 220px;
}

.pdp-returns {
  width: 54px;
  height: 52px;
}

.pdp-shacks {
  width: 367.4px;
  height: 167.1px;
}
