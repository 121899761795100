.cylindo-image {
    .footstools-cylindo &,
    .chosen__group & {
        position: relative;
    }
}

.cylindo-tooltip {
    display: none !important;
}

.gallery__slide {
  img.cylindo-placeholder {
    transform: translate(-50%,-50%);
    width: auto;
  }
}

.cylindo-action-button-group {
    width: 100px;
    text-align: right;

    .chosen__group &.right {
        top: 0;
        right: 0;
    }

    .cylindo-action-button-icon {
        display: inline-block !important;
        float: right;
        margin: 0 0 0 10px;
        padding: 10px;
        background-color: $c-grey;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 50% 50%;
        border: 1px solid $c-grey;
        position: relative;

        &:hover {
            border: 1px solid #000;
        }
    }

    .threesixty-button-secondary {
        display: none !important;
    }

    @include breakpoint(phablet) {
        .fullscreen-button {
            display: none !important;
        }
    }

    & > *:after {
        color: #999;
        font-size: 18px;
        position: absolute;
        top: 1px;
        right: 5px;
    }

    .cylindo-icon-fullscreen-on {
        background-image: url("/images/symbols/close.svg");
    }

    .cylindo-icon-fullscreen-off {
        background-image: url("/images/symbols/zoom-in.svg");
    }

    .cylindo-icon-zoom-on {
        background-image: url("/images/symbols/zoom-out.svg");
    }

    .cylindo-icon-zoom-off {
        background-image: url("/images/symbols/zoom-in.svg");
    }
}

.gallery__360 {
    position: absolute;
    z-index: 2;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    opacity: 0.7;

    &::after {
        display: block;
        content: "";
        width: 50px;
        height: 30px;
        background: no-repeat url(/images/symbols/360.svg);
        background-size: contain;
    }

    .gallery-thumbs & {
        top: 50%;
        bottom: auto;
        transform: translate(-50%, -50%);
    }

    .chosen__group & {
        top: 0;
        left: 0;
        bottom: auto;
        transform: none;
        z-index: 20;
    }
}

.gallery__360-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    padding: 2px;
}

.cylindo-viewer-container.full-screen::after {
    content: "";
    position: absolute;
    z-index: 2;
    top: 85%;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    display: block;
    width: 76px;
    height: 46px;
    opacity: 0.7;
    background: no-repeat url(/images/symbols/360.svg);
    background-size: contain;
}

// stop Cylindo getting the height wrong on choosers

.footstools-cylindo .cylindo-wrapper {
    max-height: 450px;
}

.chosen__image .cylindo-wrapper {
    height: 275px !important;
}
