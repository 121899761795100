.swatch-layout {
    display: grid;
    grid-template-areas:
        "recent recent basket"
        "filters filters basket"
        "swatch-grid swatch-grid basket";
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 23px;
    row-gap: 35px;

    @include breakpoint(tablet) {
        grid-template-areas:
            "recent"
            "filters"
            "swatch-grid";
        grid-template-columns: 1fr;
        row-gap: 23px;
    }
}

    .swatch-layout__recent {
        grid-area: recent;
    }

    .swatch-layout__filters {
        grid-area: filters;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 20px;
        padding-bottom: 40px;
        border-bottom: 1px solid $c-grey-border;

        @include breakpoint(tablet) {
            gap: 10px;
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    .swatch-layout__basket {
        grid-area: basket;
        padding: 0 23px;

        @include breakpoint(tablet) {
            display: none;
        }
    }
    
    .swatch-layout__clear {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    .swatch-layout__grid {
        grid-area: swatch-grid;
    }
               