.showroom-finder {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 23px;
    padding: 60px 0;
    background-color: $c-light-grey;

    @include breakpoint(tablet) {
        flex-direction: column;
        margin: 25px auto 0;
        gap: 15px;
        padding: 40px $site-mobile-gutter;
    }
}

.showroom-finder--flush-bottom {
    padding-bottom: 0;
}

.showroom-finder--m-flush-top {
    @include breakpoint(tablet) {
        margin-top: 0
    }
}

    .showroom-finder__title {
        @include font(20px, 24px);
        @include mediumText;
    }

    .showroom-finder__field {
        position: relative;

        @include breakpoint(tablet) {
            width: 100%;
            max-width: $tablet-max-width;
        }
    }

        .showroom-finder__input {
            all: unset;

            @include form-input;
            box-sizing: border-box;
            height: 50px;
            background-color: $c-white;
            border: 1px solid $c-border;
            border-radius: 25px;
            min-width: 400px;
            padding-left: 25px;
            padding-top: 2px;
            transition: border 250ms ease;

            @include breakpoint(tablet) {
                min-width: 100%;
            }

            &:focus {
                border: 1px solid $c-black;
            }

            &::placeholder {
                color: $c-disabled;
            }
        }

        .showroom-finder__button {
            position: absolute;
            right: 5px;
            padding: 10px;
            cursor: pointer;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
        }
