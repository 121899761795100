@use 'sass:math';

.callout-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;

    @include breakpoint(tablet) {
        gap: 5px;
    }

    &:hover {
        .callout-card__image img {
            transform: scale(1.05);
        }
    }
}

.callout-card__title {
    @include large-subtitle;
}

.callout-card__image {
    @include aspect(1);
    margin-bottom: 10px; // In addition to the 10px gap

    @include breakpoint(tablet) {
        @include aspect(math.div(5, 3));
        margin-bottom: 15px; // In addition to the 5px gap
    }

    img {
        width: 100%;
        height: auto;
        transition: transform 0.3s ease-in-out;
    }
}

.callout-card__text {
}

.callout-card__ctas {
}
