.swatch-basket {
    position: sticky;
    top: $header-height + $notice-height;

    @include breakpoint(tablet) {
        display: none; // Opened via overlay package on mobile
        position: relative;
        top: unset;
    }

    // hide on laptops so people can see the submit button
    .swatch-brochure {
        @media screen and (min-width: 1000px) and (max-height: 900px) {
            display: none;
        }
    }
}

    .swatch-basket__title {
        @include large-subtitle;

        margin-bottom: 10px;

        @include breakpoint(tablet) {
            @include small-title;

            padding-bottom: 15px;
            border-bottom: 1px solid $c-grey-border;
        }
    }

    .swatch-basket__list {
        margin: 15px 0 25px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include breakpoint(tablet) {
            margin-top: 25px;
        }
    }

    .swatch-basket__button {
        & + .product-details__christmas-panel {
            margin-top: 12px;
        }
        
        @include breakpoint(tablet) {
            border-top: 1px solid $c-grey-border;
            background-color: $c-white;
            position: sticky;
            bottom: calc(15px * -1);
            margin-left: calc($site-mobile-gutter * -1);
            margin-right: calc($site-mobile-gutter * -1);
            padding: 12px;

            & + .product-details__christmas-panel {
                margin: 0;
            }
        }
    }
