.order-complete-donation-form {
    display: grid;
    grid-template-columns: 3fr 2fr;
    padding: 60px;
    border: 1px solid $c-border;
    overflow: hidden;

    @include breakpoint(tablet) {
        padding: 20px;
        grid-template-columns: 1fr;
    }
}

.order-complete-donation-form__content {
    border-right: 1px solid $c-grey-border;
    padding-right: 30px;

    @include breakpoint(tablet) {
        border-right: none;
        padding-right: 0;
        margin-bottom: 30px;
    }
}

.order-complete-donation-form__banner {
    padding: 10px;

    @include breakpoint(tablet) {
        text-align: center;
    }

    img {
        max-width: 400px;
        width: 100%;
        height: auto;
    }
}

.order-complete-donation-form__title {
    @include font(20px, 24px);
    @include mediumText;

    margin-bottom: 15px;

    @include breakpoint(tablet) {
        text-align: center;
    }
}

.order-complete-donation-form__intro.order-complete-donation-form__intro {
    padding-bottom: 20px;

    @include breakpoint(tablet) {
        text-align: center;
        padding-bottom: 0;
    }

    @include breakpoint(phablet) {
        max-height: 140px;
        overflow: hidden;
    }

    p {
        @include intro;

        @include breakpoint(tablet) {
            @include font(14px, 24px);
        }
    }
}

.order-complete-donation-form__form {
    padding-left: 40px;

    @include breakpoint(tablet) {
        padding-left: 0px;
    }

    @include breakpoint(phablet) {
        padding-left: 0px;
    }

    input[type="radio"] {
        display: none;
    }
}

.order-complete-donation-form__option-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 15px;

    @include breakpoint(tablet) {
        gap: 10px;
    }

    label {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 15px;
        border: 1px solid $c-checkout-border;
        padding: 9px 28px 9px 12px;

        @include breakpoint(tablet) {
            padding-right: 10px;
        }
    }
}

.order-complete-donation-form__option-input {
    &:checked + label {
        background-color: $c-light-grey;
        border-color: $c-dark;
    }
}

.order-complete-donation-form__form-label {
    @include mediumText;
    @include font(18px, 24px);

    margin-bottom: 15px;

    @include breakpoint(tablet) {
        @include font(16px, 24px);
    }
}

.order-complete-donation-form__option-amount {
    @include font(24px, 24px);
    @include mediumText;

    min-width: 42px;

    @include breakpoint(tablet) {
        @include font(16px, 24px);

        min-width: 30px;
    }
}

.order-complete-donation-form__option-label {
    @include font(12px, 20px);
}

.order-complete-donation-form__custom-input-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    span {
        @include mediumText;
        @include font(24px, 24px);

        position: absolute;
        align-items: center;
    }

    input {
        padding-left: 34px;
        height: 60px;
        width: 100%;
    }

    /* Remove arrows from input */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    & + span {
        display: block;
        margin-bottom: 10px;
    }
}

.order-complete-donation-form__form-footer {
    & > label,
    & > span {
        @include font(12px, 20px);
    }
}

.order-complete-donation-form__custom-input-currency {
    padding: 0 12px;
}

.order-complete-donation-form__terms {
    @include font(12px, 20px);

    margin-top: 10px;
}

.order-complete-donation-form__read-more {
    @include breakpoint(phablet) {
        text-align: center;

        label {
            @include link;
        }

        label:after {
            content: "Read more +";
        }

        input:checked ~ label:after {
            content: "Read less -";
        }

        input:checked ~ .order-complete-donation-form__intro {
            max-height: none;
        }
    }

    input {
        display: none;
    }
}
