@use 'sass:math';

.block--moodboard {
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;

    .block__header {
        text-align: center;
        position: relative;
        margin-bottom: 40px;

        @include breakpoint(tablet) {
            margin-bottom: 20px;
        }
    }

        .block__svg-title {
            &--desktop {
                svg {
                    max-height: 6rem;
                }
            }

            &--mobile {
                svg {
                    max-width: 16rem;
                }
                
                display: none;
            }

            margin-bottom: 1rem;
        
            @include breakpoint(phablet) {
                &--desktop {
                    display: none;
                }
        
                &--mobile {
                    display: block;
                }

                margin-bottom: .5rem;
            }
        }

        .block__title,
        .block__text {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;

            @include breakpoint(phablet) {
                max-width: 100%;
            }
        }

        .block__text {
            margin-bottom: 20px;
        }

    .block__image {
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .block__image--row-1 {
        @include aspect(math.div(9, 5));

        grid-column: span 6;

        @include breakpoint(phablet) {
            @include aspect(math.div(7, 5));

            grid-column: span 2;
        }
    }

    .block__image--row-2 {
        @include aspect(math.div(7, 5));

        grid-column: span 2;

        @include breakpoint(phablet) {
            @include aspect(math.div(4, 3));

            grid-column: span 1;

            figure {
                &:last-child {
                    display: none;
                }
            }
        }
    }

    .block__swiper {
        margin: 23px 0;

        @include breakpoint(tablet) {
            margin-right: calc($site-mobile-gutter * -1);
            padding-bottom: 30px;
        }
    }
        .swiper-pagination {
            margin-top: 20px;
            display: block;
            position: relative;
        }

        .block__nav-wrap {
            display: flex;
            justify-content: flex-end;
            margin-right: -10px;

            @include breakpoint(tablet) {
                display: none;
            }
        }
}
