.product-config {}

    .product-config__title {
        @include medium-title;

        margin: 10px 0;
    }

    .product-config__info--overlay {
        margin-top: 15px;
    }

    .product-config__upsells {
        display: grid;
        gap: 10px;
    }

    .product-config__buttons {
        margin-bottom: 20px;
        list-style: none;
        display: flex;
        flex-direction: column;

        & > div:first-child.product-config__swatch-container,
        & > div:first-child.quantity-stepper {
            margin-top: 15px;
            padding-top: 20px;
            border-top: 1px solid $c-grey-border;
            border-bottom: 1px solid #ddddb7;
            padding-bottom: 10px;
        }

        & > div:first-child.product-config__choice {
            margin-top: 15px;
        }


        & > div:last-child.product-config__choice {
            margin-bottom: 15px;
        }

        &:has(div:last-child.product-config__choice) {
            margin-bottom: 0px;
        }
    }

        .product-config__swatch-label {
            margin-bottom: 7px;
            display: block;
        }

    .product-config__choice {
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;
        margin-top: 6px;
    }

        .product-config__label {
            margin-bottom: 5px;
        }

        .product-config__info {
            cursor: pointer;
            width: fit-content;
            display: inline;
        }

        .product-config__info-icon {
            flex-shrink: 0;
            width: 18px;
            height: 18px;
            background-color: $c-light-grey;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
            transition: background-colour 150ms ease-in;
            text-align: center;

            &:hover {
                background-color: $c-grey;
            }

            .global-icon-tooltip {
                width: 7px;
                height: 9px;
            }
        }

        .product-config__radio {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            &:not(:last-of-type) {
                margin-bottom: 10px;
            }

            input {
                appearance: none;
                position: relative;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                border: 1px solid $c-border;

               &::before {
                    content: '';
                    position: absolute;
                    height: calc(100% - 8px);
                    width: calc(100% - 8px);
                    border-radius: 50%;
                    left: 4px;
                    top: 4px;
               }

                &:checked::before {
                    background-color: $c-dark;
                }
            }
        }

    .product-config__clearance-info {
        padding: 20px;
        border: 1px solid $c-error;
        margin-top: 10px;
    }

        .product-config__clearance-title {
            @include mediumText;

            color: $c-error;
            margin-bottom: 5px;
        }

        .product-config__clearance-bump-button {
            margin-top: 10px;
        }

.product-config__overlay_content {
    display: flex;
    align-content: center;
    gap: 10px;
    padding: 15px 0;

    .product-config__overlay_image {
        max-width: 200px;
        display: flex;
        flex-direction: column;

        img {
            width: 100%;
            height: auto;
        }
    }

    .product-config__overlay_product_desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        .product-config__overlay_title {
            @include mediumText;
            @include font(20px, 24px);
            margin-bottom: 4px;
            @include breakpoint(mobile) {
                @include font(18px, 24px);
            }
        }
    }
}
