.order-complete {
    max-width: $max-content-width;
    margin: 0 auto;

    @include breakpoint(tablet) {
        max-width: $tablet-max-width;
    }
}

.order-complete__content {
    text-align: center;

    h1 {
        @include medium-title;
        @include boldText;

        text-transform: uppercase;
        margin-bottom: 20px;
    }

    p {
        @include font(20px, 24px);
        @include mediumText;
    }
}

.order-complete__details {
    text-align: center;
    background: $c-light-grey;
    margin: 50px 0;
    padding: 45px;

    @include breakpoint(tablet) {
        padding: 35px 20px;
        margin: 30px 0 10px;
    }

    p:not(:last-child) {
        margin-bottom: 10px;
    }

    a:not(.button) {
        @include link;
    }

    h2 {
        @include small-title;

        margin-bottom: 10px;
    }
}
