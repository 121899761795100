.tabbed-content {}

    .tabbed-content__list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1em;

        @include breakpoint(tablet) {
            display: none;
        }
    }
    
        .tabbed-content__button {
            @include subtitle;

            padding: 10px 15px;
            cursor: pointer;
            background-color: $c-light-grey;
            border-right: 1px solid $c-white;
            border-bottom: 1px solid transparent;

            &[aria-expanded="true"] {
                background-color: $c-white;
                border-bottom: 1px solid $c-dark;
                border-right: none;
            }
        }

    .tabbed-content__select {
        display: none;

        @include breakpoint(tablet) {
            display: block;
            margin-bottom: 30px;
        }
    }
        

