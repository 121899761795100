.product-quick-add-bar {
    position: fixed;
    bottom: 0;
    z-index: 20;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dddbd7;
    background-color: #f5f4f2;
    padding: 12px 24px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s;
    max-height: 0;

    @include breakpoint(mobile) {
        padding: 12px;
    }

    .product-quick-add-bar__button-wrap {
        display: grid;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        grid-template-columns: repeat(4, auto);

        button {
            white-space: normal;
            text-align: center;
        }

        > *:last-child {
            margin-left: 2px;
        }

        .product-quick-add-bar__delivery-text{
            @include font(12px, 14px)
        }

        .product-quick-add-bar__prices .product-prices--price {
            @include font(20px, 24px)
        }

        @include breakpoint(tablet) {
            display: block;
            grid-template-columns: unset;

            .product-quick-add-bar__delivery-text,
            .product-quick-add-bar__dot,
            .product-quick-add-bar__prices{
                display: none;
            }

            .product-quick-add-bar__delivery-text{
                max-width: 550px;
            }
            
        }
    }

    .product-quick-add-bar__item-details{
        .product-quick-add-bar__item-title{
            @include mediumText;
            @include font(20px, 24px);

            @include breakpoint(mobile) {
                @include font(18px, 24px);
            }
        }

        .product-quick-add-bar__prices--mobile {
            display: none;

            .product-prices__price {
                @include font(14px, 16px);
            }

            @include breakpoint(tablet) {
                display: block;
            }
        }

        .product-quick-add-bar__item-colour-size {
            @include font(12px, 14px);
            display: grid;
            justify-content: space-between;
            grid-template-columns: repeat(6, auto);
            align-items: center;
            gap: 12px;
            margin-top: 4px;
            max-width: fit-content;

            @include breakpoint(tablet) {
                display: none;
            }
        }
    }

    .product-quick-add-bar__dot{
        height: 4px;
        width: 4px;
        background-color: $c-body;
        border-radius: 50%;
        display: inline-block;
    }
}

.product-quick-add-bar--show {
    opacity: 1;
    max-height: 100%;
    pointer-events: all;
}