@font-face {
    font-family: 'Gotham Pro';
    src: url('/fonts/GothamPro.woff2') format('woff2'),
         url('/fonts/GothamPro.woff') format('woff');
    font-weight: 400;
    font-display: swap;
}
@font-face {
    font-family: 'Gotham Pro';
    src: url('/fonts/GothamPro-Medium.woff2') format('woff2'),
         url('/fonts/GothamPro-Medium.woff') format('woff');
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: 'Gotham Pro';
    src: url('/fonts/GothamPro-Bold.woff2') format('woff2'),
         url('/fonts/GothamPro-Bold.woff') format('woff');
    font-weight: 700;
    font-display: swap;
}
