@use "sass:map";


:root{
    --swatch-category--boutique-velvet:  url(/images/swatch-overlays/boutique-velvet.png);
    --swatch-category--clever-cotton-1:  url(/images/swatch-overlays/clever-cotton-1.png);
    --swatch-category--clever-slubby-cotton: url(/images/swatch-overlays/clever-slubby-cotton.png);
    --swatch-category--clever-slubby-linen: url(/images/swatch-overlays/clever-slubby-linen.png);
    --swatch-category--clever-softie: url(/images/swatch-overlays/clever-softie.png);
    --swatch-category--clever-vintage-linen: url(/images/swatch-overlays/clever-vintage-linen.png);
    --swatch-category--clever-wobbly-cotton: url(/images/swatch-overlays/clever-wobbly-cotton.png);
    --swatch-category--clever-wool: url(/images/swatch-overlays/clever-wool.png);
    --swatch-category--house-fabric: url(/images/swatch-overlays/house-fabric.png);
    --swatch-category--natural-cotton-linen: url(/images/swatch-overlays/natural-cotton-linen.png);
}

.swatch {
    aspect-ratio: 1;
    background-size: cover;
    background-blend-mode: multiply;
}

.swatch-category--boutique-velvet {
    background-image: url(/images/swatch-overlays/boutique-velvet.png);
}

.swatch-category--clever-cotton-1 {
    background-image: url(/images/swatch-overlays/clever-cotton-1.png);
}

.swatch-category--clever-slubby-cotton {
    background-image: url(/images/swatch-overlays/clever-slubby-cotton.png);
}

.swatch-category--clever-slubby-linen {
    background-image: url(/images/swatch-overlays/clever-slubby-linen.png);
}

.swatch-category--clever-softie {
    background-image: url(/images/swatch-overlays/clever-softie.png);
}

.swatch-category--clever-vintage-linen {
    background-image: url(/images/swatch-overlays/clever-vintage-linen.png);
}

.swatch-category--clever-wobbly-cotton {
    background-image: url(/images/swatch-overlays/clever-wobbly-cotton.png);
}

.swatch-category--clever-wool {
    background-image: url(/images/swatch-overlays/clever-wool.png);
}

.swatch-category--house-fabric {
    background-image: url(/images/swatch-overlays/house-fabric.png);
}

.swatch-category--natural-cotton-linen {
    background-image: url(/images/swatch-overlays/natural-cotton-linen.png);
}
