@use 'sass:math';

$nav-button-height: 54px;
$open-nav-gap: $header-height-mobile + $notice-height-mobile + $nav-button-height;

.navigation {
    display: flex;
    align-items: center;
    gap: 30px;

    @include breakpoint(nav) {
        position: relative;
        flex-direction: column;
        gap: 0;
        width: 100%;

        button, a {
            box-sizing: border-box;
        }
    }
}

    .navigation__section {
        @include breakpoint(nav) {
            width: 100%;
        }
    }

    .navigation__section--mobile {
        display: none;
        background-color: $c-light-grey;

        @include breakpoint(nav) {
            display: block;
        }
    }

        .navigation__button,
        .navigation__link {
            @include mediumText;
            @include font(14px, 17px);

            transition: border 150ms ease-in-out;
            color: inherit;
            cursor: pointer;
            border-bottom: 1px solid transparent;

            @include breakpoint(nav) {
                @include font(16px, 20px);

                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 16px $site-mobile-gutter;
                border-top: 1px solid $c-grey;

                &:hover {
                    border-top: 1px solid $c-grey;
                    border-bottom: 1px solid $c-dark;
                }

                .navigation__section--mobile & {
                    justify-content: flex-start;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    height: 50px;
                    gap: 3px;

                    .navigation__icon {
                        flex-basis: 40px;
                        display: grid;
                        place-items: center;

                        svg {
                            fill: $c-dark;
                            width: 100%;
                            max-height: 25px;

                            &.global-icon-location {
                                stroke: $c-dark;
                            }
                        }
                    }
                }
            }

            svg {
                display: none;

                @include breakpoint(nav) {
                    display: block;
                    margin-bottom: 3px;
                    fill: $c-dark;
                }
            }
        }

        .navigation__button--active,
        .navigation__button:hover {
            border-color: currentColor;

            @include breakpoint(nav) {
                border-top: 1px solid $c-grey;
                border-bottom: 1px solid $c-dark;
            }
        }

        .navigation__button--active {
            @include breakpoint(nav) {
                position: fixed;
                top: $header-height-mobile + $notice-height-mobile;
                left: 0;
                right: 0;
                background-color: $c-white;
                justify-content: flex-end;
                flex-direction: row-reverse;
                gap: 10px;
                border-bottom: 1px solid $c-grey;

                .header--submenu-active & {
                    border-top: 1px solid $c-grey;
                }

                .header--submenuLevel2-active & {
                    position: relative;
                }

                svg {
                    transform: rotate(180deg);
                }
            }
        }

        .navigation__groups {
            display: none;
            opacity: 0;
            transition: opacity 100ms ease-in;
        }

        .navigation__groups--activating {
            display: grid;
            transition: opacity 100ms ease-in;
            position: absolute;
            top: $header-height;
            opacity: 0;
            left: 0;
            right: 0;
            z-index: 1;
            background-color: $c-white;
            padding: 0 $site-gutter 48px;
            display: grid;
            gap: 20px   ;
            grid-template-columns: repeat(6, 1fr);
            box-shadow: 0px 30px 40px -10px rgba($c-black, 0.2);

            @include breakpoint(nav) {
                position: fixed;
                overflow: auto;
                padding: 0;
                grid-template-columns: 1fr;
                gap: 0;
                bottom: 0;
                align-content: start;
                opacity: 1;
                box-shadow: none;
                padding-bottom: 40px;

                .header--submenu-active & {
                    top: $open-nav-gap;
                    height: calc(100dvh - $open-nav-gap);
                }

                .header--submenuLevel2-active & {
                    display: grid;
                    bottom: 0;
                    top: $header-height-mobile + $notice-height-mobile;
                    height: calc(100dvh - $header-height-mobile - $notice-height-mobile);
                }
            }
        }

        .navigation__groups--active {
            opacity: 1;
        }

            .navigation__group {
                grid-column: span 1;
                margin-top: 20px;

                &:has(img) {
                    margin-top: 0;
                }

                @include breakpoint(nav) {
                    margin: 0;
                }
            }

            .navigation__group--2-col {
                grid-column: span 2;

                @include breakpoint(nav) {
                    grid-column: span 1;
                }
            }

            .navigation__callout {
                display: block;

                @include breakpoint(nav) {
                    margin-left: $site-mobile-gutter;
                    margin-right: $site-mobile-gutter;
                }
            }

                .navigation__callout-image {
                    @include aspect(math.div(5, 3));
                    @supports (aspect-ratio: 5 / 3) {
                        aspect-ratio: 5 / 3;
                    }
                    position: relative;
                    overflow: hidden;

                    margin-bottom: 10px;

                    .navigation__group--1-col & {
                        @include aspect(math.div(5, 6));
                        @supports (aspect-ratio: 5 / 6) {
                            aspect-ratio: 5 / 6;
                        }

                        @include breakpoint(nav) {
                            @include aspect(math.div(5, 3));
                            @supports (aspect-ratio: 5 / 3) {
                                aspect-ratio: 5 / 3;
                            }
                        }
                    }

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: transform 300ms ease-in-out;

                        @media(hover: hover), (pointer: fine) {
                            .navigation__callout:hover & {
                                transform: scale(1.075);
                            }
                        }
                    }

                    @include breakpoint(nav) {
                        margin-top: 20px;
                    }
                }

                .navigation__group-button {
                    @include breakpoint(nav) {
                        @include font(16px, 20px);

                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: 16px $site-mobile-gutter;
                        border-bottom: 1px solid $c-grey;
                        border-top: 1px solid transparent;
                        cursor: pointer;

                        .navigation__group--active & {
                            position: fixed;
                            top: $header-height-mobile + $notice-height-mobile;
                            left: 0;
                            background-color: $c-white;
                            flex-direction: row-reverse;
                            justify-content: flex-end;
                            padding: 16px $site-mobile-gutter;
                            gap: 10px;
                            border-top: 1px solid $c-grey;

                            svg {
                                transform: rotate(180deg);
                                margin-bottom: 3px;
                            }
                        }

                        &:hover {
                            border-top: 1px solid $c-grey;
                            border-bottom: 1px solid $c-dark;
                        }
                    }

                    svg {
                        display: none;

                        @include breakpoint(nav) {
                            display: block;
                        }
                    }
                }

                    .navigation__group-header {
                        @include mediumText;
                        @include font(16px, 20px);

                        margin-bottom: 10px;

                        @include breakpoint(nav) {
                            margin: 0;
                        }

                        svg {
                            display: none;

                            @include breakpoint(nav) {
                                display: block;
                            }
                        }
                    }

                    .navigation__group-list {
                        @include breakpoint(nav) {
                            display: none;

                            .navigation__group--active & {
                                display: block;
                                position: fixed;
                                top: $open-nav-gap;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                z-index: 1;
                                background-color: $c-white;
                                padding: 15px 0;
                                overflow: auto;
                            }
                        }

                        .navigation__group--2-col & {
                            column-count: 2;
                            column-gap: 20px;

                            @include breakpoint(nav) {
                                column-count: 1;
                                column-count: unset;
                            }
                        }
                    }

                        .navigation__sublink {
                            @include font(14px, 20px);

                            padding: 7px 0;

                            &--bold > a {
                                font-weight: 700;
                            }

                            &--underline > a {
                                border-bottom: solid 1px currentColor;
                                padding-bottom: 3px;
                            }

                            @include breakpoint(nav) {
                                @include font(16px, 17px);

                                padding: 11px $site-mobile-gutter;
                            }

                            &:hover > a {
                                border-bottom: solid 1px currentColor;
                                padding-bottom: 3px;
                            }
                        }
