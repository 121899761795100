.read-more {
    margin-bottom: 20px;
    position: relative;
}

.read-more__content {
    --preview-height: 5em;
    max-height: var(--preview-height);
    position: relative;
    overflow: hidden;
    transition: max-height 500ms;

    &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 30px;
        background: linear-gradient(to bottom, #fff0 0%, #ffff 100%);
    }

    .read-more--active & {
        max-height: 100vh;

        &::after {
            display: none;
        }
    }

    > :first-child {
        margin-top: 0
    }

    > :last-child {
        margin-bottom: 0
    }
}

.read-more__content--hidden {
    max-height: 0;
}

.read-more__button {
    @include rem(12px);
    margin-top: 10px;

    .read-more--active:not(.read-more--toggle) & {
        display: none;
    }
}

$fractions: (
     2 : 'half',
     3 : 'third',
     4 : 'quarter',
     5 : 'fifth',
     6 : 'sixth',
     7 : 'seventh',
     8 : 'eighth',
     9 : 'ninth',
    10 : 'tenth',
    11 : 'eleventh',
    12 : 'twelfth',
    20 : 'twentieth'
);

.read-more--first-row:not(.read-more--active) {
    @each $b, $breakpoint in $sizes {
        @include breakpoint($breakpoint) {
            @each $f, $fraction in $fractions {
                .#{$b}-one-#{$fraction}:nth-child(n+#{$f + 1}) {
                    display: none;
                }
            }
        }
    }
}

.block--content {
    .read-more__flex-wrap {
        display: flex;
    }

    .read-more__content {
        --preview-height: 10em;
        transition: none;
    }

    .read-more--active .read-more__content {
        max-height: 100%;
        transition: max-height 500ms ease-out;
    }
    
    .read-more__button.button {
        margin-top: 10px;
    }
}
