.product-range {
    text-align: center;
}

    .product-range__image {
        @include margin(bottom, 20px);

        img {

        }
    }

    .product-range__title {
        @include font(15px, 20px);
        @include mediumText;
        @include margin(bottom, 3px);
    }
