@mixin mattress-section() {
    text-align: center;

    svg {
        margin: 0 auto;
        max-width: 90%;
    }
}

.mattress-dreaming {
    @include mattress-section();

    .section-intro__title {
        color: #000;
    }

    svg {
        width: 430px;
        margin-bottom: 20px;
    }

    padding-bottom: 20px;
}

.mattress-trial {
    @include mattress-section();

    svg {
        width: 670px;
    }

    padding-bottom: 20px;
    margin-bottom: 20px !important;
}

.mattress-quote {
    @include mattress-section();

    svg {
        margin-top: 30px;
    }
}

.mattress-quote__quote {
    margin-bottom: 10px;
    @include rem(30px);
    @include breakpoint(tablet) {
        @include rem(26px);
    }
    &:before {
        content: "\201C";
    }
    &:after {
        content: "\201D";
    }
}

.mattress-quote__citation {
    text-transform: uppercase;
    display: block;
    font-style: normal;
    margin-bottom: 10px;
    @include rem(21px);
    &:before {
        content: "\2013";
        margin-right: 0.5em;
    }
}

.mattress-testing {
    img {
        margin-top: 30px;
    }
}

.mattress-upsell {
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    flex-direction: column;
}

.mattress-upsell__title {
    @include title();
    @include rem(17px);
    margin-bottom: 0;
}

.mattress-upsell__description {
    margin-bottom: 20px;
}

.mattress-upsell__actions {
    margin-top: auto;
}

.bedding-protectors {
    img {
        max-height: 200px;
        margin-bottom: 20px;
    }
}

.bedding-protectors__title {
    @include title();
    @include rem(17px);
    margin-bottom: 5px;
    display: inline-block;
    vertical-align: middle;
}
