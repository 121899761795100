.faq {
    border-bottom: 1px solid $c-grey-border;
    cursor: pointer;

    .global-icon-minus {
        display: none;
    }

    &[open] {
        padding-bottom: 25px;
    }

    &[open] .global-icon-plus {
        display: none;
    }

    &[open] .global-icon-minus {
        display: block;
    }
}

    .faq__title {
        @include large-subtitle;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 25px;
        list-style: none;

        [open] & {
            padding-bottom: 0;
        }

        svg {
            width: 18px;
            height: 18px;
        }

        /* Remove the disclosure triangle on webkit based browsers */
        &::-webkit-details-marker {
            display: none; 
        }
    }

    .faq__text {
        margin-top: 20px;
    }
