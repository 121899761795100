/*
* Simple flexbox mixin - doesn't do much but makes life easier
* 1. Default (modern spec) is row so we'll assume the same
* 2. Fallback to block if no flexbox support
*/

$default-flex-direction: row !default; /* 1 */

@mixin flex($flex-direction: $default-flex-direction) {
    display: flex;
    flex-direction: $flex-direction;
    .no-flexbox & { /* 2 */
    	display: block;
    }
}