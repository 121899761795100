.block-item { 
    .block--categories & {
        &:hover {
            .block-item__image img {
                transform: scale(1.075);
            }
        }

        .block-item__image {
            margin-bottom: 10px;
            overflow: hidden;
            aspect-ratio: 3 / 2;

            @include breakpoint(tablet) {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                transition: transform 300ms ease-in-out;
            }
        }

        .block-item__heading {
            @include button-copy;
            
            text-align: center;
        }
    }

    .block--carousel & {
        &:hover {
            .block-item__image img {
                transform: scale(1.075);
            }
        }

        .block-item__image {
            overflow: hidden;

            img {
                transition: transform 300ms ease-in-out;
                width: 100%;
                height: auto;
            }
        }

        .block-item__video {
            overflow: hidden;
            aspect-ratio: 4 / 3;

            @include breakpoint(tablet) {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
            }
        }

        .block-item__content {
            display: block;
            padding-top: 20px;
            height: 100%;
        }

        .block-item__heading {
            @include large-subtitle;

            margin-bottom: 10px;
        }
    }
}

.block-item--center {
    text-align: center;
}
