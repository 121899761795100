.simplemodal-overlay {
    background: #000000;
    z-index: z-index(modal-overlay) !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.3;
}

.simplemodal-container {
    background: #ffffff;
    z-index: z-index(modal) !important;
    padding: 30px;
    border: 1px solid $c-grey;
    box-sizing: content-box;
    font-size: 11px;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}

.modalCloseImg {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: z-index(modal-close);
    background: url("../../images/latest/modal-close.svg") center center no-repeat;
}

// container is content-box - our content needs to be border-box
.simplemodal-data {
    box-sizing: border-box;
    max-width: 750px;
    @include flush-last-child;
    @include rem(15px);

    & > * {
        width: 100%;
    }
}

.simplemodal-wrap {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

#simplemodal-container.no-scrolls {
    overflow: hidden !important;

    .simplemodal-wrap {
        overflow: hidden !important;
    }

    .simplemodal-data {
        overflow: hidden !important;
    }
}
