.tags {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin: 0 0 20px 0;
    place-items: center;

    .tag {
        width: 75px;
        text-align: center;

        svg {
            width: 100%;
        }
    }

    .tag--gift {
        width: 125px;
    }
}
