.tippy-popper {
    transition: opacity .3s !important;

    &[x-placement^=top] [data-animation=fade] {

        &[data-state=visible] {
            opacity:1;
        }

        &[data-state=hidden] {
            opacity:0;
        }
    }

    &[x-placement^=bottom] [data-animation=fade] {

        &[data-state=visible] {
            opacity:1;
        }

        &[data-state=hidden] {
            opacity:0;
        }
    }
}

    .tippy-tooltip {
        max-width: 290px;
        cursor: pointer;

        @include breakpoint(extra-tiny) {
            max-width: 250px;
        }

        .tippy-popper[x-placement="bottom"] & {
            padding-top: 10px;
        }

        .tippy-popper[x-placement="top"] & {
            padding-bottom: 10px;
        }
    }

    .tippy-content {
        @include font(12px, 18px);

        padding: 15px 20px;
        color: $c-dark;
        background: $c-grey;
        box-shadow: 0 5px 15px 0 rgba($c-dark, 0.15);

        p {
            @include margin(bottom, 5px);
        }

        a {
            color: inherit;
            text-decoration: underline;
        }
    }

    .tippy-arrow {
        position: absolute;
        left: 50%;
        border: 10px solid transparent;

        .tippy-popper[x-placement="bottom"] & {
            top: 0;
            transform: translate(0, -50%);
            border-bottom-color: $c-grey;
        }

        .tippy-popper[x-placement="top"] & {
            bottom: 0;
            transform: translate(0, 50%);
            border-top-color: $c-grey
        }
    }
