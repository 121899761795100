.new-fabric-group {
    @include margin(bottom, 40px);
}

.new-fabric-group--swatch-modern {
    @include margin(bottom, 25px);
}

    .new-fabric-group__header {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-right: 150px;
        margin-bottom: 15px;
        z-index: 1;

        @include breakpoint(tablet) {
            margin-right: 0;
        }

        .fabric-chosen & {
            margin-right: 0;
            margin-bottom: 8px;
        }
    }

        .new-fabric-group__title,
        .new-fabric-group__price,
        .new-fabric-group__benefit {
            @include font(20px, 24px);
            @include mediumText;

            margin-bottom: 0;
            letter-spacing: 0.5px;
        }

        .new-fabric-group__about,
        .new-fabric-group__price,
        .new-fabric-group__benefit {
            @include margin(right, 15px);
        }

        .new-fabric-group__title {
            @include margin(right, 10px);

            .fabric-chosen & {
                @include font(12px, 21px);

                text-transform: uppercase;
                letter-spacing: 1.5px;
            }
        }

        .new-fabric-group__benefit {
            @include font(15px, 25px);

            letter-spacing: 0.75px;
            color: $c-border;

            .new-fabric-group__price + & {
                display: inline-flex;
                align-items: center;

                &:before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    margin-right: 15px;
                    background: currentColor;
                    border-radius: 100%;

                }

                @include breakpoint(phablet) {
                    width: 100%;

                    &:before {
                        display: none;
                    }
                }
            }
        }

        .new-fabric-group__about {
            align-self: center;

            svg {
                display: block;
                fill: $c-note;
            }
        }

        .new-fabric-group__text {
            @include font(13px, 16px);

            width: 100%;
            margin-top: 15px;
            margin-bottom: 0;
            letter-spacing: 0.65px;
        }

        .new-fabric-group__swatches {}
