.product-prices {
    display: flex;
    gap: 5px;
    align-items: baseline;
}

.product-prices__price {
    @include mediumText;
    @include font(26px, 30px);

    .product-prices__price--was~& {
        color: $c-error;
    }

    @include breakpoint(tablet) {
        @include font(22px, 30px);

        letter-spacing: 1px;
    }
}

.product-prices__price--was {
    @include font(18px, 18px);

    text-decoration: line-through;

    @include breakpoint(tablet) {
        @include font(16px, 16px);
    }
}
