.swatch-replace {}

    .swatch-replace__content {
        padding: 40px;

        @include breakpoint(tablet) {
            padding: 0;
        }
    }

        .swatch-replace__title {
            @include large-subtitle;

            margin-bottom: 15px;
            text-align: center;

            @include breakpoint(tablet) {
                @include small-title;
    
                padding-bottom: 15px;
                border-bottom: 1px solid $c-grey-border;
                text-align: left;
            }
        }

        .swatch-replace__swatch {
            display: flex;
            justify-content: center;
            border-bottom: 1px solid $c-grey-border;
            padding-bottom: 30px;
            margin-bottom: 30px;

            @include breakpoint(tablet) {
                justify-content: flex-start;
                padding-bottom: 15px;
                margin-bottom: 15px;
            }
        }

        .swatch-replace__text {
            text-align: center;

            @include breakpoint(tablet) {
                text-align: left;
            }
        }

        .swatch-replace__list {
            max-width: $tablet-max-width;
            margin-left: auto;
            margin-right: auto;
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 20px;
            column-gap: 60px;
            margin-top: 30px;

            @include breakpoint(tablet) {
              grid-template-columns: 1fr;
              max-width: 100%;
            }
        }
