.swatch-item {
    display: flex;
    align-items: center;
    gap: 20px;

    .button {
        margin-left: auto;
        margin-bottom: 13px;
    }
}

    .swatch-item__image {
        @include swatch-hover;

        height: 56px;
        width: 56px;
        flex-shrink: 0;

        img {
            width: 100%;
            height: auto;
        }

        .swatch-item--large & {
            width: 75px;
            height: 75px;
        }
    }

    .swatch-item__title {
        @include mediumText;
    }

    .swatch-item__long-title {
        @include font(12px, 20px);
    }

    .swatch-item__square {
        @include aspect(1);
        @include mediumText;

        width: 56px;
        border: 1px solid $c-grey-border;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swatch-item__text {
        .swatch-item--large & {
            @include subtitle;
        }
    }
