.payment-types {
    display: flex;
    align-items: center;
    gap: 10px;
}

    .payment-types__card {
        display: flex;
        width: 51px;
        justify-content: center;
    }
