.recent-swatches {
    padding-bottom: 30px;
    border-bottom: 1px solid $c-grey-border;

    @include breakpoint(tablet) {
        padding-bottom: 0;
    }
}

.recent-swatches--empty {
    padding-bottom: 0;
}

    .recent-swatches__title {
        @include large-subtitle;

        margin-bottom: 15px;
    }

    .recent-swatches__empty-message {
        display: none;

        .recent-swatches--empty & {
            display: block;
        }
    }
