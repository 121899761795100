.overlay--donation-confirmation {
    .overlay__container {
        max-width: 600px;
    }

    .overlay__content {
        padding: 80px 30px 30px;
        @include breakpoint(mobile) {
            max-height: 100%;
        }
    }
}

.donation-confirmation__title {
    @include small-title;

    text-align: center;

    @include breakpoint(tablet) {
        padding: 0 40px;
    }

    & ~ .richtext {
        margin-top: 30px;
    }
}

.donation-confirmation__button {
    text-align: center;
    margin-top: 20px;
}

.donation-confirmation__form {
    text-align: center;
    margin-bottom: 20px;
}
