.block--content,
.block--content.block[class*='site-colour'] {
    .block__content {
        max-width: calc(100% * (2 / 3));
        margin: 0 auto;
        text-align: center;

        @include breakpoint(tablet) {
            max-width: $tablet-max-width;
        }

        & > .block__text:last-child {
            & > *:last-child {
                margin-bottom: unset;
            }
        }
    }

    .block__content--left {
        text-align: left;
    }

        .block__title,
        .block__svg-title {
            margin-bottom: 10px;

            @include breakpoint(tablet) {
                margin-bottom: 5px;
            }
        }

    .block__text {
        ol {
            text-align: left;
        }
    }

    .button {
        margin: 0;
    }

    .block__svg-title {
        &--desktop {
            svg {
                max-height: 6rem;
            }
        }

        &--mobile {
            svg {
                max-width: 16rem;
            }
            
            display: none;
        }

        margin-bottom: 1rem;
    
        @include breakpoint(phablet) {
            &--desktop {
                display: none;
            }
    
            &--mobile {
                display: block;
            }

            margin-bottom: .5rem;
        }
    }
}
