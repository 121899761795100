.window-dimensions {
    margin-bottom: 30px;
}

.window-diagrams {
}

.window-diagram {
    display: none;

    &.window-diagram--active {
        display: block;
    }
}

.window-mounting {
    margin-bottom: 30px;
}

.window-dimensions__title {
    @include title();
    @include rem(30px);
    margin-bottom: 10px;
}

.window-dimensions__desc {
    p {
        font-size: 17px;
        margin-bottom: 30px;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }
}

.window-diagram__title {
    @include section-title();
}

.window-diagram__media {
    margin-bottom: 30px;
}

.window-diagram__desc {
}

.window-help {
    padding: 30px 0;

    & > * {
        margin: 0 auto;
        max-width: 750px;
    }
}

.window-delivery {
    @include flush-last-child;
}

.window-confirm {
    text-align: right;
}

@include breakpoint(phablet) {
    tfoot td,
    .window-delivery,
    .window-confirm {
        text-align: center;
    }
}

#js-errors .error {
    text-align: center;
}

.window-total {
    text-align: right;
    padding-bottom: 0;
    margin-bottom: 0;
}

@include breakpoint(tablet) {
    .window-total {
        text-align: center;
    }
}

.chosen__price-label {
    @include title;
    @include rem(26px);
    margin-bottom: 2px;
    display: none;
    text-transform: initial;
    white-space: nowrap;
}

.chosen__stock-info--no-wrap {
    white-space: nowrap;
}

.windows-list {
    margin-bottom: 10px;

    th {
        text-align: center;
        padding: 0 10px 10px 0;
        white-space: nowrap;
        letter-spacing: 0;
    }

    td {
        padding: 0 10px 10px 0;
        display: table-cell;
        width: 16%;
        width: calc((90% - 50px) / 5);
        vertical-align: top;
        letter-spacing: 0;

        &:first-of-type {
            width: 50px;
            text-align: right;
        }
        &:nth-of-type(2) {
            min-width: 170px;
        }
        &:last-of-type {
            width: 10%;
        }
    }

    label {
        display: none;
    }

    input[type="number"] {
        text-align: right;
    }

    input:focus, .select-replace--focused {
        box-shadow: 0 0 8px $c-brand--alt;
        outline: none;
    }

    button {
        width: 100%;
    }

    .window-info {
    }

    .window-price {
        text-align: right;
    }

    .error {
        display: none;
    }

    @include breakpoint(tablet) {
        & {
            width: 100%;
        }

        th {
            display: none;
        }

        td {
            display: block;
            overflow: visible;
            width: 100% !important;
        }

        label {
            display: block;
            float: left;
            width: 25%;
        }

        input, .select-replace {
            float: left;
            width: 75% !important;
        }

        .window-confirm input {
            float: none;
            width: auto !important;
        }

        .window-price {
            border-bottom: 1px solid $c-border;
            margin-bottom: 20px;
        }

        .window-info {
            display: none;
        }
    }

    @include breakpoint(mobile) {
        label, input, .select-replace {
            float:none;
            width: 100% !important;
        }

        td {
            margin-bottom: 20px;
        }
    }
}
