.block--faq {
    .block__content {
        max-width: 1200px;
        margin-inline: auto;
        padding: 0 20px;

        @include breakpoint(tablet) {
            max-width: 100%;

        }
    }

        .block__title {
            margin-bottom: 10px;
            text-align: center;
        }

        .block__text {
            text-align: center;
        }

        * + .faq-wrap {
            margin-top: 30px;
        }

        .faq-wrap {
            display: flex;
            flex-direction: column;
            margin-inline: auto;
            max-width: $narrow-max-width;
            gap: 25px;
        }
}

.block--faq[class*="block-accent"] {
    .block__content {
        padding-block: 80px;

        @include breakpoint(tablet) {
            padding-block: 60px;
        }
    }
}

.faq__text.richtext {
    color: unset;
}
