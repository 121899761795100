.curtains-install {
  width: 221px;
  height: 121px;
}

.curtains-measure {
  width: 171px;
  height: 174px;
}

.curtains-returns {
  width: 171px;
  height: 166px;
}

.curtains-swatches {
  width: 359px;
  height: 113px;
}
