.js-hidden {
    display: none !important;
    margin: 0 !important;
}

.js-offscreen {
     position: absolute;
     top: 0;
     left: -50px;
     width: 0;
     height: 0;
     overflow: hidden;
}

// JS Disabled
button.js-disabled > span,
input[type="button"].js-disabled,
input[type="submit"].js-disabled,
.js-disabled .select-replace,
a.js-disabled {
    background-color: lighten(#D0D0D0, 10%) !important;
    border: none;
    color: darken(#D0D0D0, 10%) !important;
    cursor: default;
}


.js-invisible {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    pointer-events: none;
}

.js-image_hover {
    display: none;
}

.js-busy {
    opacity: 0.4;
    cursor: 'wait';
}

.js .js-collapsed {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 250ms ease-in-out, opacity 250ms ease-in-out;
    .no-transitions & {
        transition: none;
    }
}

[data-collapsed="Y"] {
    padding: 0 !important;
    margin: 0 !important;
}

.js-expanded {
    // overflow: hidden;
    transition: max-height 250ms ease-in-out, opacity 250ms ease-in-out;
    .no-transitions & {
        transition: none;
    }
}

.js .hide-from-js {
    display: none;
}

.show-with-js {
    display: none;

    .js & {
        display: block;
    }
}

.js-image_load {
    background-image: url("/images/latest/loading.gif");
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: center center;
}

.js-no_transitions * {
    // temporarily disable
    transition: none !important;
}

@keyframes highlight {
      0% {background-color: $c-brand--alt;}
    100% {background-color: transparent;}
}

.js-highlight {
    animation: highlight 1000ms 1;
}

.js-loading {
    min-height: 40px;
    background-image: url("/images/latest/loading.gif");
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position: center center;
}
