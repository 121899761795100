.fabric-benefits {
    padding: 25px 0;
    text-align: center;
    background: $c-grey;

    @include breakpoint(tablet) {
        margin: 0 -10px;
        padding-right: 10px;
        padding-left: 10px;
    }
}

    .fabric-benefits__title {
        @include font(20px, 24px);
        @include mediumText;

        margin-bottom: 15px;
        letter-spacing: 0.5px;
    }

    .fabric-benefits__list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @include breakpoint(tablet) {
            max-width: 600px;
            margin: 0 auto;
        }
    }

        .fabric-benefits__item {
            @include font(15px, 21px);

            display: flex;
            align-items: flex-start;
            padding: 0 15px;
            letter-spacing: 0.75px;
            text-align: left;
            padding-left: 25px;

            &::before {
                content: "";
                background: 0 40% url("/images/symbols/tick.svg") no-repeat;
                width: 25px;
                height: 25px;
            }

            @include breakpoint(tablet) {
                @include font(13px, 18px);

                width: 50%;
                margin-bottom: 5px;
                padding: 0 5px;
                letter-spacing: 0.65px;

                &::before {
                    background-position: 0 20%;
                }
            }

            @include breakpoint(mobile) {
                @include font(11px, 18px);

                &::before {
                    background-position: 0 10%;
                }
            }

            &:nth-child(2n-1) {
                @include breakpoint(tablet) {
                    padding-left: 0;
                }
            }

            &:nth-child(2n) {
                @include breakpoint(tablet) {
                    padding-right: 0;
                }
            }

            svg {
                flex: 0 0 auto;
                margin-top: 10px;
                margin-right: 10px;
                transform: translateY(-50%);

                @include breakpoint(tablet) {
                    margin-top: 9px;
                }
            }
        }
