.product-info {
    display: flex;
    flex-direction: column;
    list-style: none;
}

    .product-info__button {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 15px 0;
        border-bottom: 1px solid $c-grey-border;
        width: 100%;
        cursor: pointer;
        transition: border-color 150ms ease-in;

        svg {
            fill: currentColor;
            pointer-events: none;
            padding-left: 15px;
        }

        svg.global-icon-arrow-right {
            margin-left: auto;
            margin-right: 15px;
        }

        &:hover {
            border-color: $c-dark;
        }
    }

    .product-info__button--xmas{
        background-color:$c-xmas-purple;
        color: $c-white;
        margin-top: 14px;

        svg{
            fill: $c-white;
        }

        &:hover{
            background-color: #802740;
            transition: all 150ms ease-in;
        }

        .product-info__button--xmas-content{
            pointer-events: none;
            opacity: 1;
            transition: opacity 0.5s linear;

            &.product-info__button--xmas-content--hide{
                opacity: 0;
            }
        }
    }

    .product-info__location-form {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 25px;
        margin-bottom: 15px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        @include breakpoint(phablet) {
            flex-direction: column;
        }

        .checkbox-replace {
            flex-basis: 0;
            flex-grow: 1;
        }
    }

        .product-info__search-location {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            width: 100%;

            @include breakpoint(phablet) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .product-info__error {
            @include mediumText;

            color: $c-error;
            flex-basis: 100%;
        }

        .product-info__search {
            position: relative;
            display: inline-block;

            input {
                @include form-input;

                width: 320px;
                height: 40px;
                border: 1px solid $c-grey-border;
                border-radius: 25px;
                background-color: $c-white;
                padding-right: 35px;
                padding-left: 25px;

                @include breakpoint(phablet) {
                    width: 100%;
                }

                &::placeholder {
                    color: $c-body-light;
                }

                &:focus-visible {
                    outline: 1px solid $c-dark;

                    .product-info__location-form--error & {
                        outline: 1px solid $c-error;
                    }
                }
            }

            button {
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                cursor: pointer;

                svg {
                    fill: $c-dark;
                }
            }

            p {
                position: absolute;
                bottom: -10px;
            }
        }

    .product-info__location {
        display: flex;
        align-items: center;
        gap: 8px;

        svg {
            fill: $c-dark;
        }
    }

    .product-info__title {
        @include small-title;

        padding-bottom: 24px;
        margin-bottom: 25px;
        border-bottom: 1px solid $c-grey-border;

        @include breakpoint(phablet) {
            padding-right: 40px;
            margin-bottom: 20px;
        }
    }

    .product-info__subtitle {
        @include large-subtitle;

        margin-bottom: 10px;
    }

    .product-info__steps {
        @include mediumText;
        @include font(18px, 20px);

        list-style: decimal;
        list-style-position: inside;
        margin-top: 25px;
    }

        .product-info__step {
            margin-bottom: 25px;
        }

            .product-info__step-title {
                margin-bottom: 15px;
                display: inline-block;
            }

            .product-info__step-copy {
                @include body;
                @include regularText;
            }

    .product-info__showrooms {
        transition: opacity 1s;
        margin: 25px 0;

        @include breakpoint(tablet) {
            margin: 10px 0;
        }

    }

    .product-info__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .product-info__showrooms--loading {
        transition: opacity 1s;
        opacity: 0.4;
        pointer-events: none;
    }

    .product-info__showrooms--spinner::after {
        content: " ";
        display: block;
        width: 21px;
        height: 21px;
        margin: 8px;
        border-radius: 50%;
        border: 3px solid #eceae6;
            border-top-color: rgb(236, 234, 230);
            border-right-color: rgb(236, 234, 230);
            border-bottom-color: rgb(236, 234, 230);
            border-left-color: rgb(236, 234, 230);
        border-color: #eceae6 transparent #eceae6 transparent;
        -webkit-animation: lds-dual-ring 2s linear infinite;
        animation: lds-dual-ring 2s linear infinite;
    }

    .product-info__showrooms--spinner {
        opacity: 0;
        transition: opacity 1s;
    }

    .product-info__showrooms--spinnershow {
        opacity: 1;
    }
    
