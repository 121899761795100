@use 'sass:math';

.showrooms {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 23px;
    row-gap: 70px;
    padding-bottom: 70px;
    border-bottom: 1px solid $c-grey-border;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        max-width: $tablet-max-width;
        margin-left: auto;
        margin-right: auto;
        row-gap: 50px;
        padding-bottom: 60px;
    }
}

    .showrooms__item {
        display: block;
    }

        .showrooms__image {
            @include aspect(math.div(7, 5));

            margin-bottom: 23px;

            @include breakpoint(tablet) {
                margin-bottom: 17px;
            }

            img {
                width: 100%;
                height: auto;
                transition: transform 0.3s ease-in-out;

                .showrooms__item:hover & {
                    transform: scale(1.075);
                }
            }
        }

        .showrooms__title {
            @include small-title;

            margin-bottom: 10px;

            @include breakpoint(tablet) {
                margin-bottom: 5px;
            }
        }

        .showrooms__address {
            @include mediumText;

            margin-bottom: 13px;

            @include breakpoint(tablet) {
                margin-bottom: 10px;
            }
        }

        .showrooms__text {
            margin-bottom: 20px;

            .showrooms__text-elipsis {
                display: none;
            }

            @include breakpoint(tablet) {
                .showrooms__text-expanded {
                    display: none;
                }

                .showrooms__text-elipsis {
                    display: inline;
                }
            }

        }

        .showrooms__footer {
            max-width: 900px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 50px;

            @include breakpoint(tablet) {
                max-width: $tablet-max-width;
            }

            @include breakpoint(phablet) {
                text-align: left;
            }
        }

