.block--swatches {
    max-width: $max-content-width;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    text-align: center;

    .block__title {
        margin-bottom: 20px;

        @include breakpoint(tablet) {
            margin-bottom: 10px;
            text-align: left;
        }
    }

    .block__swiper {
        @include breakpoint(phablet) {
            margin-right: calc($site-mobile-gutter * -1);
        }
    }

    .block__swiper-wrapper {
        display: flex;
        justify-content: center;
        gap: 23px;
        margin-bottom: 20px;

        @include breakpoint(phablet) {
            gap: unset;
        }
    }

        .block__slide {
            cursor: pointer;
            max-width: 110px;
            text-align: center;

            @include breakpoint(phablet) {
                max-width: 100%;
            }

            img {
                width: 100%;
                height: auto;
                margin-bottom: 10px;

                &:hover {
                    outline: 1px solid $c-dark;
                }
            }
        }

            .block__subtitle {
                @include mediumText;
            }

            .block__text {
                @include font(12px, 18px);

                margin-bottom: 3px;
            }
}
