.radio-tabs__wrapper {
}
.radio-tabs__wrapper--footstools {
    max-width: 400px;

    @include breakpoint(phablet) {
        max-width: 100%;
    }
}

.radio-tabs__wrapper--center {
    text-align: center;
}

.radio-tabs {
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #000;
    display: flex;
    max-width: 550px;
    margin-bottom: 20px;

    .radio-tabs__wrapper--center & {
        margin-left: auto;
        margin-right: auto;
    }

    @include breakpoint(phablet) {
        max-width: 100%;
    }

    input {
        display: none;
    }
}

.radio-tabs__item {
    display: flex;
    flex-basis: 50%;
    flex-shrink: 0;
    position: relative;
    margin: 0 10px 0 0;
    align-items: center;

    &::after {
        content: "";
        display: inline-block;
        border: 1px solid $c-border;
        position: absolute;
        right: 0;
        top: 5px;
        bottom: 5px;
    }

    &:last-of-type {
        margin-right: 0;

        &::after {
            display: none;
        }
    }
}

@for $i from 2 through 5 {
    .radio-tabs__item--#{$i} {
        flex-basis: calc((100% - ((#{$i} - 2) * 10px)) / #{$i});
    }
}

.radio-tabs__tab {
    color: #000;
    background: #fff;
    margin: 0;
    @include mediumText;
    @include rem(15px);
    letter-spacing: 1px;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 10px;
    max-width: calc(100% - 11px);

    @include breakpoint(phablet) {
        @include rem(12px);
    }

    @include hover {
        background: $c-grey;
        color: #000;
    }
}

.radio-tabs__label {
    display: inline-block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

input:checked + label.radio-tabs__tab,
.radio-tabs__tab--selected {
    background: #000;
    color: #fff;
}
