.xmas-checker {
    @include secondary-panel;
    // padding-bottom: 0;
}

.xmas-checker__results {
    text-align: left;
    min-width: 750px;

    h2 {
        @include rem(21px);
    }

    h3 {
        @include title;
        margin-bottom: 0;
        @include rem(17px);
    }

    p {
        @include rem(17px);
    }
}

.xmas-checker__thumbs {
    padding-left: 85px;
    position: relative;
    min-width: 0;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        content: "";
        width: 75px;
        height: 75px;
        background-size: cover;
    }

    span {
        color: #000;
    }
}

.xmas-checker__thumbs--yes::before {
    background-image: url("../../images/latest/thumbs-up.svg");
}
.xmas-checker__thumbs--no::before {
    background-image: url("../../images/latest/thumbs-down.svg");
}
.xmas-checker__thumbs--maybe::before {
    background-image: url("../../images/latest/thumbs-maybe.svg");
}
.xmas-checker__thumbs--invalid::before {
    background-image: url("../../images/latest/thumbs-invalid.svg");
}

@include breakpoint(nav) {
    .xmas-checker__results {
        min-height: 0;
        min-width: 0;
    }

    .xmas-checker__thumbs {
        padding-left: 0;

        &::before {
            position: static;
            margin: 0 auto 10px auto;
        }
    }
}
