@use 'sass:math';

.block--text-overlay {
    position: relative;
    margin-left: calc(((100vw - $site-max-width) / -2) - $site-gutter);
    margin-right: calc(((100vw - $site-max-width) / -2) - $site-gutter);

    @include breakpoint(large) {
        margin-left: calc($site-gutter * -1);
        margin-right: calc($site-gutter * -1);
    }

    @include breakpoint(tablet) {
        margin-left: calc($site-mobile-gutter * -1);
        margin-right: calc($site-mobile-gutter * -1);
    }

    .block__image {
        @include aspect(math.div(7, 3));

        img {
            width: 100%;
            height: auto;
        }

        @include breakpoint(phablet) {
            @include aspect(math.div(7, 5));
        }
    }

    .block__content {
        position: absolute;
        top: 120px;
        left: $site-gutter;
        padding: 45px 45px 40px;
        background-color: var(--site-colour-primary);
        max-width: 435px;
        margin-left: calc((100vw - $site-max-width) / 2);

        @include breakpoint(large) {
            margin-left: auto;
        }

        @include breakpoint(laptop) {
            top: $site-gutter;
        }

        @include breakpoint(phablet) {
            margin: -100px $site-mobile-gutter 0;
            position: relative;
            top: unset;
            left: 0;
            padding: $site-gutter;
            max-width: 100%;
        }
    }

        .block__title {
            margin-bottom: 10px;
            color: var(--site-text-colour-primary);

            @include breakpoint(phablet) {
                margin-bottom: 5px;
            }
        }

        .block__text {
            margin-bottom: 20px;
            color: var(--site-text-colour-primary);

            @include breakpoint(phablet) {
                margin-bottom: 10px;
            }
        }
}
