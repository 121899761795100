@use 'sass:math';

.newsletter-callout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: $max-content-width;
    margin: 50px auto 0;

    @include breakpoint(tablet) {
        max-width: $tablet-max-width;
        grid-template-columns: 1fr;
    }
}

    .newsletter-callout__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid $c-grey-border;
        border-right: none;
        padding: 40px 50px;

        @include breakpoint(tablet) {
            padding: 40px 24px;
            border-right: 1px solid $c-grey-border;
        }
    }

        .newsletter-callout__title {
            @include small-title;

            margin-bottom: 15px;
        }

        .newsletter-callout__text {
            margin-bottom: 20px;
        }

        .newsletter-callout__cta {
            @include smallprint;

            display: flex;
            gap: 23px;
            align-items: center;
            padding-bottom: 45px;
            margin-bottom: 40px;
            border-bottom: 1px solid $c-grey-border;

            @include breakpoint(tablet) {
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
                padding-bottom: 25px;
                margin-bottom: 25px;
            }
        }

        .newsletter-callout__socials {
            display: flex;
            gap: 30px;

            @include breakpoint(tablet) {
                flex-wrap: wrap;
                row-gap: 10px;
            }
        }
    
            .newsletter-callout__social-item {
                display: flex;
                align-items: center;

                @include breakpoint(tablet) {
                    &:first-child {
                        flex-basis: 100%;
                    }
                }

                p {
                    @include large-subtitle;
                }
    
                a {
                    display: flex;
                }
    
                svg {
                    height: 20px;
                    width: auto;
                    transition: fill 150ms ease-in;
    
                    &:hover {
                        fill: var(--site-colour-primary);
                    }
                }
            }

    .newsletter-callout__media {
        @include aspect(math.div(5, 3));

        @include breakpoint(tablet) {
            order: -1;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
