.swatch-filter {
    margin-top: 20px;

    @include breakpoint(tablet) {
        margin-top: 0;
    }
}

    .swatch-filter__nav-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        margin-right: -10px;

        @include breakpoint(tablet) {
            display: none;
        }
    }

        .swatch-filter__nav-item {}

            .swatch-filter__nav-item--count {
                margin-right: auto;
            }

                .swatch-filter__nav {
                    padding: 10px;
                    display: grid;
                    place-items: center;
                    cursor: pointer;
            
                    &:disabled {
                        opacity: 0.5;
                        cursor: default;
                    }
                }

    .swatch-filter__slides {
        @include breakpoint(tablet) {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            gap: 12px;
        }
    }

        .swatch-filter__slide {
        }

        .swatch-filter__slide--filtered {
            display: none;
        }
