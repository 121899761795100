.modular-add {
  width: 286.376px;
  height: 286.376px;
}

.modular-basket {
  width: 70px;
  height: 70px;
}

.modular-bin {
  width: 65px;
  height: 65px;
}

.modular-fabrics {
  width: 548px;
  height: 313px;
}

.modular-rotate-left {
  width: 75px;
  height: 75px;
}

.modular-rotate-right {
  width: 75px;
  height: 75px;
}
