.overlay.overlay {
    z-index: 100000;

    .overlay__title {
        @include small-title;

        text-align: center;
        padding-bottom: 20px;
        margin-bottom: 23px;
        border-bottom: 1px solid $c-grey-border;
    }

    .overlay__media {
        img {
            width: 100%;
            height: auto;
        }
    }

    .overlay__buttons {
        margin-top: 30px;
        text-align: center;
    }

    .overlay__close {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $c-light-grey;
        transition: background-color 150ms ease-in;
        display: grid;
        place-items: center;
        cursor: pointer;
        z-index: 1;
        top: $site-gutter;
        right: $site-gutter;

        @include breakpoint(phablet) {
            top: $site-mobile-gutter;
            right: $site-mobile-gutter;

            svg {
                width: 18px;
                height: 18px;
            }
        }

        &:hover {
            background-color: $c-grey;
        }

        svg {
            stroke: none;
        }
    }
}

.overlay__content {
    .lds-dual-ring {
        display: inline-block;
        width: 80px;
        height: 80px;
        margin: auto;
      }

      .lds-dual-ring:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid $c-grey;
        border-color: $c-grey transparent $c-grey transparent;
        animation: lds-dual-ring 2s linear infinite;
      }

      @keyframes lds-dual-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
    }
}

.overlay--wide {
    .overlay__content {
        padding: 25px $site-gutter 40px;
        height: 100%;

        @include breakpoint(phablet) {
            padding: 15px $site-mobile-gutter 40px;
        }
    }
}

.overlay--takeover.overlay--takeover {
    .overlay__content {
        padding: 25px $site-gutter;
        height: 100%;

        @include breakpoint(phablet) {
            padding: 15px $site-mobile-gutter;
        }
    }

    .overlay__bg {
        background-color: $c-white;
    }

    .overlay__title {
        @include breakpoint(tablet) {
            text-align: left;
            padding-right: 40px;
        }
    }
}

.overlay--product-info.overlay--sidebar {
    .overlay__bg {
        background: $c-black;
    }

    .overlay__container {
        max-width: 715px;
    }

        .overlay__close {
            left: initial;
        }

        .overlay__content {
            padding: 25px $site-gutter;

            @include breakpoint(phablet) {
                padding: 15px $site-mobile-gutter;
            }
        }

    &.overlay-transition--fade.overlay--opening,
    &.overlay-transition--fade.overlay--active {
        .overlay__bg {
            opacity: 0.65;
        }
    }
}

.overlay--swatch-form.overlay--swatch-form {
    .overlay__content {
        padding: 20px $site-gutter;
        height: 100%;

        @include breakpoint(phablet) {
            padding: 15px $site-mobile-gutter;
        }
    }

    .overlay__bg {
        background-color: $c-white;
    }
    .overlay__close {
        display: none;
    }
}

.overlay--basket-overlay {
    .overlay__content {
        padding: $site-gutter;
        padding-bottom: 40px;
        height: 100%;
        overflow: auto;

        @include breakpoint(phablet) {
            padding: 15px $site-mobile-gutter 30px;
            max-height: 100vh;
            margin-bottom: unset;
        }
    }
}

.overlay.chooser-panel {
    .overlay__content {
        @include breakpoint(tablet) {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
}

.overlay--chooser-panel.overlay--takeover,
.overlay--product-upsell.overlay--takeover,
.overlay--product-options.overlay--takeover {
    .overlay__container {
        transform: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .overlay__content {
        transform: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.overlay--takeover.overlay--product-options {
    .overlay__content {
        display: flex;
        flex-direction: column;
    }
}

.overlay--wide.overlay--swatch-success {
    .overlay__title {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .overlay__success {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

        .overlay__success-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70px;
            height: 70px;
            margin: 0 auto;
            background: $c-grey;
            border-radius: 100%;

            svg {
                width: 30px;
                height: auto;
            }
        }
}
