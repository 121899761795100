@use 'sass:math';

.banner {
    --banner-top-offet: 0px;
    position: relative;

    .body--debug & {
        max-height: calc(100vh - $notice-height - 20px);
    }

    .body--admin & {
        max-height: calc(100vh - $notice-height - 44px);
    }

    .body--debug.body--admin & {
        max-height: calc(100vh - $notice-height - 64px);
    }

    .body--debug &,
    .body--admin &,
    .body--debug.body--admin & {
        @include breakpoint(phablet) {
            max-height: none;
        }
    }
}

.banner--large {
    --banner-top-offet: 60px;
    min-height: calc(min(700px, 59vw));

    position: relative;
    overflow: hidden;

    &.banner--gradient::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 50%;
        left: 0;
        opacity: 0.55;
        background: linear-gradient(
            to right,
            $c-black 0%,
            rgba($c-black, 0) 100%
        );
        z-index: 1;

        @include breakpoint(phablet) {
            right: 0;
            top: unset;
            background: linear-gradient(
                to top,
                $c-black 0%,
                rgba($c-black, 0) 100%
            );
            opacity: 0.75;
            height: 60%;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 250px;
        opacity: 0.5;
        background: linear-gradient(
            to bottom,
            $c-black 0%,
            rgba($c-black, 0) 100%
        );
        z-index: 1;

        @include breakpoint(phablet) {
            opacity: 0.65;
            height: 200px;
        }
    }

    &.banner--right {
        &::before {
            left: 50%;
            right: 0;
            background: linear-gradient(
                to left,
                $c-black 0%,
                rgba($c-black, 0) 100%
            );
        }
    }
}

.banner--medium {
    --banner-top-offet: 0px;

    &.banner--gradient::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 50%;
        opacity: 0.55;
        background: linear-gradient(
            to right,
            $c-black 0%,
            rgba($c-black, 0) 100%
        );
        z-index: 1;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        opacity: 0.2;
        height: 200px;
        background: linear-gradient(
            to top,
            $c-black 0%,
            rgba($c-black, 0) 100%
        );
        z-index: 1;
    }

    &.banner--right {
        &::before {
            right: 0;
            left: initial;
            background: linear-gradient(
                to left,
                $c-black 0%,
                rgba($c-black, 0) 100%
            );
        }
    }
}

.banner--dark:not(.banner--text-only[class*='banner-background']) {
    color: $c-dark;
}

.banner--light:not(.banner--text-only[class*='banner-background']) {
    color: $c-white;
}

.banner--content-below {
    @include breakpoint(phablet) {
        color: var(--site-text-colour-primary);

        .button:not(.button--link) {
            background-color: var(--site-text-colour-primary);
            color: $c-dark;

            &:hover {
                background-color: $c-dark;
                color: var(--site-text-colour-primary);
            }
        }
    }
}

// override site-colour-primary--blush banner colour
body.site-colour-primary--blush {
    @include breakpoint(phablet) {
        .banner--content-below {
            .button:not(.button--link) {
                background-color: $c-white;
                color: $c-dark;
                &:hover {
                    background-color: $c-dark;
                    color: $c-white;
                }
            }
        }
    }
}

.banner--content-overlay {
    @include breakpoint(phablet) {
        video {
            bottom: 0;
            top: 0;
            left: 0;
            height: 100% !important; // override inline styling
            object-fit: cover;
        }
    }
}

.banner--text-only:not(.banner--text-only[class*='banner-background']) {
    color: $c-dark;
    margin: 60px auto;
    max-width: calc(100% * (2 / 3));

    @include breakpoint(tablet) {
        margin: 45px auto;
        color: $c-dark;
        max-width: 100%;
    }
}

.banner__media {
    @include aspect(math.div(5, 3));

    position: relative;

    iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    img,
    video {
        position: absolute;
        left: 0;
        width: 100%;
        height: auto;
        margin: auto;

        .banner-gravity--top & {
            top: 0;
        }

        .banner-gravity--mid & {
            top: 0;
            bottom: 0;
        }

        .banner-gravity--bottom & {
            bottom: 0;
        }
    }

    .banner--large & {
        max-height: calc(100vh - 45px);
        min-height: min(700px, 59vw);


        @include breakpoint(phablet) {
            @include aspect(math.div(5, 6));

            max-height: calc(100vh - $notice-height-mobile);
        }
    }

    .banner--medium & {
        @include aspect(math.div(3, 1));

        @include breakpoint(phablet) {
            @include aspect(math.div(5, 3));
        }
    }

    .banner--content-below.banner--large & {
        @include breakpoint(phablet) {
            @include aspect(math.div(5, 6));
        }
    }
}

.banner__inner-content .banner__svg--desktop,
.banner__inner-content .banner__svg--mobile {
    & {
        position: absolute;
        z-index: 3;
    }
}

.banner__inner-content .banner__svg--desktop {
    &--top-left {
        top: calc(60px + var(--banner-top-offet, 0));

        @media only screen and (max-width: 1099px) {
            top: calc(50px + var(--banner-top-offet, 0));
        }

        @media only screen and (max-width: 1024px) {
            top: calc(30px + var(--banner-top-offet, 0));
        }

        left: 70px;

        .banner--medium.banner--left &,
        .banner--medium.banner--right & {
            left: 25px;
            top: calc(30px + var(--banner-top-offet, 0));
        }
    }

    &--top-right {
        top: calc(60px + var(--banner-top-offet, 0));

        @media only screen and (max-width: 1099px) {
            top: calc(50px + var(--banner-top-offet, 0));
        }

        @media only screen and (max-width: 1024px) {
            top: calc(30px + var(--banner-top-offet, 0));
        }

        right: 70px;

        .banner--medium.banner--left &,
        .banner--medium.banner--right & {
            right: 25px;
            top: calc(30px + var(--banner-top-offet, 0));
        }
    }

    &--bottom-left {
        bottom: 30px;
        left: 70px;

        .banner--medium.banner--left &,
        .banner--medium.banner--right & {
            left: 25px;
        }
    }

    &--bottom-right {
        bottom: 30px;
        right: 70px;

        .banner--medium.banner--left &,
        .banner--medium.banner--right & {
            right: 25px;
        }
    }
}

.banner__inner-content .banner__svg--mobile {
    &--top-left {
        top: calc(40px + var(--banner-top-offet, 0));
        left: 20px;

        .banner--medium.banner--left &,
        .banner--medium.banner--right & {
            top: calc(30px + var(--banner-top-offet, 0));
        }
    }

    &--top-right {
        top: calc(40px + var(--banner-top-offet, 0));
        right: 20px;

        .banner--medium.banner--left &,
        .banner--medium.banner--right & {
            top: calc(30px + var(--banner-top-offet, 0));
        }
    }

    &--bottom-left {
        bottom: 20px;
        left: 20px;
    }

    &--bottom-right {
        bottom: 20px;
        right: 20px;
    }
}

.banner__inner-content {
    .banner__svg {
        svg {
            width: 100%;
            height: auto;
        }

        /*** Desktop ***/
        &--desktop-size-large {
            width: 24vw;
            height: auto;

            .banner--medium.banner--left & {
                width: 13vw;
            }

            .banner--medium.banner--right & {
                width: 13vw;
            }
        }

        &--desktop-size-medium {
            width: 20vw;
            height: auto;

            .banner--medium.banner--left & {
                width: 10vw;
            }

            .banner--medium.banner--right & {
                width: 10vw;
            }
        }

        &--desktop-size-small {
            width: 15vw;
            height: auto;

            .banner--medium.banner--left & {
                width: 7vw;
            }

            .banner--medium.banner--right & {
                width: 7vw;
            }
        }

        /*** Mobile ***/
        &--mobile-size-xlarge {
            width: calc(50% - 20px);
        }

        &--mobile-size-large {
            width: calc(25% - 20px);
        }

        &--mobile-size-medium {
            width: calc(20% - 20px);
        }

        &--mobile-size-small {
            width: calc(15% - 20px);
        }
    }
}

.banner__content {
    position: absolute;
    z-index: 2;
    bottom: 70px;
    max-width: 500px;

    @include breakpoint(tablet) {
        bottom: 40px;
    }

    @include breakpoint(phablet) {
        max-width: 100%;
    }

    .button-group {
        margin-top: 15px;
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;

        .field.button {
            margin: 0;
        }

        .button--link {
            color: $c-white;
        }
    }

    .banner--left & {
        left: 70px;
        text-align: left;

        @include breakpoint(phablet) {
            left: $site-mobile-gutter;
            right: $site-mobile-gutter;
        }
    }

    .banner--medium.banner--left & {
        left: $site-gutter;
    }

    .banner--right & {
        right: 70px;
        text-align: right;

        @include breakpoint(phablet) {
            left: $site-mobile-gutter;
            right: $site-mobile-gutter;
        }
    }

    .banner--medium.banner--right & {
        right: $site-gutter;
    }

    .banner--center & {
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

        @include breakpoint(phablet) {
            padding-left: $site-mobile-gutter;
            padding-right: $site-mobile-gutter;
            left: 0;
            right: 0;
            transform: none;
        }
    }

    .banner--medium & {
        bottom: 35px;
    }

    .banner--left.banner--content-below &,
    .banner--center.banner--content-below &,
    .banner--right.banner--content-below & {
        @include breakpoint(phablet) {
            left: unset;
            right: unset;
            transform: unset;
        }
    }

    .banner--content-below:not(.banner--text-only) & {
        @include breakpoint(phablet) {
            position: relative;
            bottom: unset;
            background-color: var(--site-colour-primary);
            padding: 35px $site-mobile-gutter 40px;
        }
    }

    .banner--content-below.banner--medium & {
        @include breakpoint(phablet) {
            padding-bottom: 15px;
            text-align: center;
            color: $c-body;
            background-color: $c-white;
        }
    }

    .banner__svg {
        &--desktop {
            svg {
                max-height: 6rem;
            }
        }

        &--mobile {
            svg {
                max-width: 16rem;
            }
        }
    }
}

.banner__svg {
    &--mobile {
        display: none;
    }

    @include breakpoint(phablet) {
        &--desktop {
            display: none;
        }

        &--mobile {
            display: block;
        }
    }
}

.banner__content--text-only {
    position: relative;
    max-width: 100%;
    bottom: 0;
    max-width: $max-content-width;

    @include breakpoint(tablet) {
        max-width: $tablet-max-width;
        padding-left: $site-mobile-gutter;
        padding-right: $site-mobile-gutter;
    }
}

.banner__title {
    @include large-title;

    color: inherit;
    text-transform: uppercase;

    .banner__content--text-only & {
        @include medium-title;
    }
}

.banner__subheading {
    margin-top: 10px;
    color: inherit;

    .banner__content--text-only & {
        @include intro;
    }
}

.banner--text-only[class*='banner-background'] {
    padding: 80px 0;

    .banner__content {
        max-width: calc(100% * (2 / 3));
    }

    &.banner--right .banner__content {
        margin-left: auto;
    }

    @include breakpoint(large) {
        padding-left: $site-gutter;
        padding-right: $site-gutter;
    }

    @include breakpoint(phablet) {
        .banner__content {
            max-width: $tablet-max-width;
        }

        &.banner--right .banner__content {
            margin: 0;
        }

        padding: 60px $site-mobile-gutter;
    }
}
