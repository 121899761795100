.section-intro__longcopy--why-us {
    svg {
        margin-top: 15px;
        max-height: 60px;
    }
}

.why-us {
    margin-bottom: 60px;
}

.why-us__media {
    margin-bottom: 30px;

    img {
        width: 100%;
        height: auto;
    }
}

.why-us__heading {
    @include small-title;

    margin-bottom: 15px;
    text-align: center;
}

.why-us__longcopy {
    @include intro();
    text-align: center;
}
