.block--full-width-image {
    .block__image {
        @include aspect(16 / 9);

        position: relative;
        max-width: $max-content-width;
        margin-left: auto;
        margin-right: auto;

        img {
            width: 100%;
            height: auto;
        }
    }
}
