.product-details {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 23px;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        max-width: $tablet-max-width;
        margin-left: auto;
        margin-right: auto;
        gap: 15px;
    }
}

    .product-details__config {
        padding: 0 23px;

        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    .product-details__config-sticky-container {
        position: sticky;
        top: $header-height + $notice-height;
    }

    .product-details__anchors {
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin: 20px 0;
    }

    .product-details__marketing {
        margin: 20px 0;

        &:empty {
            display: none;
        }

        span {
            font-weight: bold;
        }
    }

    .product-details__marketing--overlay:not(:empty) {
        width: 100%;
        border-bottom: 1px solid $c-grey-border;
        margin-bottom: 15px;
        padding-bottom: 10px;
    }

    .product-details__totals {
        display: flex;
        gap: 23px;
        align-items: center;
        margin-top: 20px;

        @include breakpoint(tablet) {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }
    }

    .product-details__notice {
        text-align: center;
        background-color: #BF282D;
        border-radius: 5px;
        padding: 20px;
        color: #fff;

        h4 {
            text-transform: uppercase;
            fill:  currentColor;
            font-size: 15px;
            font-weight: bold;
            display: grid;
            gap: 10px;
            grid-auto-flow: column;
            justify-content: center;
            align-items: center;

            svg:nth-last-of-type(1) {
                transform: rotateY(180deg);
            }
        }

        p {
            font-size: 13px;
        }

        .product-details__totals + &,
        & + & {
            margin-top: 20px;
        }

        &[data-type="private-sale"] {
            background-color: #154651;
            color: #E9CB78;
        }

        &[data-type="christmas"] {
            background-color: #5E1C31;
            color: #ECEAE6;

            &[data-cutoff="true"] {
                background-color: #F0EAE3;
                color: inherit;

                h4 {
                    text-transform: none;
                }
            }
        }
    }

    .product-details__delivery {
        padding: 17px 0 14px;
        border-bottom: 1px solid $c-grey-border;
    }
    
    .product-details__instock-link {
        font-weight: 500;
        background-color: transparent;
        padding: 0;
        padding-bottom: 0px;
        padding-bottom: 3px;
        border-bottom: 1px solid currentColor;
        margin-left: 5px;

        &--no-underline {
            text-decoration: none;
            border-bottom: none;
        }

        &--bold {
            font-weight: bold;
        }

        &hover {
            background-color: transparent;
            color: var(--site-colour-primary);
        }
    }

    .product-details__delivery--with-christmas-panel {
        border-bottom: none;
    }

    .product-details__paypal {
        height: 46px; // Avoid CLS
        padding: 13px 0 6px;
        border-bottom: 1px solid $c-grey-border;
    }

    .product-details__action {
        width: 100%;
    }

    .product-details__gallery {
        position: relative;

        .product-details__gallery-pinterest {
            position: absolute;
            top: 15px;
            left: 14px;
            z-index: 15;

            @include breakpoint(tablet) {
                left: $site-mobile-gutter;
            }

            @include breakpoint(phablet) {
                left: 0px;
            }

            a {
                width: 38px;
                height: 38px;
                background-color: $c-grey;
                opacity: .8;
                cursor: pointer;
                display: grid;
                place-items: center;
                border-radius: 50%;
                transition: opacity 150ms ease-in;
            }

            a:hover {
                opacity: 1;
            }

            svg {
                height: 20px;
                width: 20px;
            }
        }
    }

    .product-details__totals_overlay {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 0 10px;
        align-items: center;

        .product-prices__price {
            @include font(20px, 24px);
        }

        .product-details__delivery-info--overlay {
            @include font(12px, 14px);
        }

        form {
            grid-column: 2 / 3;
            grid-row: 1 / 3;
        }

        @include breakpoint(phablet) {
            gap: 12px 10px;

            .product-prices__price {
                @include font(18px, 24px);
            }

            .product-details__delivery-info--overlay {
                grid-column: 1 / -1;
                border-top: 1px solid $c-grey-border;
                padding-top: 12px;
                text-align: center;
            }

            form {
                grid-row: 1 / 2;
            }
        }
    }
