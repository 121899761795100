.filter-range {
    display: flex;
    position: relative;
    transition: 0.1s ease opacity;

    @include breakpoint(tablet) {
        display: block;
    }

    &.grid--loading {
        opacity: 0.3;
        pointer-events: none;
    }

    &:not(:last-child) {
        margin-right: 45px;

        @include breakpoint(tablet) {
            margin-right: 0;
            margin-bottom: 40px;
        }
    }

    &:last-child {
        margin-left: 45px;

        &::before {
            content: '';
            position: absolute;
            left: -45px;
            top: 50%;
            transform: translateY(-50%);
            height: 75px;
            background: $c-grey;
            width: 1px;
        }

        @include breakpoint(tablet) {
            margin: 0;

            &::before {
                display: none;
            }
        }
    }
}

    .filter-range__item {
        max-width: 270px;

        @include breakpoint(tablet) {
            max-width: 100%;
        }
    }

        .filter-range__title {
            @include font(12px, 15px);
            @include mediumText;

            text-align: left;
            width: 100%;
            color: $c-dark;
            display: block;
        }

        .filter-range__slider {
            margin: 20px 10px;

            &[disabled] {
                opacity: 0.3;

                & ~ .filter-range__inputs {

                    label {
                        opacity: 0.3;
                    }
                }
            }
        }

        .filter-range__inputs {
            display: flex;
            justify-content: space-between;

            /* Hide the arrows */

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }
        }

            .filter-range__field {
                position: relative;

                &:not(:first-of-type) {
                    margin-left: 10px;
                }
            }

                .filter-range__label {
                    @include font(11px, 13px);

                    position: absolute;
                    top: 3px;
                    left: 7px;
                    color: $c-dark;
                }

                input[type="number"].filter-range__input {
                    @include form-input;
                    @include mediumText;

                    padding: 15px 0 5px 7px;
                    min-width: 130px;
                    width: 100%;
                    color: $c-dark;
                    border: 1px solid $c-dark;
                    height: 36px;

                    &.filter-range__input-error {
                        border-color: $c-error;
                        border-width: 2px;
                        background: rgba($c-error, 0.1);
                    }

                    &[disabled] {
                        cursor: not-allowed;
                        opacity: 0.3;
                    }
                }
