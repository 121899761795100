// Remove margin of first/last child

@mixin flush-first-child {
    & > :first-child:not(.grid) {
        margin-top: 0;
    }
}

@mixin flush-last-child {
    & > :last-child:not(.grid) {
        margin-bottom: 0;
    }
}
