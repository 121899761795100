.auto-complete {
    position: relative;
}

    .auto-complete__list {
        position: absolute;
        top: 1px;
        left: 0;
        right: 0;
        max-height: 195px;
        overflow: auto;
        z-index: 2;
        background-color: $c-white;
        border: 1px soliod $c-light-grey;
        box-shadow: 5px 5px 20px 5px rgba($c-black, 0.15);

        button {
            cursor: pointer;
            padding: 5px 15px;
            width: calc(100% - 30px);

            &:hover {
                background-color: $c-light-grey;
            }

            b {
                pointer-events: none;
            }
        }
    }
