// Remove white space from space charecters in html etc 
@mixin remove-white-space {
    font-size: 0 !important;
    letter-spacing: normal;
    word-spacing: normal;
    & > * {
        display: inline-block;
        font-size: 1rem;
    }
}
