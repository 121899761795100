.elastic-filter {
    border: 1px solid $c-border;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 0;
    min-height: 54px;
    transition: opacity .1s ease;
    cursor: pointer;

    &:hover {
        border-color: $c-dark;
    }

    input[type="radio"] {
        display: none;
    }

    svg[class^="listing-icon-filter"] {
        margin-top: 10px;
        height: 48px;
        width: 100%;

        @include breakpoint(tablet) {
            height: 80px;
            padding: 0 10px;
            width: auto;
        }
    }

    .grid--loading & {
        opacity: 0.3;
    }
}

.elastic-filter--selected {
    border-color: $c-dark;

    .filterable__dimension--colour_style & {

        @include breakpoint(tablet) {
            background: $c-dark;
        }
    }

    .grid--loading & {
        opacity: 1;
    }
}

.elastic-filter__container {
    display: grid;
    grid-template-columns: repeat(8, minmax(auto, 1fr));
    gap: 15px;

    @include breakpoint(tablet) {
        display: grid;
        gap: 18px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin: 0;
    }

    @include breakpoint(phablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.elastic-filter--colour {
    @include swatch-hover;

    border: none;
    width: 54px;
    height: 54px;
    cursor: pointer;

    img {
        width: 100%;
        height: auto;
    }

    &:not(:last-child) {
        margin-right: 1px;
    }

    .filterable__dimension--colour_style & {

        @include breakpoint(tablet) {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: auto;
            height: auto;
            margin: 0;
            border: 1px solid $c-border;

            img {
                position: relative;
                width: 60px;
                height: 60px;
                margin: 0;
                border: 5px solid $c-white;
            }
        }
    }

    &.elastic-filter--selected {
        position: relative;
        overflow: visible;

        img {
            z-index: 2;
        }

        .filterable__dimension--colour_style & {
            border-color: $c-dark;
        }

        &::before {
            content: '';
            position: absolute;
            top: -3px;
            left: -3px;
            right: -3px;
            bottom: -3px;
            background-color: $c-dark;

            .filterable__dimension--colour_style & {

                @include breakpoint(tablet) {
                    display: none;
                }
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            right: -2px;
            bottom: -2px;
            background-color: $c-white;

            .filterable__dimension--colour_style & {

                @include breakpoint(tablet) {
                    display: none;
                }
            }
        }
    }
}

.elastic-filter--swatch {
    position: relative;
    border: none;
    cursor: pointer;

    img {
        margin: 0;
        width: 100%;
        height: auto;
    }

    @include breakpoint(tablet) {
        width: auto;
        height: auto;
    }
}

.elastic-filter__swatch-container {
    @include swatch-hover;

    .elastic-filter--selected {
        @include swatch-active;
    }
}

.elastic-filter__search-link {
    @include breakpoint(tablet) {
        align-self: flex-start;
    }
}

.elastic-filter__no-results {
    display: none;
    width: 100%;
}

.elastic-filter__no-results--active {
    min-height: 140px;
    display: block;
}

// TODO
.elastic-filter--size {
    display: flex;
    height: 100%;
    align-items: center;

    @include breakpoint(tablet) {
        min-height: 60px;
    }
}

.elastic-filter--all-colours {

    @include breakpoint(tablet) {
        grid-column: 1/4;
    }

    @include breakpoint(phablet) {
        grid-column: 1/3;
    }
}

    .elastic-filter__content {
        padding: 10px 20px;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-basis: 0;
        flex-grow: 1;

        @include breakpoint(tablet) {
            padding: 15px 12px;
        }

        .elastic-filter--selected & {
            background-color: $c-dark;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border: 2px solid $c-white;
            }
        }
    }

    .elastic-filter__text {
        @include font(12px, 18px);

        text-align: center;
        margin: 0;

        @include breakpoint(tablet) {
            @include font(15px, 19px);
        }

        .elastic-filter--selected & {
            color: $c-white;
            background: $c-dark;
        }
    }



    .elastic-filter__text--group{
        
        .elastic-filter--selected & {
            color: $c-dark;
            background: $c-dark;
        }
    }

    .elastic-filter__colour-label {
        display: none;

        @include breakpoint(tablet) {
            @include font(15px, 19px);

            display: block;
            margin: 0;
            padding-left: 12px;
            padding-right: 10px;

            .elastic-filter--selected & {
                color: $c-white;
            }
        }
    }

    .elastic-filter__text--group {
        @include mediumText;
        @include body;

        margin: 12px 0 8px;

        .elastic-filter--selected & {
            background: transparent;
        }

        @include breakpoint(tablet) {
            @include font(12px, 15px);
            @include mediumText;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .elastic-filter__text--sizes {
        margin-top: 4px;
    }
