.swatch-form {
    .added {
        display: none;
    }
}

    .swatch-form__header {
        padding-bottom: 15px;
        border-bottom: 1px solid $c-grey-border;
    }

        .swatch-form__title {
            @include small-title;

            position: relative;
            text-align: center;

            .button {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

    .swatch-form__swatches {
        margin: 40px auto 30px;
    }

    .swatch-form__brochure {
        max-width: 470px;
        margin-left: auto;
        margin-right: auto;
    }

    .swatch-form__form-elements {
        max-width: calc((2 / 3) * 100%);
        margin: 50px auto 0;
        padding: 50px 115px;
        background-color: $c-light-grey;

        @include breakpoint(tablet) {
            max-width: 100%;
            padding: 0;
            background-color: $c-white;
            margin: 0 auto 25px;
        }

        &:has(input[type="checkbox"]:checked) {
            .not-added {
                display: none;
            }
    
            .added {
                display: block;
            }
        }
    }

    .swatch-form__footer {
        border-top: 1px solid $c-grey-border;
        padding-top: 25px;
        margin-top: 100px;
        text-align: center;

        @include breakpoint(tablet) {
            padding: 30px 0 15px;
            margin-top: 30px;
        }
    }
