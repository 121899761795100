.search {

}

    .search__submit-form {
        max-width: $tablet-max-width;
        margin: 20px auto 0;
    }

    .search__suggestions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        @include breakpoint(tablet) {
            flex-wrap: wrap;
            gap: 10px;
        }
    }

    .search__results-list {
        display: flex;
        justify-content: center;
        align-items: center;
    }

        .search__results-header {
            @include small-title;
        }
