.basket-overlay {}

    .basket-overlay__title {
        @include small-title;

        text-align: center;

        @include breakpoint(tablet) {
            padding: 0 40px;
        }

        & ~ .richtext {
            margin-top: 30px;
        }
    }

    .basket-overlay__button {
        text-align: center;
        margin-top: 20px;
    }

    .basket-overlay__form {
        text-align: center;
        margin-bottom: 20px;
    }

    .basket-overlay__image {
        img {
            width: 100%;
            height: auto;
        }
    }
