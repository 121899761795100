.slide-in {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.5s;
    will-change: opacity;
    background: #fff;
    z-index: z-index(modal) !important;
    width: 1200px;
    max-width: calc(100% - 30px);
    border-left: 5px solid $c-dark;
    overflow: visible;
    pointer-events: none;

    .band,
    .wrap {
        width: auto;
        max-width: none;
        min-width: none;
        padding-top: 0;
    }
}

.slide-in--show {
    right: 0;
    opacity: 1;
    pointer-events: all;
}

.slide-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px;
    overflow: visible;
    display: none;
    background: #fff;

    .slide-in & {
        display: flex;
    }

    @include breakpoint(phablet) {
        border-radius: 0 0 0 10px;
    }
}

.slide-content--active {
    z-index: z-index(modal-active) !important;

    .slide-in--busy & > div {
        opacity: 0.4;
        cursor: 'wait';
    }
}

@function tabPos($tabNum) {
    @return 5px + ((($tabNum - 1) % 5) * 102);
}

.slide-content__tab {
    display: block;
    width: 24px;
    height: 100px;
    position: absolute;
    background: $c-border;
    left: -29px;
    writing-mode: vertical-lr;
    text-align: center;
    transform: rotate(180deg);
    color: $c-body;
    cursor: pointer;
    border-radius: 0 3px 3px 0;
    @include mediumText;

    .slide-content--active & {
        color: #fff;
        background: $c-dark;
    }

    @for $i from 0 through 5 {
        .slide-content:nth-of-type(#{$i}) & {
            top: tabPos($i);
        }
    }
}

// Close/back button (container)
.slider__back {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    right: 0;
    height: 30px;
    line-height: 30px;
    padding-right: 35px;
    z-index: z-index(modal-close) !important;

    &::before {
        content: "CLOSE";
        color: #bbb;
        @include rem(13px);
        @include mediumText;
        letter-spacing: 1.5px;
    }
}
