// Hide only visually, but have it available for screenreaders: h5bp.com/v ----------------------------------------------------------------------------------

@mixin visually-hidden {
    border: 0; 
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
}
