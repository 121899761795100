.quick-add-overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .quick-add-overlay__title {
        @include small-title;
        border-bottom: 1px solid #dddbd7;
        padding-bottom: 24px;

        @include breakpoint(phablet) {
            padding-right: 40px;
            margin-bottom: 20px;
        }
    }

    .quick-add-overlay__basket,
    .quick-add-overlay__insurance-form {
        border-top: 1px solid #dddbd7;
        background-color: #f5f4f2;
        padding: 12px 24px;
        margin-top: auto;

        .button--light {
            border: 1px solid #dddbd7;
        }
    }


    .quick-add-overlay__yes-insurance-form{
        .checkbox-replace {
            .checkbox-warning {
                margin: 5px 0;
            }
        }
    }

    .quick-add-overlay__insurance-content {
        display: flex;
        flex-direction: column;
        justify-content: start;

        .quick-add-overlay__insurance-content--info {
            margin-top: 20px;
            margin-bottom: 20px;

            ul li:before {
                left: -10px;
                top: 12px;
                height: 4px;
                width: 4px;
            }

            > h3 {
                font-size: 18px;
            }
        }

        .quick-add-overlay__insurance--image-wrap {
            display: flex;
            justify-content: start;
            align-items: center;
            margin-bottom: 20px;
            gap: 10px;
            border-bottom: 1px solid $c-grey-border;


            .quick-add-overlay__insurance--image-title {
                @include mediumText;
                @include font(20px, 24px);
                margin-bottom: 4px;

                @include breakpoint(mobile) {
                    @include font(18px, 24px);
                }
            }

            .quick-add-overlay__insurance_image {
                max-width: 200px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .quick-add-overlay__insurance-form {
        .checkbox-replace {
            padding: 0;
        }

        label {
            @include font(12px, 20px);

            > a {
                font-size: inherit;
                line-height: inherit;
            }
        }

        .quick-add-overlay__no-insurance-form {
            display: grid;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            margin-top: 12px;
            grid-template-columns: repeat(2, 1fr);

            @include breakpoint(mobile) {
                grid-template-columns: 1fr 2fr;
            }
        }
    }

    .quick-add-overlay__section {
        margin-left: 24px;
        margin-right: 24px;
    }
}


.overlay__container {
    >.overlay__content:has(>.quick-add-overlay) {
        padding: 0;
        padding-top: 25px;
    }

    .quick-add-overlay__inline-info {
        border-top: 1px solid #dddbd7;
        background-color: #f5f4f2;
        padding: 12px 24px;

        .button--light {
            border: 1px solid #dddbd7;
        }
    }

    .quick-add-overlay__section--spacing-bottom {
        margin-bottom: 20px;
    }

    .quick-add-overlay__section--spacing-top {
        margin-top: 20px;
    }
}

