.product-dimensions {
    margin-top: 30px;
    display: grid;
    align-items: flex-start;
    grid-template-columns: 3fr 2fr;
    gap: 23px;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
    }
}

    .product-dimensions__first-col {
        overflow: hidden;
        position: relative;

        @include breakpoint(phablet) {
            margin-left: -2.5%;
            width: 105%;
        }
    }

    .product-dimensions__nav {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            padding: 10px;
            display: flex;
        }
    }

    .product-dimensions__nav--disabled {
        display: none;
    }

    .product-dimensions__table-container {
        overflow: auto;

        @include breakpoint(tablet) {
            display: flex;
            justify-content: center
        }

        @include breakpoint(phablet) {
            justify-content: flex-start;
            -webkit-transform: translateZ(0); /* Fix sticky z-index bug in iOS Safari */
        }
    }

    table.product-dimensions__table {
        @include font(13px, 18px);

        width: 100%;
        table-layout: fixed;
        border-collapse: inherit;

        thead {
            @include mediumText;

            border-top: none;

            tr {
                background-color: $c-light-grey;
            }
        }

        tr.product-dimensions__table-body-row {
            td {
                background-color: $c-white;
            }

            &:nth-child(even) {
                td {
                    background-color: $c-light-grey;
                }
            }

            &:nth-child(n+6) {
                display: none;
            }

            &:last-child {
                td {
                    border-bottom: 1px solid transparent;
                }

                td.product-dimensions__cell-selected {
                    border-bottom: 2px solid $c-dark;
                }
            }
        }

        tr.product-dimensions__table-body-row--last-visible {
            td.product-dimensions__cell-selected {
                border: 2px solid $c-dark;
                border-top: none;
            }
        }

            td,
            th {
                vertical-align: middle;
                padding: 13px 18px;

                &:not(:first-child) {
                    text-align: center;
                }

                &:first-child {
                    position: sticky;
                    left: 0;
                }

                @include breakpoint(phablet) {
                    height: 50px;
                    vertical-align: middle;
                }

                strong {
                    margin-right: 2px;
                }
            }

            th {
                &:first-child {
                    width: 215px;
                    background-color: $c-light-grey;
                }

                &:not(:first-child) {
                    width: 105px;

                    @include breakpoint(phablet) {
                        width: calc((100vw - 130px - 50px) / 2); // 130px is the width of the first column, 50px is the width of the last column
                    }
                }
            }

            td {
                &:first-child {
                    @include breakpoint(phablet) {
                        width: 130px;
                        min-width: 130px;
                    }
                }
            }

            th.product-dimensions__cell-selected {
                background-color: $c-dark;
                color: $c-white;
                border: 0;
                border-left: 2px solid $c-dark;
                border-right: 2px solid $c-dark;
            }

            td.product-dimensions__cell-selected {
                border: 0;
                border-left: 2px solid $c-dark;
                border-right: 2px solid $c-dark;
                background-color: $c-light-grey;
            }
    }

    // Expandable table styles
    .product-dimensions--expanded table {
        tr.product-dimensions__table-body-row {
            &:nth-child(n+6) {
                display: table-row;
            }
        }

        tr.product-dimensions__table-body-row--last-visible {
            td.product-dimensions__cell-selected {
                border-bottom: none;
            }
        }
    }

    .product-dimensions__table-lower {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        @include breakpoint(phablet) {
            padding: 0 $site-mobile-gutter;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
        }

        .global-icon-minus {
            display: none;
        }

        .button--expanded {
            .global-icon-minus {
                display: inline-block;
            }

            .global-icon-plus {
                display: none;
            }
        }
    }

        .product-dimensions__caveat {
            @include font(12px, 26px);
        }

    .product-dimensions__table-buttons {
        margin-top: 35px;
        display: flex;
        gap: 20px;
        list-style: none;

        @include breakpoint(phablet) {
            padding-left: $site-mobile-gutter;
            padding-right: $site-mobile-gutter;
            margin-top: 20px;
            flex-direction: column;
            gap: 15px;
        }
    }

    .product-dimensions__image-container {
        @include breakpoint(tablet) {
            order: -1;
        }

        .product-dimensions__image-caption {
            @include font(13px, 15px);

            letter-spacing: 1px;
            font-style: italic;
            color: $c-dark;
            text-align: center;
        }
    }

    .product-dimensions__image {
        img {
            width: 100%;
            height: auto;
        }
    }

    .product-dimensions:not(.product-dimensions--expanded) {
        .product-dimensions__image--important-only + .product-dimensions__image--full {
            display: none;
        }
    }

    // when we don't have any images uploaded - only show dims (without letters)
    .product-dimensions--no-image {
        display: flex;
        justify-content: center;

        .product-dimensions__image-container, 
        .product-dimensions__table-body-row td strong {
            display: none;
        }

        .product-dimensions__first-col {
            max-width: 700px;
        }
    }
