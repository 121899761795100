.header {
    --header-text-colour: #{$c-dark};
    --header-background-colour: #{$c-white};
    --search-background-colour: #{$c-white};
    --search-border: 1px solid #{$c-grey-border};
    --basket-text-colour: #{$c-white};

    padding: 0 $site-gutter;
    display: flex;
    align-items: center;
    position: sticky;
    top: $notice-height;
    color: var(--header-text-colour);
    background-color: var(--header-background-colour);
    z-index: 29;
    transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

    @include breakpoint(nav) {
        padding: 16px $site-mobile-gutter;
        height: $header-height-mobile;
        top: $notice-height-mobile;
    }

    &[data-position="sticky"] {
        box-shadow: 0 3px 20px 2px rgba($c-black, .05);
    }
}

.header--clear {
    --header-text-colour: #{$c-white};
    --header-background-colour: transparent;
    --search-background-colour: rgba(250, 250, 250, 0.2);
    --search-border: 1px solid transparent;
    --basket-text-colour: #{$c-dark};

    &:hover,
    &[data-position='sticky'],
    &.header--submenu-active,
    &.header--mobile-menu-active,
    &.header--search-active {
        --header-text-colour: #{$c-dark};
        --header-background-colour: #{$c-white};
        --basket-text-colour: #{$c-white};
        --search-border: 1px solid #{$c-grey-border};
    }

    & + * .banner--large {
        margin-top: calc($header-height * -1);

        @include breakpoint(nav) {
            margin-top: calc($header-height-mobile * -1);
        }
    }
}

.header--mobile-menu-active {
    @include breakpoint(nav) {
        --header-background-colour: #{$c-white};
        --header-text-colour: #{$c-dark};
    }
}

    .header__logo {
        display: flex;
        align-items: flex-end;
        margin-right: 40px;
        max-width: 70px;

        @include breakpoint(nav) {
            max-width: 55px;
            margin-right: 15px;
        }

        svg {
            height: auto;
            transition: fill 150ms ease-in-out;
            fill: currentColor;
        }
    }

    .header__navigation {
        padding: 35px 0;

        @include breakpoint(nav) {
            display: none;

            .header--mobile-menu-active & {
                overflow: auto;
                padding: 0;
                display: flex;
                position: fixed;
                top: calc($header-height-mobile + $notice-height-mobile);
                left: 0;
                right: 0;
                bottom: 0;
                background-color: $c-white;
                z-index: 1;
            }
        }
    }

    .header__search {
        position: relative;
        margin-left: auto;
        margin-right: 25px;

        @include breakpoint(nav) {
            margin-right: 15px;
        }
    }

        input.header__search-input {
            @include form-input;

            max-width: 300px;
            height: 45px;
            border: var(--search-border);
            border-radius: 25px;
            background-color: var(--search-background-colour);
            color: var(--header-text-colour);
            padding: 3px 35px 0 20px;
            line-height: 1;
            transition: all 150ms ease-in-out;

            @include breakpoint(nav) {
                font-size: 16px;
                max-width: 205px;
                height: 40px;
                width: 100%;
                margin-right: 0;
            }

            &::placeholder {
                color: var(--header-text-colour);
                opacity: 1;
                transition: color 150ms ease-in-out;

                @include breakpoint(nav) {
                    @include font(12px, 12px);
                }
            }

            &:focus,
            &:focus-visible,
            .header--search-active & {
                border-color: $c-dark;
                outline: none;
            }
        }

        .header__search-label {
            @include visually-hidden;
        }

        .header__search-submit {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
            display: flex;

            svg {
                width: 20px;
                height: 20px;
                transition: fill 150ms ease-in-out;
                fill: currentColor;

                @include breakpoint(tablet) {
                    margin-bottom: 2px;
                }
            }

            &:hover svg {
                fill: var(--site-colour-primary);
            }
        }

        .header__basket {
            position: relative;
            display: flex;
            cursor: pointer;

            svg {
                transition: fill 150ms ease-in-out;
                fill: currentColor;

                @include breakpoint(tablet) {
                    margin-bottom: 2px;
                }
            }

            &:hover svg {
                fill: var(--site-colour-primary);
            }
        }

        .header__basket-count {
            position: absolute;
            background-color: var(--header-text-colour);
            color: var(--basket-text-colour);
            border-radius: 50%;
            height: 22px;
            width: 22px;
            line-height: 15px;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -10px;
            right: -10px;
            transition: background-color 150ms ease-in-out, color 150ms ease-in-out;

            &:has(span:empty) {
                display: none;
            }

            span {
                @include font(12px, 13px);
                @include mediumText;

                height: 12px;
            }
        }

        @keyframes inflate {
            0% { transform: scale(1); }
            50% { transform: scale(1.3); }
            100% { transform: scale(1); }
        }

        .header__basket-count--animating {
            animation: inflate 800ms ease forwards;
            background-color: $c-green-accent;
        }

        .header__quick-basket {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            position: absolute;
            right: 25px;
            top: $header-height;
            background-color: $c-white;
            display: none;

            @include breakpoint(nav) {
                top: $header-height-mobile;
                box-shadow: 0 10px 15px 0 rgba($c-black, 0.1);
                right: 0;
            }

            @include breakpoint(mobile) {
                left: 0;
            }
        }

        .header__quick-basket--showing {
            opacity: 0;
            display: block;
            transition: opacity 100ms ease-in;
        }
        
        .header__quick-basket--show {
            opacity: 1;
        }

        .header__toggle {
            display: none;

            @include breakpoint(nav) {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 15px;
                width: 25px;
                cursor: pointer;

                .global-icon-hamburger {
                    fill: currentColor;
                    width: 25px;

                    .header--mobile-menu-active & {
                        display: none;
                    }
                }

                .global-icon-close-large {
                    width: 25px;
                    display: none;

                    .header--mobile-menu-active & {
                        display: block;
                    }
                }

                svg {
                    transition: fill 150ms ease-in-out;

                    @include breakpoint(tablet) {
                        margin-bottom: 2px;
                    }
                }

                &:hover svg {
                    fill: $c-green-accent;
                }
            }
        }
