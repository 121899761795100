html {
    font-size: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    text-size-adjust: 100%;
    scroll-behavior: smooth;

    &.lock-scroll {
        overflow: hidden;
    }
}

body {
    @include font(14px, 24px);

    margin: 0;
    padding: 0;
    height: 100%;
    background-color: $c-white;
    color: $c-body;
    font-family: $f-primary;
    min-width: 320px;
}

img.fill {
    width: 100%;
    height: auto;
}

main#content {
    // Remove margin and border from the footer if showrooms is the last block
    &:has(.block--showrooms) {
        ~ .footer {
            margin-top: 0;
            border-top: 0;
        }
    }
}
