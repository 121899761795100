@import "core/resources/sass/reset";

// Match most modern UA defaults
dd {
	display: block;
}

a {
    text-decoration: none;
    color: inherit;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}  
