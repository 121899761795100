.fabric-chooser-mobile-content {
    position: sticky;
    top: 20px;

    @include breakpoint(tablet) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px 10px 100px;
        background: $c-white;
        overflow: auto;
        overflow-x: hidden;
        z-index: 12;
        transform: translateX(100%);
        transition: transform .3s ease;
    }

    @media screen and (max-height:700px) {
        top: 0;
    }
}

.fabric-chooser-mobile-content--active {

    @include breakpoint(tablet) {
        transform: none;
    }
}

    .fabric-chooser-mobile-content__footer {
        @include breakpoint(tablet) {
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            padding: 10px;
            background: $c-white;
            box-shadow: 0 -10px 15px $c-border;
            z-index: 3;
        }

        .fabric-chosen--no-tabs .swatch-basket & {
            @include breakpoint(tablet) {
                position: static;
                padding: 0;
                background: transparent;
                box-shadow: none;
            }
        }

        .button {
            .fabric-chosen--no-tabs .swatch-basket & {
                @include breakpoint(tablet) {
                    padding: 12px;
                }
            }
        }
    }

        .fabric-chooser-mobile-content__back {
            display: none;

            @include breakpoint(tablet) {
                @include mediumText;
                @include font(13px, 25px);

                display: flex;
                align-items: center;
                margin-right: 20px;
                letter-spacing: 0.65px;
            }

            svg[role="img"] {
                width: 6px;
                height: 10px;
                margin-right: 10px;
            }
        }
