.expander {}

    .expander__toggle {
        display: flex;

        span {
            @include mediumText;

            display: flex;
            align-items: center;
            margin-left: auto;
            gap: 10px;
            cursor: pointer;
            transition: color 150ms ease-in;

            &:hover,
            .expander--expanded-a & {
                color: var(--site-colour-primary);
            }

            svg {
                fill: currentColor;
                transition: transform 150ms;

                .expander--expanded-a & {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .expander__target {
        opacity: 0;
        max-height: 0;
        overflow: none;
        pointer-events: none;
        transition: opacity 150ms ease-in, max-height 150ms ease-in-out;
        
        .expander--expanded-a & {
            max-height: 200px;
            transition: max-height 150ms ease-in-out;
            pointer-events: auto;
        }

        .expander--expanded-b & {
            opacity: 1;
            transition: opacity 100ms ease-in;
        }
    }
