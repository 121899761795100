@use "sass:map";
@import 'text-colours.json';

@each $label, $data in $colours {
    .header[data-position='sticky'],
    .header--submenu-active,
    .header--clear[data-position='sticky']:hover,
    .header--clear:hover
    {
        .text-colour--#{$label} {
            color: #{map-get($data, 'color')};

            .navigation__groups {
                color: #2e2d2c;
            }
        }
    }

    .text-colour--#{$label} {
        color: #{map-get($data, 'color')};
    }
}

