.inline-cta {
    max-width: $max-content-width;
    margin: auto;
    @include breakpoint(tablet) {
        max-width: $tablet-max-width;
        margin-left: auto;
        margin-right: auto;
    }
}

.inline-cta--careers {
    border-bottom: 1px solid $c-grey-border;
    padding-bottom: 30px;
}

.inline-cta__hidden {
    display: none;
}

    .inline-cta__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 10%;

        @include breakpoint(tablet) {
            max-width: $tablet-max-width;
            padding-right: 0;
            flex-direction: column;
            gap: 20px;
        }

        .inline-cta--careers & {
            padding-right: 0;
            gap: 20px;
        }

        &--above {
            text-align: center;
            display: flex;
            align-content: center;
            flex-direction: column;
            gap: 15px;
        }
    }

        .inline-cta__text-wrap {
            max-width: 550px;
            flex-shrink: 0;

            @include breakpoint(tablet) {
                max-width: 50%;
                width: 100%;
            }
            
            @include breakpoint(mobile) {
                max-width: 80%;
                width: 100%;
            }

            .inline-cta--careers & {
                flex-grow: 1;
            }

            &--above {
                width: 100%;
                margin-bottom: 5px;
            }
        }

            .inline-cta__title {
                @include small-title;

                margin-bottom: 15px;

                @include breakpoint(tablet) {
                    margin-bottom: 7px;
                }

                .inline-cta--careers & {
                    @include large-subtitle;
                }

                &--above {
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 20px;
                }
            }

            .inline-cta__meta {
                margin-top: 5px;

                span {
                    @include mediumText;
                }
            }

        .inline-cta__thanks {
            flex-basis: 100%;
            text-align: center;

            & > * {
                justify-content: center;
            }
        }

        .inline-cta__button-wrap {
            width: 100%;
            display: flex;
            justify-content: end;
            gap: 20px 10px;
            flex-wrap: wrap;
            flex-grow: 1;


            .inline-cta__button {
                flex-grow: 1;
                max-width: 320px;
                width: 100%;

                @include breakpoint(tablet) {
                    max-width: 50%;
                    width: 100%;
                    justify-self: center;
                }

                @include breakpoint(mobile) {
                    max-width: 80%;
                }

                .inline-cta--careers & {
                    flex-basis: 0;
                }
            }
        
            &--above {
                width: 100%;
                display: flex;
                max-width: 660px;
                margin: auto;
                flex-wrap: wrap;
                justify-content: center;
                gap: 20px 10px;
                
                .inline-cta__button {
                    flex-grow: unset;
                    max-width: unset;
                    width: fit-content;
                    min-width: 150px;

                    @include breakpoint(tablet) {
                        max-width: 50%;
                        width: 100%;
                        word-wrap: break-word;
                        white-space: normal;
                    }

                    @include breakpoint(mobile) {
                        max-width: 80%;
                    }
                }
            }

            @include breakpoint(tablet) {
                justify-content: center;
            }
        }


    


