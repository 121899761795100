.adyen-checkout__field {
    position: relative;
}

    span.adyen-checkout__label__text {
        position: absolute;
        z-index: 1;
        top: 12px;
        left: 15px;
        color: $c-label;
    }

    span.adyen-checkout__input,
    input.adyen-checkout__input {
        @include font(16px, 16px);
        font-weight: 400;
        padding-left: 15px;
        padding-top: 25px;
        height: 55px;
        border-radius: 0;
        border-color: $c-checkout-border;
        color: $c-dark;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }

.adyen-checkout__paypal__buttons {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

    .adyen-checkout__paypal__button.adyen-checkout__paypal__button {
        margin: 0;
        width: 100%;
    }

.adyen-checkout__paywithgoogle {
    button {
        min-width: 0;
        width: 100%;
        box-sizing: border-box;
        margin: 0;
    }
}

.klarna-container {
    width: 100%;
}
