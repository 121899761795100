@each $name, $breakpoint in $sizes {
    @include breakpoint($breakpoint) {
        .#{$name}-square,
        .#{$name}-one-by-one {
            @include aspect(1 / 1);
        }
        .#{$name}-two-by-one {
            @include aspect(2 / 1);
        }
        .#{$name}-three-by-one {
            @include aspect(3 / 1);
        }
        .#{$name}-four-by-one {
            @include aspect(4 / 1);
        }
        .#{$name}-three-by-two {
            @include aspect(3 / 2);
        }
        .#{$name}-four-by-three {
            @include aspect(4 / 3);
        }
        .#{$name}-auto {
            &::before {
                display: none;
            }
        }
    }
}
