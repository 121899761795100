@import 'swiper/swiper.scss';
@import 'swiper/modules/navigation.scss';
@import 'swiper/modules/pagination.scss';
@import 'swiper/modules/effect-fade.scss';
@import 'swiper/modules/thumbs.scss';

:root {
    --swiper-pagination-bullet-size: 14px;
    --swiper-pagination-bullet-inactive-color: #fff;
    --swiper-pagination-color: #2e2d2c;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-horizontal-gap: 4px;
}

.swiper-pagination-bullet {
    border: 1px solid $c-dark;

    &.swiper-pagination-bullet-active {
        box-shadow: inset 0 0 0 2px $c-white;
    }
}

[data-carousel-type="galleryCta"] {
    --swiper-pagination-bottom: 25px;

    .swiper-pagination {
        text-align: right;
        right: 20px;
        left: unset;

        @include breakpoint(tablet) {
            left: 0;
            right: 0;
            text-align: center;
            bottom: 20px;
        }

        .block__grid--image-right & {
            right: unset;
            left: 20px;
            text-align: left;

            @include breakpoint(tablet) {
                left: 0;
                right: 0;
                text-align: center;
            }
        }
    }
}

[data-carousel-type="showroom"] {
    --swiper-pagination-bottom: 10px;

    .swiper-pagination {
        display: none;

        @include breakpoint(tablet) {
            display: block;
            left: 0;
            right: $site-mobile-gutter; // as the carousel is being offset by the site gutter, we need to offset the pagination by the same amount
            width: auto;
        }
    }
}

[data-carousel-type="focus"] {
    .swiper-pagination {
        display: none;

        @include breakpoint(tablet) {
            display: block;
            position: relative;
            margin-top: 25px;
            left: 0;
            right: 0;
            width: auto;
        }
    }
}


[data-carousel-type="shopTheLook"] {
    --swiper-pagination-bottom: 0;

    .swiper-wrapper {
        box-sizing: border-box;
    }

    .swiper-pagination {
        display: none;

        @include breakpoint(tablet) {
            margin-top: 20px;
            display: block;
            position: relative;
        }
    }

    .swiper-slide {
        height: auto;
    }

    .listing-item {
        height: 100%;
    }
}

[data-carousel-type="products"],
[data-carousel-type="range"] {
    --swiper-pagination-bottom: 0;

    @include breakpoint(tablet) {
        margin-right: calc($site-mobile-gutter * -1);
    }

    .swiper-wrapper {
        box-sizing: border-box;
    }

    .swiper-pagination {
        display: none;

        @include breakpoint(tablet) {
            margin-top: 20px;
            display: block;
            position: relative;
        }
    }

    .swiper-slide {
        height: auto;
    }

    .listing-item {
        height: 100%;
    }
}

[data-carousel-type="carousel"] {
    --swiper-pagination-bottom: 0;

    .swiper-pagination {
        display: none;

        @include breakpoint(tablet) {
            display: block;
        }
    }
}

[data-carousel-type="moodboard"] {
    --swiper-pagination-bottom: 0;

    .swiper-pagination {
        display: none;

        @include breakpoint(tablet) {
            display: block;
        }
    }
}

[data-carousel-type="genericCarousel"] {
    --swiper-pagination-bottom: 0;

    .swiper-pagination {
        display: none;

        @include breakpoint(tablet) {
            display: block;
        }
    }
}

[data-carousel-type="kioskCarousel"] {
    --swiper-pagination-bottom: 0;

    .swiper-pagination {
        display: none;

        @include breakpoint(tablet) {
            display: block;
        }
    }
}

[data-carousel-type="crosssells"] {
    --swiper-pagination-bottom: 10px;

    .swiper-wrapper {
        gap: 0;
    }

    .swiper-pagination {
        display: none;

        @include breakpoint(tablet) {
            display: block;
            position: relative;
            left: 0;
            right: $site-mobile-gutter; // as the carousel is being offset by the site gutter, we need to offset the pagination by the same amount
            width: auto;
        }
    }

    .swiper-slide {
        box-sizing: border-box;
        height: auto;
    }

    .listing-item {
        height: 100%;
    }
}
