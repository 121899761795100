.global-icon-360 {
  width: 37px;
  height: 30px;
}

.global-icon-arrow-down-small {
  width: 12px;
  height: 8px;
}

.global-icon-arrow-down {
  width: 14px;
  height: 9px;
}

.global-icon-arrow-left {
  width: 10px;
  height: 15px;
}

.global-icon-arrow-right {
  width: 10px;
  height: 15px;
}

.global-icon-basket {
  width: 21px;
  height: 21px;
}

.global-icon-brochure {
  width: 19px;
  height: 15px;
}

.global-icon-chat {
  width: 22px;
  height: 20px;
}

.global-icon-close-large {
  width: 19px;
  height: 19px;
}

.global-icon-close-small {
  width: 20px;
  height: 20px;
}

.global-icon-cross-round {
  width: 20px;
  height: 20px;
}

.global-icon-delivery {
  width: 18px;
  height: 18px;
}

.global-icon-dixa {
  width: 30px;
  height: 26px;
}

.global-icon-dry-clean {
  width: 33px;
  height: 37px;
}

.global-icon-gift {
  width: 19px;
  height: 19px;
}

.global-icon-hamburger {
  width: 25px;
  height: 15px;
}

.global-icon-has-filters {
  width: 18px;
  height: 20px;
}

.global-icon-info {
  width: 16px;
  height: 16px;
}

.global-icon-location {
  width: 35px;
  height: 45px;
}

.global-icon-minus {
  width: 10px;
  height: 2px;
}

.global-icon-pause-video {
  width: 60px;
  height: 70px;
}

.global-icon-phone-alt {
  width: 21px;
  height: 20px;
}

.global-icon-phone {
  width: 57px;
  height: 50px;
}

.global-icon-pinterest {
  width: 60px;
  height: 50px;
}

.global-icon-play-video {
  width: 61px;
  height: 74px;
}

.global-icon-plus-circle {
  width: 18px;
  height: 19px;
}

.global-icon-plus {
  width: 10px;
  height: 10px;
}

.global-icon-position {
  width: 18px;
  height: 18px;
}

.global-icon-search {
  width: 20px;
  height: 20px;
}

.global-icon-shack {
  width: 18px;
  height: 19px;
}

.global-icon-stars {
  width: 20px;
  height: 18px;
}

.global-icon-swatches-alt {
  width: 31px;
  height: 25px;
}

.global-icon-swatches {
  width: 24px;
  height: 24px;
}

.global-icon-tick-round {
  width: 20px;
  height: 20px;
}

.global-icon-tick {
  width: 13px;
  height: 10px;
}

.global-icon-tooltip {
  width: 7px;
  height: 9px;
}

.global-icon-van {
  width: 19px;
  height: 19px;
}

.global-icon-zoom-in {
  width: 20px;
  height: 20px;
}

.global-loaf-logo {
  width: 84px;
  height: 80px;
}

.global-swatch-mask {
  width: 38px;
  height: 38px;
}

.global-tick {
  width: 13px;
  height: 10px;
}
