.h1.h1 {
    @include large-title;
}

.h2.h2 {
    @include  medium-title;
}

.h3.h3 {
    @include small-title;
}

.h4.h4 {
    @include large-subtitle;
}

.h1,
.h2,
.h3,
.h4 {
    // Colours
    &.white {
        color: $c-white;
    }

    &.site-text-colour-secondary {
        color: var(--site-text-colour-secondary);
    }

    // Layout
    &.center {
        text-align: center;
    }

    &.m-left {
        @include breakpoint(tablet) {
            text-align: left;
        }
    }

    &.ph-left {
        @include breakpoint(phablet) {
            text-align: left;
        }
    }
    
    // Spacing
    &.margin-bottom {
        margin-bottom: 15px;
    }
}

p {
    &.center {
        text-align: center; 
    }
}

strong {
    @include mediumText;
}
