.fabric-filter {
    padding: 0 1px 1px 0;

    @include breakpoint(mobile) {
        padding: 0 5px 5px 0;
    }

    input {
        display: none;
    }

    label {
        margin: 0;
        font-size: 0;
    }
}

    .fabric-filter__tile {
        position: relative;

        img {
            @include aspect(1);
            width: 100%;
        }

        input:checked + label &,
        .fabric-filter:hover & {

            &:after {
                content: '';
                position: absolute;
            }
        }

        input:checked + label & {

            .fabric-filter:hover &:after,
            &:after {
                top: -4px;
                right: -4px;
                bottom: -4px;
                left: -4px;
                border: 1px solid $c-dark;
                outline: 3px solid $c-white;
                outline-offset: -4px;
                z-index: 1;
            }
        }

        .fabric-filter:hover & {

            &:after {
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 1px solid $c-border;
                outline: 2px solid $c-white;
                outline-offset: -3px;
            }
        }
    }
