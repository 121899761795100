.video-wrap {
    overflow: hidden;
    position: relative;
    @include aspect(16 / 9);
}

.video-wrap--4-by-3 {
    @include aspect(4 / 3);
}

.video-wrap--mattress {
    max-width: 800px;
    margin: 0 auto;
}

.video-wrap__video {
    @include absolute-cover;
}
