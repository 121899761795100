.block--shop-the-look {
    @include breakpoint(tablet) {
        max-width: $tablet-max-width;
        margin-left: auto;
        margin-right: auto;
    }

    .block__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 23px;

        @include breakpoint(tablet) {
            display: block;
        }

        .block__title--mobile {
            display: none;

            @include breakpoint(tablet) {
                display: block;
            }
        }
    }

    .block__grid--reverse {
        .block__image-link {
            order: 1;

            @include breakpoint(tablet) {
                order: 0;
            }
        }
    }

    .block__image-link {
        display: block;
        position: relative; 
        width: 100%;
        height: 100%;
    }

        .block__video {
            width: 100%;
            height: 100%;

            .video__player {
                height: 100%
            }

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .block__image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    .block__content {
        text-align: center;

        @include breakpoint(tablet) {
            margin-top: 23px;
            text-align: left;
        }

        @include breakpoint(phablet) {
            margin-right: calc($site-mobile-gutter * -1);
        }
    }

        .block__title {
            margin-bottom: 20px;

            @include breakpoint(tablet) {
                display: none;
            }
        }

        .block__card-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 20px;
            row-gap: 30px;
            height: auto;

            @include breakpoint(tablet) {
                display: flex;
                gap: 0;
                margin-bottom: 15px;
                padding-bottom: 30px;
            }
        }
}
