.listing {}

    .listing__filters {
        background-color: $c-white;
    }

    .listing__filters--no-filters {
        padding-top: 20px;

        @include breakpoint(tablet) {
            padding: 0;
        }

        .listing__breadcrumbs {
            padding: 0 5px 20px 0;

            @include breakpoint(tablet) {
                padding: 0 5px 0 0;
            }
        }
    }

    .listing__items {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 23px;
        user-select: none;
        grid-auto-flow: dense;

        @include breakpoint(tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include breakpoint(mobile) {
            grid-template-columns: minmax(0, 1fr);
            gap: 12px;
        }
    }

    // Higher specificity needed
    .listing__items--shop-the-look.swiper-wrapper {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        padding-right: 1px; //@todo fix this properly

        @include breakpoint(tablet) {
            padding-right: 0;
            display: flex;
            gap: 0;
        }
    }

    .listing__items--overlay {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .listing__items--products {
        gap: 0;
    }

    .listing__items--crosssells {
        @include breakpoint(tablet) {
            padding-bottom: 30px;
            gap: 0;
        }
    }

    .listing__items--moodboard {
        gap: 0;
        margin-top: 23px;
    }

    .listing__breadcrumbs {
        background: $c-white;
        text-align: left;
        padding-top: 20px;

        .button--has-icon {
            gap: 3px
        }

        .button--has-icon svg {
            height: 8px;
            margin-bottom: 0;
        }
      
        @include breakpoint(tablet) {
            padding-top: 10px;
        }
    }

.listing__pagination--hidden {
    display: none;
}
