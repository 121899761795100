@use 'sass:math';

.quick-basket {
    position: relative;
    max-width: 350px;
    padding: 0 24px;
    color: $c-black;

    @include breakpoint(mobile) {
        max-width: 100%;
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-100%);
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $c-grey transparent;

        @include breakpoint(nav) {
            right: 52px;
        }
    }
}

    .quick-basket__header {
        background-color: $c-grey;
        padding: 13px 0;
        margin-left: -24px;
        margin-right: -24px;
        display: grid;
        place-items: center;

        p {
            @include mediumText;
        }
    }

    .quick-basket__list {
        padding: 20px 0;
        border-bottom: 1px solid $c-grey-border;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 400px;
        overflow-y: auto;
        overscroll-behavior: contain;

        @include breakpoint(nav) {
            border: none;
            padding-bottom: 0;
        }
    }

        .quick-basket__link {
            display: flex;
            gap: 10px;
        }

            .quick-basket__image {
                @include aspect(math.div(4, 3));

                flex-shrink: 0;

                img {
                    width: 100%;
                    height: auto;
                    max-width: 80px;
                }
            }

            .quick-basket__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

                .quick-basket__title {
                    @include font(14px, 20px);
                    @include mediumText;
                }

                .quick-basket__meta {
                    display: flex;
                    gap: 5px;
                }

                .quick-basket__price,
                .quick-basket__subtitle {
                    @include smallprint;
                    @include mediumText;
                }

                .quick-basket__quantity {
                    @include smallprint;
                }

                .quick-basket__subtitle {
                    margin-top: 8px;
                }

        .quick-basket__footer {
            padding: 15px 0 24px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 15px;

            button {
                display: flex;
                margin: 0 auto;
            }
        }

            .quick-basket__total-price {
                @include mediumText
            }
