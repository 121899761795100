.competition-content {
    margin-bottom: 30px;
}

.competition-radio {
    margin-bottom: 10px;
}

.competition-panel {
    @include secondary-panel;
}